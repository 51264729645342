import { Box } from '@mui/material';
import React, { useContext } from 'react';
import LocationsFilter from '../../../components/FiltersComponents/LocationFilter';
import SearchFilter from '../../../components/FiltersComponents/Search';
import StatusesFilter from '../../../components/FiltersComponents/Select';
import { CLIENTS_STATUSES, ClientsFilters } from '../constants';

export default function Filters() {
  const {
    changeStatusFilters,
    changeTeamsFilters,
    clientsFilters,
    debouncedSearch,
    isLoading,
    isFetching,
    locationsList,
    setClientsFilters,
    isTerritoriesLoading,
  } = useContext(ClientsFilters);
  return (
    <Box sx={{ display: 'flex', mb: '25px', gap: '20px' }}>
      <SearchFilter
        changeValue={debouncedSearch}
        name="clientName"
        placeholder="Name"
        initialValue={clientsFilters.clientName}
      />
      <StatusesFilter
        filterItems={clientsFilters.status}
        id="status"
        isLoading={isLoading || isFetching}
        isMultiselect
        options={CLIENTS_STATUSES}
        placeHolder="Status"
        setFilters={changeStatusFilters}
        showFiltersCount
      />
      <LocationsFilter
        locationsList={locationsList}
        setFilters={setClientsFilters}
        filters={clientsFilters}
        setFiltersValue={changeTeamsFilters}
        isLoading={isTerritoriesLoading}
        isCaregiverOrClient
      />
    </Box>
  );
}
