import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';
import { parseWeeklyReportData } from './utils';

export const REPORTING_API_KEY = 'reportingApi';
const reportingApi = createApi({
  reducerPath: REPORTING_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: (builder) => ({
    getWeeklyReport: builder.query({
      query: (params) => ({
        url: 'reporting/weekly-report',
        method: 'POST',
        body: JSON.stringify(params.date),
      }),
      transformResponse: (response) => parseWeeklyReportData(response),
      providesTags: () => [{ type: 'weeklyReport', id: 'ALL' }],
    }),
  }),
});
export const { useGetWeeklyReportQuery } = reportingApi;

export default reportingApi;
