import { Box } from '@mui/material';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext } from 'react';
import CustomSelect from '../../../../../components/Select';

export default function GenderSelector() {
  const { setProfileData, editingProfileDataLibrary, profileData } =
    useContext(CaregiverProfileContext);

  const onSelectChange = (params) => {
    let newGender = {};
    for (let gender in editingProfileDataLibrary.skills.genders){
      if (params.id === editingProfileDataLibrary.skills.genders[gender].id){
        newGender = editingProfileDataLibrary.skills.genders[gender]
      }
    }
    setProfileData({
      ...profileData,
      gender: params.id,
      genderDetails: newGender
    });
  };

  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <CustomSelect
        id="gender"
        skillLang
        options={editingProfileDataLibrary?.skills?.genders}
        isLoading={false}
        selectedValue={profileData.gender || ''}
        setValue={onSelectChange}
      />
    </Box>
  );
}
