import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';

export const CAREGIVERS_AVAILABILITY_API_KEY = 'caregiversAvailabilityApi';
const caregiversAvailabilityApi = createApi({
  reducerPath: CAREGIVERS_AVAILABILITY_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: (builder) => ({
    getCaregiverAvailability: builder.query({
      query: (caregiverId) => ({
        url: `crud/caregivers/`,
        method: 'GET',
        params: { id: caregiverId},
      }),
    }),
    confirmCaregiverAvailability: builder.mutation({
      query: (payload) => ({
        url: `crud/caregivers/`,
        method: 'PATCH',
        body: payload,
      }),
    }),
  }),
});
export const {
  useGetCaregiverAvailabilityQuery,
  useConfirmCaregiverAvailabilityMutation,
} = caregiversAvailabilityApi;
export default caregiversAvailabilityApi;
