import { Box, Typography } from '@mui/material';
import HolidayIcon from '../../../../img/icons/scheduling-holiday-icon.svg';
import SchedulingToolbar from '../../../../pages/Scheduling/components/SchedulingToolbar';
import { editScheduleHolidayWrapperStyles } from '../../../../pages/Scheduling/components/styles';
import { truncateName } from '../../../../pages/Scheduling/constants';
import { hideEditedContent, hideEditedHeader } from '../../../../pages/Scheduling/styles';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';

export default function Header({
  closeEditMode = () => {},
  isDateHoliday = false,
  setTeamsFiler = () => {},
  showDetails = false,
  teamsFilter = {},
}) {
  return (
    <div>
      <Box
        className="hideHeader"
        sx={{
          ...hideEditedHeader,
          height: showDetails ? '257px' : '87px',
        }}
      />
      <Box className="hideContent" sx={hideEditedContent} />
      {isDateHoliday?.name && (
        <Box sx={editScheduleHolidayWrapperStyles}>
          <img src={HolidayIcon} alt="" />
          <Typography variant="h6" sx={{ ml: '6px' }}>
            {truncateName(isDateHoliday?.name, 20, 10)}
          </Typography>
        </Box>
      )}
      <SchedulingToolbar
        editMode
        setEditMode={closeEditMode}
        setTeamsFiler={setTeamsFiler}
        teamsFilter={teamsFilter}
      />
    </div>
  );
}

Header.propTypes = {
  closeEditMode: func,
  isDateHoliday: bool,
  setTeamsFiler: func,
  showDetails: bool,
  teamsFilter: instanceOf(Object),
};
