import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Checkbox, Divider, MenuItem, Select } from '@mui/material';
import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import CounterBadge from '../../../components/CounterBadge';
import {
  customSelectItem,
  customSelectPaperProps,
  customSelectStyles,
} from './styles';

export default function SelectWithCounter({
  disableColored = false,
  filterItems = [],
  id = '',
  isLoading = false,
  isMultiselect = false,
  hasSelectAllOption = false,
  options = [],
  placeHolder = '',
  setFilters = () => {},
  showFiltersCount = false,
}) {
  const selectedIds = filterItems.map((item) => item.id);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (!isMultiselect) {
      setFilters({ field: id, value });
      return '';
    }
    if (isMultiselect && value[value.length - 1] === 'all') {
      setFilters({
        field: id,
        value: filterItems.length === options.length ? [] : options,
      });
      return '';
    }
    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((option) => option === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setFilters({ field: id, value: duplicateRemoved });
    return '';
  };

  return (
    <Select
      IconComponent={ExpandMoreIcon}
      className={
        filterItems.length && !disableColored ? 'has-selected-items' : ''
      }
      disabled={isLoading}
      displayEmpty
      multiple={isMultiselect}
      onChange={handleChange}
      sx={{ ...customSelectStyles }}
      MenuProps={{
        disableAutoFocusItem: true,
        PaperProps: {
          sx: customSelectPaperProps,
        },
      }}
      renderValue={() => (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {placeHolder}
          {showFiltersCount ? (
            <CounterBadge
              label={
                hasSelectAllOption && options?.length === filterItems?.length
                  ? 'ALL'
                  : filterItems?.length?.toString()
              }
            />
          ) : null}
        </Box>
      )}
      value={selectedIds}
    >
      {hasSelectAllOption && (
        <MenuItem
          disabled={isLoading}
          name="all"
          value="all"
          disableRipple
          className="select-all-item"
          sx={{
            ...customSelectItem,
          }}
        >
          <Checkbox
            checked={
              options?.length > 0 && options?.length === filterItems?.length
            }
          />
          All
        </MenuItem>
      )}
      {hasSelectAllOption && <Divider sx={{ mt: 0 }} />}
      {options?.map((option, index) => (
        <MenuItem
          disabled={isLoading}
          disableRipple
          key={index}
          sx={{ ...customSelectItem }}
          value={option.id}
        >
          <Checkbox
            checked={selectedIds.findIndex((item) => item === option.id) >= 0}
          />
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
}

SelectWithCounter.propTypes = {
  disableColored: bool,
  filterItems: instanceOf(Array),
  id: string,
  isLoading: bool,
  isMultiselect: bool,
  hasSelectAllOption: bool,
  options: instanceOf(Array),
  placeHolder: string,
  setFilters: func,
  showFiltersCount: bool,
};
