import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { useCreateUserByIdMutation } from '../../../../../../api/Administration/api';
import { func, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import { isValidEmail } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomDialog from '../../../../../../components/Dialog';
import SearchFilter from '../../../../../../components/FiltersComponents/Search';
import AddUserModal from '../AddUserModal';

export default function AddUserHeader({
  changeValue = () => {},
  radioValue = '',
  setRadioValue = () => {},
  setUserEmail = () => {},
  userEmail = '',
}) {
  const { setAlert } = useContext(AlertContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [createUserById, { error }] = useCreateUserByIdMutation();
  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  const createUser = () => {
    createUserById({
      email: userEmail,
      role: radioValue,
    });
    setOpenDialog(false);
    setUserEmail('');
  };
  const cancelModal = () => {
    setUserEmail('');
    setOpenDialog(false);
  };
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        mb: '30px',
      }}
    >
      <Box sx={{ maxWidth: '250px' }}>
        <SearchFilter
          changeValue={changeValue}
          name="user"
          placeholder="User"
        />
      </Box>
      <Button
        color="secondary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setOpenDialog(true)}
        disableElevation
        sx={{
          '&.MuiButton-root.Mui-disabled': {
            backgroundColor: 'transparent',
            color: COLORS.blue[500],
            opacity: 0.7,
          },
        }}
      >
        USER
      </Button>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={cancelModal}
        submitButtonName="Save"
        disabledSubmit={userEmail.length === 0 || !isValidEmail(userEmail)}
        submitCallback={createUser}
        title="Add User"
        openDialog={openDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 20px 13px' }}>
          <AddUserModal
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            radioValue={radioValue}
            setRadioValue={setRadioValue}
          />
        </Box>
      </CustomDialog>
    </Box>
  );
}

AddUserHeader.propTypes = {
  changeValue: func,
  radioValue: string,
  setRadioValue: func,
  setUserEmail: func,
  userEmail: string,
};
