import { Box, Breadcrumbs, Typography } from '@mui/material';
import { useGetUserDataByIdQuery } from '../../../../api/Clients/api';
import { bool, func, string } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { COLORS } from '../../../../shared/styles/Theme';
import { getIconComponent } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import BreadcrumbsButton from '../../../../components/BreadcrumbsButton';

export default function CustomBreadcrumbs({
  closeDialog = () => {},
  isVisitAdding = false,
  resetToInitialView = () => {},
  title = '',
  visitType = '',
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { data: userData, error } = useGetUserDataByIdQuery(id);
  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  const userName = () =>
    `${userData?.firstName} ${userData?.lastName}`;

  const moveToProfile = () => {
    closeDialog();
    resetToInitialView();
  };
  const onLinkClick = ({ e, url }) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    } else {
      navigate(url);
      closeDialog();
    }
  };
  return (
    <Breadcrumbs
      aria-label="breadcrumbs"
      sx={{ '& .MuiBreadcrumbs-li .MuiTypography-root': { cursor: 'pointer' } }}
    >
      <BreadcrumbsButton
        name="Clients"
        onLinkClick={(e) => onLinkClick({ e, url: '/clients' })}
      />
      <BreadcrumbsButton name={userName()} onLinkClick={moveToProfile} />
      {isVisitAdding ? (
        <BreadcrumbsButton
          name={title}
          onLinkClick={(e) => onLinkClick({ e, url: `/clients/${id}` })}
        />
      ) : (
        <Typography sx={{ color: COLORS.blue[900] }} variant="body1">
          {title}
        </Typography>
      )}
      {isVisitAdding && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& img': {
              height: '14px',
            },
          }}
        >
          {getIconComponent(visitType)}
          <Typography
            sx={{ color: COLORS.blue[900], ml: '8px' }}
            variant="body1"
          >
            Add Visit
          </Typography>
        </Box>
      )}
    </Breadcrumbs>
  );
}

CustomBreadcrumbs.propTypes = {
  closeDialog: func,
  isVisitAdding: bool,
  resetToInitialView: func,
  title: string,
  visitType: string,
};
