import { Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import MultiTeamIcon from '../../../../img/icons/multiTeam-icon.svg';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { COLORS } from '../../../../shared/styles/Theme';
import CustomTooltip from '../../../../components/CustomTooltip';
import { truncateName } from '../../constants';
import { caregiverInfoWrapper } from '../styles';

export default function CaregiverInfo({ caregiver = {}, editMode = false }) {
  return (
    <Box sx={caregiverInfoWrapper}>
      <Box sx={{ display: 'flex' }}>
        {editMode ? (
          <CustomTooltip title={`${caregiver?.firstName} ${caregiver?.lastName}`}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                color: COLORS.blue[700],
              }}
            >
              {truncateName(`${caregiver?.firstName} ${caregiver?.lastName}`, 30, 30)}
            </Typography>
          </CustomTooltip>
        ) : (
          <Link
            component={RouterLink}
            to={`/caregivers/${caregiver.id}`}
            underline="none"
            sx={{ mr: '4px' }}
          >
            <CustomTooltip title={`${caregiver?.firstName} ${caregiver?.lastName}`}>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  color: COLORS.blue[700],
                  cursor: 'pointer',
                }}
              >
                {truncateName(`${caregiver?.firstName} ${caregiver?.lastName}`, 30, 30)}
              </Typography>
            </CustomTooltip>
          </Link>
        )}
        {(caregiver?.primaryTeams?.length > 1 || (caregiver?.primaryTeams?.length > 0 && caregiver?.secondaryTeams?.length > 0) || caregiver?.secondaryTeams?.length > 1) && (
          <img src={MultiTeamIcon} alt="multiTeam" />
        )}
      </Box>
    </Box>
  );
}

CaregiverInfo.propTypes = {
  caregiver: instanceOf(Object),
  editMode: bool,
};
