import { Alert, AlertTitle, Box, Grow, Stack } from '@mui/material';
import { useGetWeekendsSchedulingDaysQuery } from '../../../../api/Administration/api';
import {
  useConfirmCaregiverAvailabilityMutation,
  useGetCaregiverAvailabilityQuery
} from '../../../../api/Availability/api';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT, SHORT_DATE_FORMAT } from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import AvailabilityControls from './components/AvailabilityControls';
import AvailabilityTable from './components/AvailabilityTable';
import CaregiverAvailabilityHistory from './components/CaregiverAvailabilityHistory';
import EditAvailability from './components/EditAvailability';
import EffectiveDateDialog from './components/EffectiveDateDialog';
import AvailabilityNoRowsComponent from './components/NoRowsComponent';
import OptimizerCheck from '../../../../components/Optimizer/OptimizerCheck';
import { availabilityScheduleModels, viewTypeButtons } from './constants';
import { successAlertStyles } from './styles';

export default function Availability() {
  const { setAlert } = useContext(AlertContext);
  const { id, tabSubView } = useParams();
  const navigate = useNavigate();
  const [availabilityScheduleView, setAvailabilityScheduleView] = useState(
    availabilityScheduleModels.current,
  );
  const [showMultiplyMode, setShowMultiplyMode] = useState(false);
  const initialEffectiveDate = moment().add(1, 'day');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [date, setDate] = useState(initialEffectiveDate);
  const [editMode, setEditMode] = useState(false);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [availabilityResponseData, setAvailabilityResponseData] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [effectiveDates, setEffectiveDates] = useState([]);
  const [editedAvailabilityData, setEditedAvailabilityData] = useState(availabilityData);
  const [caregiverData, setCaregiverData] = useState({});
  const [checkData, setCheckData] = useState(null);
  const [openCheckOptomizer, setOpenCheckOptomizer] = useState(false);

  useEffect(() => {
    if (availabilityData.length) {
      const dates = [];
      let id = 1;
      for (let activity in availabilityData) {
        if (!dates.find((date) => date.rawDate === availabilityData[activity].effectiveStartDate)) {
          dates.push({
            name: moment(availabilityData[activity].effectiveStartDate).format(SHORT_DATE_FORMAT),
            infoType: String(id),
            rawDate: availabilityData[activity].effectiveStartDate,
          });
          id++;
        }
      }
      setEditedAvailabilityData(availabilityData);
      setSelectedDate(dates[0]);
      setEffectiveDates(dates);
    }
  }, [availabilityData]);

  const showWarningTitle =
    availabilityData.length === 2 &&
    moment(availabilityData[0].effectiveDate).isBefore(moment()) &&
    moment(availabilityData[1].effectiveDate).isAfter(moment());

  const {
    data: caregiverDataRaw,
    isLoading,
    refetch,
    error: careGiverAvailabilityError,
  } = useGetCaregiverAvailabilityQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (caregiverDataRaw) {
      setCaregiverData(caregiverDataRaw);
    }
  }, [caregiverDataRaw]);

  const [
    updateAvailability,
    {
      isLoading: isAvailabilityUpdating,
      error: updateCaregiverAvailabilityError,
    },
  ] = useConfirmCaregiverAvailabilityMutation();
  const {
    data: getWeekdays,
    isLoading: getWeekdaysLoading,
    error: getWeekdaysError,
  } = useGetWeekendsSchedulingDaysQuery();
  useEffect(() => {
    if (
      careGiverAvailabilityError ||
      updateCaregiverAvailabilityError ||
      getWeekdaysError
    ) {
      const errorData =
        careGiverAvailabilityError ||
        updateCaregiverAvailabilityError ||
        getWeekdaysError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    careGiverAvailabilityError,
    getWeekdaysError,
    setAlert,
    updateCaregiverAvailabilityError,
  ]);

  useEffect(() => {
    if (caregiverData) {
      const availabilities  = [];
      let id = 0;
      for (let activity in caregiverData.availabilities) {
        availabilities.push({...caregiverData.availabilities[activity], day: activity, id: String(id)});
        id++;
      };
      for (let activity in caregiverData?.pendingChanges?.availabilities) {
        for (let day in caregiverData.pendingChanges.availabilities[activity]) {
          availabilities.push({...caregiverData.pendingChanges.availabilities[activity][day], day: day, id: String(id)});
          id++;
        }
      }
      setAvailabilityData(availabilities);
    }
  }, [caregiverData]);

  useEffect(() => {
    if (tabSubView === 'current') {
      refetch();
    }
  }, [refetch, availabilityScheduleView, tabSubView]);

  useEffect(() => {
    if (
      availabilityResponseData?.length === 2 &&
      moment(availabilityData[0]?.effectiveDate).isBefore(moment(), 'day') &&
      moment(availabilityData[1]?.effectiveDate) >
        moment(availabilityData[0]?.effectiveDate) &&
      moment(availabilityData[1]?.effectiveDate).isBefore(moment(), 'day')
    ) {
      setAvailabilityResponseData(availabilityResponseData.slice(1));
    }
  }, [availabilityData, availabilityScheduleView, availabilityResponseData]);

  const checkAvailabilityData = () => {
    const newAvailability = {};
    for (let activity in editedAvailabilityData) {
      const day = editedAvailabilityData[activity].day;
      delete editedAvailabilityData[activity].day
      const effectiveDate = editedAvailabilityData[activity].effectiveStartDate
      if (!newAvailability[effectiveDate]){
        newAvailability[effectiveDate] = {};
      }
      newAvailability[effectiveDate][day] = editedAvailabilityData[activity];
    }
    const newCaregiverData = {...caregiverData};
    const newPendingData = {...newCaregiverData.pendingChanges, availabilities: newAvailability};
    newCaregiverData.pendingChanges = newPendingData;
    setCheckData([newCaregiverData]);
    setOpenCheckOptomizer(true);
  };

  const updateAvailabilityData = (newCaregiverData) => {
    setCaregiverData(newCaregiverData);
    updateAvailability(newCaregiverData)
    .unwrap()
    .then(() => {
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
    });
    setEditMode(false);
    setDate(initialEffectiveDate);
  };

  const submitAvailabilityDialog = () => {
    setOpenDialog(false);
    setEditMode(true);
    setShowMultiplyMode(true);
    if (
      !availabilityData[0]?.effectiveDate ||
      moment(availabilityData[0]?.effectiveDate).isAfter(
        moment(),
        'day',
      )
    ) {
      setAvailabilityScheduleView(availabilityScheduleModels.current);
      return '';
    }
    if (
      caregiverData.length < 2 &&
      caregiverData[availabilityScheduleView].effectiveDate
    ) {
      setAvailabilityScheduleView(availabilityScheduleModels.future);
      return '';
    }
    if (
      caregiverData.length > 1 &&
      moment(availabilityData[0].effectiveDate).isBefore(moment()) &&
      moment(availabilityData[1].effectiveDate).isBefore(moment())
    ) {
      setAvailabilityScheduleView(availabilityScheduleModels.future);
    }
    if (
      caregiverData.length > 1 &&
      moment(availabilityData[1].effectiveDate).isAfter(moment())
    ) {
      setAvailabilityScheduleView(availabilityScheduleModels.future);
      return '';
    }
    return '';
  };
  const cancelEditMode = () => {
    setEditMode(false);
    setAvailabilityScheduleView(availabilityScheduleModels.current);
    setDate(initialEffectiveDate);
  };

  const viewEffectiveDate = [
    {
      name: moment(availabilityData[0]?.effectiveDate).format(
        SHORT_DATE_FORMAT,
      ),
      infoType: availabilityScheduleModels.current,
    },
    {
      name: moment(availabilityData[1]?.effectiveDate).format(
        SHORT_DATE_FORMAT,
      ),
      infoType: availabilityScheduleModels.future,
    },
  ];
  const sortedViewEffectiveDate = viewEffectiveDate.sort((a, b) =>
    moment(a.name, SHORT_DATE_FORMAT).diff(moment(b.name, SHORT_DATE_FORMAT)),
  );
  const changeSubView = (value) =>
    navigate(`/caregivers/${id}/availability/${value}`);
  return (
    <Stack sx={{ maxWidth: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <ButtonsGroup
          buttons={viewTypeButtons}
          changeSelected={changeSubView}
          selected={tabSubView}
        />
        {tabSubView === 'current' && !isLoading &&(
          <AvailabilityControls
            availabilityData={availabilityData}
            setOpenDialog={setOpenDialog}
            effectiveDates={effectiveDates}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
          />
        )}
        <EffectiveDateDialog
          openAvailabilityDialog={openDialog}
          date={date}
          selectDate={(newValue) => setDate(newValue)}
          closeAvailabilityDialog={() => {
            setOpenDialog(false);
            setDate(initialEffectiveDate);
          }}
          submitAvailabilityDialog={submitAvailabilityDialog}
          showWarningTitle={showWarningTitle}
        />
        <Grow in={showSuccessAlert}>
          <Alert sx={successAlertStyles} severity="success">
            <AlertTitle>Success</AlertTitle>
            Successfully update Availability
          </Alert>
        </Grow>
      </Box>
      <Box sx={{ marginTop: '30px' }}>
        {tabSubView === 'current' ? (
          <Box>
            <AvailabilityTable
              availabilityData={availabilityData}
              isLoading={isLoading || isAvailabilityUpdating}
              noRowsOverlay={AvailabilityNoRowsComponent}
              availabilityScheduleView={availabilityScheduleView}
              selectedDate={selectedDate}
            />
            <EditAvailability
              availabilityData={availabilityData}
              availabilityScheduleView={availabilityScheduleView}
              cancelEditMode={cancelEditMode}
              closeDialog={cancelEditMode}
              dbData={availabilityResponseData}
              disabledDays={getWeekdays?.schema}
              isLoading={
                isLoading ||
                isAvailabilityUpdating ||
                getWeekdaysLoading
              }
              openEditMode={editMode}
              setAvailabilityData={setAvailabilityData}
              setShowMultiplyMode={setShowMultiplyMode}
              showMultiplyMode={showMultiplyMode}
              startDate={
                availabilityData[availabilityScheduleView]?.effectiveDate ||
                moment(date).format(ISO_DATE_ONLY_FORMAT)
              }
              submitCallback={checkAvailabilityData}
              setAvailabilityScheduleView={setAvailabilityScheduleView}
              viewEffectiveDate={sortedViewEffectiveDate}
              selectedDate={selectedDate}
              editedAvailabilityData={editedAvailabilityData}
              setEditedAvailabilityData={setEditedAvailabilityData}
            />
            <OptimizerCheck
              data={checkData}
              dataObjName="caregiverObj"
              submitFunction={updateAvailabilityData}
              open={openCheckOptomizer}
              openToggle={setOpenCheckOptomizer}
            />
          </Box>
        ) : (
          <CaregiverAvailabilityHistory />
        )}
      </Box>
    </Stack>
  );
}
