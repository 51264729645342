/* eslint-disable no-param-reassign */
import { Box } from '@mui/material';
import {
  useGetHolidaysQuery,
  useGetTerritoryQuery,
  useGetTeamsQuery,
} from '../../api/Administration/api';
import moment from 'moment-timezone';
import { bool, func, instanceOf, string } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { CALIFORNIA_TIME_ZONE } from '../../shared/constants';
import { VisitInfoTypeContext } from '../../shared/context';
// import { useWebSocket } from '../../shared/hooks/useWebsocket';
import {
  checkIfIsHoliday,
  combineTeamsAndTerritory,
} from '../../shared/utils/common';
import AlertContext from '../../components/Alert';
import CommonScheduling from './components/CommonScheduling';
import EditScheduling from './components/EditCalendar/EditScheduling';
import ExpandButton from './components/ExpandButton';
import PtoAndCallouts from './components/PtoAndCalloutsTable';
import CustomHeader from './components/SchedulingHeader/SchedulerCalendarHeader';
import SchedulingToolbar from './components/SchedulingToolbar';
import VisitsTableView from './components/VisitsTable';
import {
  DEFAULT_HEADER_HEIGHT,
  EXPANDED_HEADER_HEIGHT,
  HIDE_HEADER_HEIGHT,
  HIDE_HEADER_HEIGHT_EXPANDED,
  LOCAL_STORAGE_KEYS,
  TIME_CONTENT_HEIGHT,
  TIME_CONTENT_HEIGHT_EXPANDED,
  findFirstAndLastAvailability,
  notAllocatedDescription,
} from './constants';
import './index.css';
import { hideContent, hideHeader } from './styles';

export default function Scheduling({
  currentDate = {},
  eventsLoading = false,
  reloadSchedulingForDate = () => {},
  reFetchCalendarEvents = () => {},
  schedulerEvents = null,
  setCurrentDate = () => {},
  today = {},
  todayDate = '',
}) {
  const { tab } = useParams();
  const eventWrapperRef = useRef();
  const { setAlert } = useContext(AlertContext);
  const {
    scheduleEvents,
    caregiversList,
    notAllocatedEvents,
    tableViewEvents,
  } = schedulerEvents || {};
  const { onOpenVisitInfoTypeDialog } = useContext(VisitInfoTypeContext);
  const { data: holidays, error: getHolidaysError } = useGetHolidaysQuery();
  const {
    data: territories,
    error: territoriesError,
  } = useGetTerritoryQuery();

  const {
    data: teams,
    error: teamsError,
  } = useGetTeamsQuery();

  const [location, setLocation] = useState([]);
  useEffect(() => {
    if (territories?.length > 0 && teams?.length > 0) {
      const tempLocations = [];
      territories.forEach((territory) => {
        let thisTeams = []
        teams.forEach((team) => {
          if (team.territory === territory.id) {
            thisTeams.push(team);
          }  
        });
        tempLocations.push({
          ...territory,
          teams: thisTeams,
        });
      });
      setLocation(tempLocations);
    }
  }, [territories, teams]);

  useEffect(() => {
    if (getHolidaysError || territoriesError || teamsError) {
      const errorData = getHolidaysError || territoriesError || teamsError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getHolidaysError, territoriesError, teamsError, setAlert]);

  const locationsList = useMemo(() => [...location], [location]);

  const getInitialTeamsList = useMemo(() => {
    const allLocations = combineTeamsAndTerritory(locationsList);
    return localStorage.getItem(LOCAL_STORAGE_KEYS.teamsFilter)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.teamsFilter))
      : { teams: allLocations?.map((t) => t) };
  }, [locationsList]);

  const [editMode, setEditMode] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [currentTimeMinutes, setCurrenTimeMinutes] = useState(
    moment().minutes(),
  );
  const [showDetails, setShowDetails] = useState(false);
  const [teamsFilter, setTeamsFiler] = useState(getInitialTeamsList);
  const [caregivers, setCaregivers] = useState(null);

  useEffect(() => {
    setTeamsFiler(getInitialTeamsList);
  }, [getInitialTeamsList]);

  const isDateHoliday = checkIfIsHoliday(
    currentDate || todayDate,
    holidays,
    currentDate || todayDate,
  );
  const isCalendarView = tab === 'calendar';
  // const { message } = useWebSocket();
  useEffect(() => {
    if (scheduleEvents) {
      setEventsList([...scheduleEvents, ...notAllocatedEvents]);
    }
    // eslint-disable-next-line
  }, [scheduleEvents]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTimeIndicator = document.querySelector(
        '.rbc-current-time-indicator',
      );
      const currentTimeIndicatorLabel = document.querySelector(
        '.custom-time-indicator-scheduling',
      );
      const newTime = moment().tz(CALIFORNIA_TIME_ZONE).minutes();
      if (currentTimeMinutes !== newTime || !currentTimeIndicatorLabel) {
        setCurrenTimeMinutes(newTime);
        const currentTimeString = `${moment()
          .tz(CALIFORNIA_TIME_ZONE)
          .format('h:mm A')}(PST)`;
        currentTimeIndicator?.classList.add('custom-time-indicator-scheduling');
        document
          .querySelector('.custom-time-indicator-scheduling')
          ?.setAttribute('data-current-time', currentTimeString);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [currentTimeMinutes]);

  useEffect(() => {
    if (caregiversList && caregiversList.length > 0) {
      const tempFilteredCaregivers = caregiversList?.filter((caregiver) =>
          teamsFilter?.teams?.find((team) => (caregiver?.primaryTeams.includes(team.id) || caregiver?.secondaryTeams.includes(team.id)))
        )
      setCaregivers(tempFilteredCaregivers);
    }
  }, [caregiversList, teamsFilter]);

  const resourceMap = useMemo(() => {
    if (caregivers) {
      const addAllocatedVisits = [
        ...(notAllocatedEvents?.length ? [notAllocatedDescription] : []),
        ...caregivers,
      ];
      return addAllocatedVisits.map((caregiver, index) => ({
        resourceId: caregiver.id,
        resourceTitle: (
          <CustomHeader
            key={index}
            caregiver={caregiver}
            editMode={editMode}
            isDateHoliday={isDateHoliday}
            selectedDate={currentDate}
          />
        ),
      }));
    }
  }, [
    currentDate,
    editMode,
    caregivers,
    isDateHoliday,
    notAllocatedEvents?.length,
  ]);

  const dayPropGetter = useCallback(() => {
    const dayProps = {};

    if (isDateHoliday) {
      dayProps.className = 'holiday';
      dayProps.style = {
        ...dayProps.style,
        background:
          'repeating-linear-gradient(135deg, #EAF1FF, #EAF1FF 6px, #fff 5px, #fff 12px) !important',
      };
    }
    return dayProps;
  }, [isDateHoliday]);

  const onNavigate = useCallback(
    (newDate) => {
      setCurrentDate(newDate);
    },
    [setCurrentDate],
  );
  const slotPropGetter = useCallback(
    (date, resourceId) => {
      const slotProps = {};
      if (resourceId){
        const thisWeekday = moment(date).weekday();
        const slotTime = moment(date);
        if (resourceId === 1) {
          slotProps.style = {
            background: '#F7F9FF',
          };
        }
        const caregiver = caregiversList?.find(
          (care) => care.id === resourceId,
        );
        if (!isDateHoliday) {
          let thisAvailability = caregiver?.availabilities;
          if (caregiver?.pendingChanges?.availabilities){
            for (let availDate in caregiver?.pendingChanges?.availabilities){
              const availDateMoment = moment(availDate, 'YYYY-MM-DD');
              if (availDateMoment <= moment(date)){
                thisAvailability = caregiver?.pendingChanges?.availabilities[availDate];
              }
            }
          }
          const startTimeHours = moment(thisAvailability?.[thisWeekday]?.startTime, 'h:mm:ss')
          const endTimeHours = moment(thisAvailability?.[thisWeekday]?.endTime, 'h:mm:ss')
          const startTime = moment(date).set({
            hour: startTimeHours?.hours(),
            minute: startTimeHours?.minutes() - 1,
            second: 0,
            millisecond: 0,
          });
          const endTime = moment(date).set({
            hour: endTimeHours?.hours(),
            minute: endTimeHours?.minutes(),
            second: 0,
            millisecond: 0,
          });
          if (slotTime.isBetween(startTime, endTime)) {
            slotProps.style = {
              background: '#fff',
            };
          }
        }
      }
      return slotProps;
    },
    [isDateHoliday, caregiversList],
  );

  useEffect(() => {
    const timeContent = document.querySelector('.rbc-time-content');
    const timeHeader = document.querySelector('.rbc-time-header');
    if (showDetails && timeHeader) {
      timeHeader?.classList.add('custom-time-header-scheduling');
      timeHeader.style.minHeight = EXPANDED_HEADER_HEIGHT;
    } else if (timeHeader) {
      timeHeader?.classList.remove('custom-time-header-scheduling');
      timeHeader.style.minHeight = DEFAULT_HEADER_HEIGHT;
    }

    if (timeContent) {
      if (showDetails) {
        timeContent.style.maxHeight = TIME_CONTENT_HEIGHT_EXPANDED;
      } else {
        timeContent.style.maxHeight = TIME_CONTENT_HEIGHT;
      }
    }

    const expandedHeader = document.querySelectorAll('.expandedHeader');
    if (expandedHeader) {
      expandedHeader.forEach((headerStyle) => {
        if (showDetails) {
          headerStyle.style.opacity = '1';
          headerStyle.style.height = 'auto';
          headerStyle.style.visibility = 'visible';
        } else {
          // headerStyle.style.display = 'none';
          headerStyle.style.opacity = '0';
          headerStyle.style.height = '0';
          headerStyle.style.visibility = 'hidden';
        }
      });
    }
  }, [caregivers, showDetails, tab, editMode, eventsList, onNavigate]);

  const onSelectEvent = useCallback(
    (infoTypeData) => {
      if (infoTypeData.title === 'Lunch break' || infoTypeData.isShadowVisit) {
        return;
      }
      onOpenVisitInfoTypeDialog(infoTypeData);
    },
    [onOpenVisitInfoTypeDialog],
  );

  const minStartTime = moment().subtract(30, 'minute').toDate();
  const firstLastAvailability = findFirstAndLastAvailability(caregivers);
  const calendarComponent = useMemo(
    () => (
        <CommonScheduling
          currentDate={currentDate}
          dayPropGetter={dayPropGetter}
          eventsList={eventsList}
          firstVisit={firstLastAvailability.first}
          isDateHoliday={isDateHoliday}
          lastVisit={firstLastAvailability.last}
          minStartTime={minStartTime}
          onNavigate={onNavigate}
          onSelectEvent={onSelectEvent}
          reloadSchedulingForDate={reloadSchedulingForDate}
          resourceMap={resourceMap}
          slotPropGetter={slotPropGetter}
        />
  ), // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDate, eventsList, onNavigate, dayPropGetter, resourceMap],
  );
  const changeTeamsFilter = useCallback((newTeamsValue) => {
    setTeamsFiler(newTeamsValue);
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.teamsFilter,
      JSON.stringify(newTeamsValue),
    );
  }, []);
  const editCalendarComponent = useMemo(
    () => (
      <EditScheduling
        currentDate={currentDate}
        dayPropGetter={dayPropGetter}
        eventsList={eventsList}
        filteredCaregivers={caregivers}
        firstVisit={firstLastAvailability.first}
        isDateHoliday={isDateHoliday}
        lastVisit={firstLastAvailability.last}
        notAllocatedEvents={notAllocatedEvents}
        onNavigate={onNavigate}
        resourceMap={resourceMap}
        setEditMode={setEditMode}
        setTeamsFiler={changeTeamsFilter}
        showDetails={showDetails}
        slotPropGetter={slotPropGetter}
        teamsFilter={teamsFilter}
        todayDate={todayDate}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentDate,
      caregivers,
      editMode,
      eventsList,
      onNavigate,
      dayPropGetter,
      resourceMap,
    ],
  );

  const viewComponent = useMemo(() => {
    if (tab === 'calendar') {
      return (
        <div style={{ width: '100%', position: 'relative' }}>
          {calendarComponent}
        </div>
      );
    }
    if (tab === 'visits') {
      return (
        <VisitsTableView
          date={currentDate || today}
          eventsArray={tableViewEvents}
          isDateHoliday={isDateHoliday}
          isLoading={eventsLoading}
          locationsList={locationsList}
          onNavigate={onNavigate}
          reloadSchedulingForDate={reloadSchedulingForDate}
          selectedDate={currentDate || today}
          setTeamFilter={changeTeamsFilter}
          teamsFilter={teamsFilter}
        />
      );
    }
    if (tab === 'time-off-requests') {
      return (
        <PtoAndCallouts
          teamsArray={locationsList}
          teamsFilter={teamsFilter}
          setTeamFilter={changeTeamsFilter}
        />
      );
    }
    return null;
  }, [
    calendarComponent,
    changeTeamsFilter,
    currentDate,
    eventsLoading,
    isDateHoliday,
    locationsList,
    onNavigate,
    reloadSchedulingForDate,
    tab,
    tableViewEvents,
    teamsFilter,
    today,
  ]);
  return (
    <div style={{ width: '100%', position: 'relative' }} ref={eventWrapperRef}>
      {isCalendarView && (
        <Box>
          <Box
            className="hideHeader"
            sx={{
              ...hideHeader,
              height: showDetails
                ? HIDE_HEADER_HEIGHT_EXPANDED
                : HIDE_HEADER_HEIGHT,
            }}
          />
          <Box className="hideContent" sx={hideContent} />
        </Box>
      )}
      {isCalendarView && (
        <SchedulingToolbar
          editMode={editMode}
          isDateHoliday={isDateHoliday}
          setEditMode={setEditMode}
          setTeamsFiler={changeTeamsFilter}
          teamsFilter={teamsFilter}
        />
      )}
      {isCalendarView && (
        <ExpandButton
          setShowDetails={setShowDetails}
          showDetails={showDetails}
        />
      )}
      {editMode ? editCalendarComponent : viewComponent }
    </div>
  );
}

Scheduling.propTypes = {
  currentDate: instanceOf(Object),
  eventsLoading: bool,
  reloadSchedulingForDate: func,
  reFetchCalendarEvents: func,
  schedulerEvents: instanceOf(Object),
  setCurrentDate: func,
  today: instanceOf(Object),
  todayDate: string,
};
