import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';

// change name
export const CONFIGS_REAL_API_KEY = 'configsRealApi';
const configRealApi = createApi({
  reducerPath: CONFIGS_REAL_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: (builder) => ({
    getConfig: builder.query({
      query: (types) => ({
        url: `crud/config`,
        method: 'GET',
        params: { 
          index: "active-index",
          pk: "active",
          pkValue: "True",
          filters: JSON.stringify(types.map(type => ({key: "type", value: type, operation: "=", preOperation: "OR"}))),
         },
      }),
      transformResponse: (response) => {
        const returnObj = {};
        for (let item in response) {
            if (!returnObj[response[item].type]) {
                returnObj[response[item].type] = [];
            }
            returnObj[response[item].type].push(response[item]);
        }
        return returnObj;
      }
    }),
  }),
});

export const {
  useGetConfigQuery
} = configRealApi;

export default configRealApi;
