import { Box } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import styles from './style.module.css';
import {
  WEEK_DAYS
} from '../../shared/constants';

const formatKeyName = (key) => {
  if (!key || typeof key !== 'string') return '';
  return key
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, (str) => str.toUpperCase());
};

const getDayNameById = (id) => {
  const day = WEEK_DAYS.find((day) => day.id === id);
  return day ? day.name : id;
};

const formatValue = (value, fieldKey, details = [], idField = 'id', nameField = 'name') => {
  if (fieldKey === 'days') {
    return value.map(getDayNameById).join(', ');
  }

  if (Array.isArray(value)) {
    return value
      .map((item) => {
        if (item[idField] && item[nameField]) {
          return item[nameField];  // Ensure we're rendering just the name field, not the entire object
        }
        return JSON.stringify(item); // Convert non-object items into a string
      })
      .join(', ');
  }

  // Fix for handling objects: Ensure we're rendering only the `name` field or primitive value.
  if (typeof value === 'object' && value !== null) {
    // If value is an object and contains a `name` field, return the `name` value
    if (value.name) {
      return value.name.toString();  // Ensure we're always returning a string
    }
    return Object.entries(value)
      .map(([key, val]) => `${key}: ${formatValue(val, key, details, idField, nameField)}`)
      .join(', ');
  }

  return value?.toString() || 'None';
};


const getHistoryList = (arr) => (
  <ul className={styles.bulletList}>
    {arr?.data?.map((item, index) => {
      const key = `${arr.action}-${index}-${arr.id}`;

      if (arr.action === 'edited') {
        return (
          <li
            key={key}
            className={ 
              !item?.newValue || Object.values(item?.newValue).includes('None')
                ? `${styles.bulletListItem} ${styles.removedType}`
                : styles.bulletListItem
            }
          >
            <span className={styles.boldWeight}>
              {formatKeyName(item?.name)}'s{' '}
            </span>
            {Object.keys(item?.newValue || {}).map((fieldKey) => (
              <span key={fieldKey}>
                {formatKeyName(fieldKey)} is changed from
                <span className={styles.oldTime}>
                  {/* Make sure you're only rendering the string value */}
                  {formatValue(item?.oldValue?.[fieldKey], fieldKey) || 'None'}
                </span>{' '}
                to
                <span className={styles.newTime}>
                  {/* Make sure you're only rendering the string value */}
                  {formatValue(item?.newValue?.[fieldKey], fieldKey) || 'None'}
                </span>
              </span>
            ))}
          </li>
        );
      }
      
      if (arr.action === 'removed') {
        return (
          <li
            key={key}
            className={`${styles.bulletListItem} ${styles.removedType}`}
          >
            <span className={styles.boldWeight}>
              {/* Only render the name string, not the whole object */}
              {item?.oldValue?.name || `${item?.oldValue?.firstName || ''} ${item?.oldValue?.lastName || ''}`}
            </span>
            {" was removed"}
          </li>
        );
      }
      
      if (arr.action === 'added') {
        return (
          <li
            key={key}
            className={`${styles.bulletListItem} ${styles.addedType}`}
          >
            <span className={styles.boldWeight}>
              {/* Only render the name string */}
              {item?.newValue?.name || `${item?.newValue?.firstName || ''} ${item?.newValue?.lastName || ''}`}{' '}
            </span>
            {" was added"}
          </li>
        );
      }

      return null;
    })}
  </ul>
);

export default function HistoryBulletList({ changes = [] }) {
  return <Box sx={{ paddingLeft: '20px' }}>{getHistoryList(changes)}</Box>;
}

HistoryBulletList.propTypes = {
  changes: instanceOf(Object),
};
