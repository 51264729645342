import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { constraintsIconsTypes } from '../../constants';
import AdditionalVisitDetails from '../AdditionalVisitDetails';
import CaregiverVisitNotes from '../CaregiverVisitNotes';
import ConstraintsAlert from '../ConstraintsAlert';
import VisitInfoFields from '../VisitInfoFields/VisitInfoFields';

export default function VisitInfoTypeContent() {
  const { visitInfoData } =
    useContext(VisitInfoTypeContext);

  const constraintsViolations =
  visitInfoData?.violatedHardConstraints.length > 0 ||
  visitInfoData?.violatedSoftConstraints.length > 0;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {!!constraintsViolations && (
        <ConstraintsAlert
          alertData={constraintsViolations}
          iconType={constraintsIconsTypes.triangle}
        />
      )}
      <VisitInfoFields />
      {!!visitInfoData?.visitDetails?.length && (
        <AdditionalVisitDetails notes={visitInfoData?.visitDetails} />
      )}
      {!!visitInfoData?.visitNotes?.length && (
        <CaregiverVisitNotes notes={visitInfoData?.visitNotes} />
      )}
    </Box>
  );
}
