import { Stack, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import noDataIcon from '../../../../../../../../../../img/icons/no-data.svg';
import VisitDateAccordion from '../../../../../../../../../../pages/ClientDetails/components/VisitDateAccordion';
import { instanceOf } from 'prop-types';
import React, { useCallback } from 'react';
import Loader from '../../../../../../../../../../components/Loader';
import CustomTable from '../../../../../../../../../../components/Table';
import { noDataTableHintStyles } from '../../../../../../../../../../components/Table/styles';
import { columns, summaryDataAccordions } from './constants';
import { tasksManagementTableStyles } from './styles';

function SummaryData({ summaryData = {} }) {
  const apiRef = useGridApiRef();
  const getNoRowsComponent = useCallback(
    () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <img src={noDataIcon} alt="" />
        <Typography sx={noDataTableHintStyles}>No visits yet. </Typography>
      </Stack>
    ),
    [],
  );
  if (!Object.keys(summaryData).length) {
    return <Loader />;
  }
  return summaryDataAccordions?.map(({ dataKey, title }) => (
    <VisitDateAccordion
      disableToAddTasks
      hideIfEmpty
      key={dataKey}
      tasksCount={summaryData[dataKey].length}
      title={title}
    >
      <CustomTable
        apiRef={apiRef}
        columns={columns}
        customStyles={tasksManagementTableStyles(summaryData[dataKey].length)}
        headerHeight={34}
        noRowsOverlay={() => getNoRowsComponent()}
        onRowClick={() => {}}
        rows={summaryData[dataKey]}
        withoutPagination
        rowIdField="updateId"
      />
    </VisitDateAccordion>
  ));
}

SummaryData.propTypes = {
  summaryData: instanceOf(Object),
};

export default React.memo(SummaryData);
