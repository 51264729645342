import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetUserDataByIdQuery } from '../../../../../../../../api/Clients/api';
import { useGetOneTimeVisitAvailableTasksQuery } from '../../../../../../../../api/Visits/api';
import NoRowsComponent from '../../../../../../../../pages/ClientDetails/components/NoRowsComponent';
import VisitDateAccordion from '../../../../../../../../pages/ClientDetails/components/VisitDateAccordion';
import { func, instanceOf } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import AlertContext from '../../../../../../../../components/Alert';
import CustomTable from '../../../../../../../../components/Table';
import VisitSummary from './VisitSummary';
import { editColumns } from './constants';
import { stepContentWrapperStyles, tasksManagementTableStyles } from './styles';
import { getVisitAccordionTitle, parseDates } from './utils';

export default function TasksManagement({
  availabilityScheduling = {},
  goToEditTasks = () => {},
  setAvailableVisitTasks = () => {},
  visitParams = {},
  availableVisitTasks = {},
}) {
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  const apiRef = useGridApiRef();
  const [details, setDetails] = useState({});
  const { data: userData, error: getUserDataByIdError } =
    useGetUserDataByIdQuery(id);

  const {
    data: visitAvailableTasks,
    isLoading: isLoadingTasks,
    error: getOneTimeVisitAvailableTasksError,
  } = useGetOneTimeVisitAvailableTasksQuery(
    {
      clientId: id,
      payload: {
        visitType: visitParams?.visitType?.name,
        dates: parseDates(availabilityScheduling),
      },
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (getUserDataByIdError || getOneTimeVisitAvailableTasksError) {
      const errorData =
        getUserDataByIdError || getOneTimeVisitAvailableTasksError;

      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getUserDataByIdError, getOneTimeVisitAvailableTasksError, setAlert]);

  useEffect(() => {
    if (visitAvailableTasks) {
      setAvailableVisitTasks(visitAvailableTasks?.tasksByDate);
    }
    const initialDetails = {};
    visitAvailableTasks?.tasksByDate[0]?.tasks.forEach((task) => {
      initialDetails[task.id] = task.details;
    });
    setDetails(initialDetails);
  }, [visitAvailableTasks, setAvailableVisitTasks]);

  const getRowId = (row) => (row ? row.date : '');

  const summaryData = {
    client: `${userData?.firstName} ${userData?.lastName}`,
    address: userData?.address,
    visitType: visitParams.visitType.name,
    duration: visitParams.duration,
  };
  const getNoRowsComponent = useCallback(
    () => <NoRowsComponent goToEditTasks={goToEditTasks} />,
    [goToEditTasks],
  );
  const handleMultilineInputChange = (taskId, newDetails) => {
    setDetails({ ...details, [taskId]: newDetails.target.value });

    const updatedTasks = availableVisitTasks[0]?.tasks?.map((task) =>
      task.id === taskId ? { ...task, details: newDetails.target.value } : task,
    );

    const updatedAvailableVisitTasks = [
      { ...availableVisitTasks[0], tasks: updatedTasks },
    ];
    setAvailableVisitTasks(updatedAvailableVisitTasks);
  };

  return (
    <Box sx={stepContentWrapperStyles}>
      <VisitSummary data={summaryData} />
      {availableVisitTasks?.map((visit) => (
        <VisitDateAccordion
          key={visit.date}
          preferredCaregiver={
            visit.preferredCaregiver
              ? `${visit.preferredCaregiver.firstName} ${visit.preferredCaregiver.lastName}`
              : null
          }
          tasksCount={visit?.tasks?.length}
          title={getVisitAccordionTitle(visit)}
          visit={{ ...visit, visitType: visitParams.visitType.name }}
        >
          <CustomTable
            apiRef={apiRef}
            columns={editColumns({
              dateOfVisit: visit.date,
              details,
              handleMultilineInputChange,
            })}
            customStyles={tasksManagementTableStyles(!visit.tasks.length)}
            headerHeight={34}
            getRowId={getRowId}
            isLoading={isLoadingTasks}
            noRowsOverlay={() => getNoRowsComponent(visit)}
            onRowClick={() => {}}
            rows={visit.tasks}
            withoutPagination
          />
        </VisitDateAccordion>
      ))}
    </Box>
  );
}

TasksManagement.propTypes = {
  availabilityScheduling: instanceOf(Object),
  goToEditTasks: func,
  setAvailableVisitTasks: func,
  visitParams: instanceOf(Object),
  availableVisitTasks: instanceOf(Object),
};
