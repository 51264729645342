import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';

export const USER_DATA_API_KEY = 'userDataApi';

const userDataApi = createApi({
  reducerPath: USER_DATA_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: (builder) => ({
    getUserProfileData: builder.query({
      query: (userId) => ({
        url: 'crud/users',
        method: 'GET',
        params: {
          id: userId,
        },
      }),
    }),
  }),
});
export const { useGetUserProfileDataQuery } = userDataApi;

export default userDataApi;
