import { Box } from '@mui/material';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext } from 'react';
import CustomSelect from '../../../../../components/Select';

export default function LanguagesSelector() {
  const { setProfileData, editingProfileDataLibrary, profileData} =
    useContext(CaregiverProfileContext);

  const onSelectChange = (params) => {
    const newDetails = []
    for (let language in editingProfileDataLibrary.skills.languages){
      if (params.value.includes(editingProfileDataLibrary.skills.languages[language].id)){
        newDetails.push(editingProfileDataLibrary.skills.languages[language])
      }
    }
    setProfileData({
      ...profileData,
      'languages': params.value,
      'languagesDetails': newDetails
    });
  };

  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <CustomSelect
        id="languages"
        options={editingProfileDataLibrary?.skills?.languages}
        isLoading={false}
        isMultiselect
        selectedValue={profileData?.languagesDetails}
        setValue={onSelectChange}
      />
    </Box>
  );
}
