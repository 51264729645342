import { Box, Typography } from '@mui/material';
import {
  useDeleteUserByIdMutation,
  useUpdateUserByIdMutation,
} from '../../../../../../api/Administration/api';
import EditIcon from '../../../../../../img/icons/edit-icon.svg';
import DeleteIcon from '../../../../../../img/icons/iconButtons/delete-icon.svg';
import { instanceOf } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import { isValidEmail } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomDialog from '../../../../../../components/Dialog';
import TableRowActionsWithIcons from '../../../../../../components/TableRowActionsWithIcons';
import AddUserModal from '../AddUserModal';

function UserTableActions({ row = {} }) {
  const { setAlert } = useContext(AlertContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [userEmail, setUserEmail] = useState(row?.email);
  const [radioValue, setRadioValue] = useState(row?.role || 'Admin');
  const [updateUserById, { error: updateUserByIdError }] =
    useUpdateUserByIdMutation();
  const [deleteUser, { error: deleteUserByIdError }] =
    useDeleteUserByIdMutation();

  useEffect(() => {
    if (updateUserByIdError || deleteUserByIdError) {
      const errorData = updateUserByIdError || deleteUserByIdError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [updateUserByIdError, deleteUserByIdError, setAlert]);

  const updateUser = () => {
    updateUserById({ ...row, email: userEmail, role : radioValue});
    setOpenEditDialog(false);
  };
  const deleteHolidayById = () => {
    deleteUser(row);
    setOpenDeleteDialog(false);
  };
  const menuItems = [
    {
      icon: EditIcon,
      key: 'Edit',
      name: 'Edit',
      callback: () => {
        setOpenEditDialog(true);
      },
    },
    {
      icon: DeleteIcon,
      key: 'Remove',
      name: 'Remove',
      callback: () => {
        setOpenDeleteDialog(true);
      },
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingRight: '20px',
      }}
    >
      <Typography>{row.role || 'Admin'}</Typography>

      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenEditDialog(false)}
        submitButtonName="Save"
        disabledSubmit={userEmail?.length === 0 || !isValidEmail(userEmail)}
        submitCallback={updateUser}
        title="Edit User"
        openDialog={openEditDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 20px 13px' }}>
          <AddUserModal
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            radioValue={radioValue}
            setRadioValue={setRadioValue}
          />
        </Box>
      </CustomDialog>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenDeleteDialog(false)}
        submitButtonName="confirm"
        submitCallback={deleteHolidayById}
        title="Confirmation"
        openDialog={openDeleteDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            The user
            <span style={{ fontWeight: 'bold', margin: '0 4px' }}>
              {row.externalId}
            </span>
            will be removed. Do you want to proceed?
          </Typography>
        </Box>
      </CustomDialog>
      <TableRowActionsWithIcons menuItems={menuItems} />
    </Box>
  );
}

UserTableActions.propTypes = {
  row: instanceOf(Object),
};

export default React.memo(UserTableActions);
