import { Box, IconButton, Typography } from '@mui/material';
import {
  useApplyShadowCaregiverMutation,
  useCheckShadowCaregiverAvailabilityMutation,
  useUnmarkShadowCaregiverMutation,
} from '../../../../api/commonApi/api';
import DeleteIcon from '../../../../img/icons/iconButtons/delete-icon.svg';
import { bool, func, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import AlertContext from '../../../../components/Alert';
import Loader from '../../../../components/Loader';
import {
  infoFieldWrapper,
  infoKeyField,
  infoRowEditableInfoWrapper,
  infoRowWrapperStyles,
} from '../../styles';
import EditIconButton from '../EditIconButton';
import LinkItem from '../VisitInfoFields/LinkItem';
import AddShadowCaregiverButton from './AddShadowCaregiverButton';
import {
  VisitShadowCaregiverSetup,
  VisitUnmarkShadowCaregiver,
} from './VisitShadowCaregiverSetup';

export default function VisitShadowCaregiver({
  cancelShadowCaregiverEditing = () => {},
  caregiverName = "",
  caregiverId = "",
  editingShadowCaregiver = false,
  enableToEditShadowCaregiver = false,
  onEditShadowCaregiver = () => {},
  unMarkingShadowCaregiver = false,
  onUnmarkShadowCaregiver = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const [shadowCaregiverAvailability, setShadowCaregiverAvailability] =
    useState(null);
  const [
    applyShadowCaregiver,
    { isLoading: applyLoading, error: updateVisitDetailsError },
  ] = useApplyShadowCaregiverMutation();
  const [
    unmarkShadowCaregiver,
    { isLoading: unmarkLoading, error: unmarkError },
  ] = useUnmarkShadowCaregiverMutation();
  const [
    checkShadowCaregiverAvailability,
    {
      data: checkShadowCaregiverAvailabilityData,
      error: checkShadowCaregiverAvailabilityError,
      isLoading: checkShadowCaregiverAvailabilityLoading,
    },
  ] = useCheckShadowCaregiverAvailabilityMutation();

  useEffect(() => {
    const errorData =
      updateVisitDetailsError ||
      unmarkError ||
      checkShadowCaregiverAvailabilityError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    checkShadowCaregiverAvailabilityError,
    setAlert,
    unmarkError,
    updateVisitDetailsError,
  ]);

  useEffect(() => {
    if (checkShadowCaregiverAvailabilityData) {
      setShadowCaregiverAvailability(checkShadowCaregiverAvailabilityData);
    }
  }, [checkShadowCaregiverAvailabilityData]);

  const deleteShadowCaregiver = () => onUnmarkShadowCaregiver(true);
  return (
    <Box>
      {(applyLoading ||
        unmarkLoading ||
        checkShadowCaregiverAvailabilityLoading) && <Loader />}
      {!editingShadowCaregiver && !unMarkingShadowCaregiver && (
        <Box sx={{ ...infoRowWrapperStyles, alignItems: 'start' }}>
          <Typography sx={infoKeyField}>Shadow Caregiver:</Typography>
          <Box sx={infoFieldWrapper}>
            <Box sx={infoRowEditableInfoWrapper}>
              {caregiverName ? (
                <LinkItem
                  link={`/caregivers/${caregiverId}`}
                  name={caregiverName}
                />
              ) : (
                <Typography>-</Typography>
              )}
              {enableToEditShadowCaregiver && caregiverId && (
                <Box sx={{ display: 'flex', gap: '12px' }}>
                  <EditIconButton
                    onEdit={onEditShadowCaregiver}
                    tooltip="Edit shadow caregiver"
                  />
                  <IconButton
                    aria-label="delete"
                    disableRipple
                    onClick={deleteShadowCaregiver}
                    sx={{ p: 0 }}
                  >
                    <img src={DeleteIcon} alt="Delete" />
                  </IconButton>
                </Box>
              )}
            </Box>
            <AddShadowCaregiverButton
              enableToEditShadowCaregiver={enableToEditShadowCaregiver}
              hasShadowCaregiver={!!caregiverId}
              onEditShadowCaregiver={onEditShadowCaregiver}
            />
          </Box>
        </Box>
      )}
      {unMarkingShadowCaregiver && (
        <VisitUnmarkShadowCaregiver
          applyUnmarkCaregiver={unmarkShadowCaregiver}
          caregiverName={caregiverName}
          closeUnmarkCaregiver={() => onUnmarkShadowCaregiver(false)}
        />
      )}
      {editingShadowCaregiver && (
        <VisitShadowCaregiverSetup
          applyShadowCaregiver={applyShadowCaregiver}
          cancelEditing={cancelShadowCaregiverEditing}
          checkShadowCaregiverAvailability={checkShadowCaregiverAvailability}
          checkShadowCaregiverAvailabilityData={shadowCaregiverAvailability}
          clearShadowCaregiverAvailabilityData={() =>
            setShadowCaregiverAvailability(null)
          }
        />
      )}
    </Box>
  );
}

VisitShadowCaregiver.propTypes = {
  cancelShadowCaregiverEditing: func,
  caregiverName: string,
  caregiverId: string,
  editingShadowCaregiver: bool,
  enableToEditShadowCaregiver: bool,
  onEditShadowCaregiver: func,
  unMarkingShadowCaregiver: bool,
  onUnmarkShadowCaregiver: func,
};
