import { Box, Typography } from '@mui/material';
import React from 'react';
import TeamTableActions from './components/TableActions';

export const DEFAULT_SORTING_MODEL = { field: 'order', sort: 'asc' };
export const TEAM_LOCAL_STORAGE_KEYS = {
  territoryID: 'TERRITORY_ID',
};
export const teamColumns = [
  {
    field: 'order',
    headerName: 'Order',
    minWidth: 250,
    renderCell: ({ row }) => (
      <Typography
        sx={{
          display: 'flex',
        }}
      >
        {row.order}
      </Typography>
    ),
  },
  {
    field: 'territory',
    headerName: 'Territory',
    minWidth: 300,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 0',
        }}
      >
        {row?.territoryName}
      </Box>
    ),
  },
  {
    field: 'teamName',
    headerName: 'Team Name',
    minWidth: 300,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 0',
        }}
      >
        {row.name}
      </Box>
    ),
  },
  {
    field: 'teamColor',
    headerName: 'Team Color',
    minWidth: 300,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <TeamTableActions row={row} />,
  },
];
