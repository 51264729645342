import { Box, Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetCaregiverInfoQuery } from '../../../../api/Caregivers/api';
import {
  useUpdateTimeOffRequestMutation,
  useDeleteTimeOffRequestMutation,
  useGetCaregiverTimeOffRequestsQuery,
  useCreateTimeOffRequestMutation,
} from '../../../../api/TimeOffRequest/api';
import { TimeOffRequestsContext } from '../../../../pages/CaregiverDetails/context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { getNoData } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import CustomTable from '../../../../components/Table';
import CreatePtoAndCallouts from './components/CreatePtoAndCallouts';
import PtoHistory from './components/PtoHistory';
import ViewActions from './components/ViewActions';
import { columns, timeOffRequestTypes } from './constants';
import { getTimeOffRequestsTableStyles } from './styles';
import { useGetConfigQuery } from '../../../../api/Config/api';
import OptimizerCheck from '../../../../components/Optimizer/OptimizerCheck';

function PtoAndCallouts() {
  const { setAlert } = useContext(AlertContext);
  const apiRef = useGridApiRef();
  const { id } = useParams();
  const { tabSubView } = useParams();
  const { data: userData, error: getUserDataError } =
    useGetCaregiverInfoQuery(id);
  const [openAddRequestDialogType, setOpenAddRequestDialogType] =
    useState(null);
  const [checkData, setCheckData] = useState(null);
  const [openCheckOptomizer, setOpenCheckOptomizer] = useState(false);

  const creatingRequestInitialData = null;

  const { data: timeOffRequestsTypes, error: getTimeOffRequestsTypesError } =
    useGetConfigQuery(["ptoTypes"]);

  const [timeOffRequests, setTimeOffRequests] = useState([]);
  const {
    data: timeOffRequestsRaw,
    isFetching,
    error: getCaregiverTimeOffRequestsError,
    refetch: refetchTimeOffRequests,
  } = useGetCaregiverTimeOffRequestsQuery(id);

  useEffect(() => {
    if (timeOffRequestsRaw) {
      setTimeOffRequests(timeOffRequestsRaw);
    }
  }, [timeOffRequestsRaw]);

  const [
    createTimeOffRequest,
    { 
      createTimeOffRequestError,
      isLoading: isCreatingTimeOffRequest,
    }
  ] =
  useCreateTimeOffRequestMutation();

  const [
    deleteTimeOffRequest,
    {
      error: deleteTimeOffRequestError,
    } 
  ] = useDeleteTimeOffRequestMutation();

  const [
    updateTimeOffRequest,
    {
      error: updateTimeOffRequestError
    } 
  ] = useUpdateTimeOffRequestMutation();

  useEffect(() => {
    if (!isCreatingTimeOffRequest) {
      refetchTimeOffRequests();
    }
  }, [isCreatingTimeOffRequest, refetchTimeOffRequests]);

  useEffect(() => {
    if (
      getCaregiverTimeOffRequestsError ||
      getTimeOffRequestsTypesError ||
      updateTimeOffRequestError ||
      deleteTimeOffRequestError ||
      createTimeOffRequestError ||
      getUserDataError
    ) {
      const errorData =
        getTimeOffRequestsTypesError ||
        getCaregiverTimeOffRequestsError ||
        updateTimeOffRequestError ||
        deleteTimeOffRequestError ||
        createTimeOffRequestError ||
        getUserDataError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    getCaregiverTimeOffRequestsError,
    getTimeOffRequestsTypesError,
    getUserDataError,
    setAlert,
    updateTimeOffRequestError,
    deleteTimeOffRequestError,
    createTimeOffRequestError
  ]);
  const closeAddRequestDialog = useCallback(() => {
    setOpenAddRequestDialogType(null);
  }, [setOpenAddRequestDialogType]);

  const showCheckPossibilityView = useCallback(
    () => setOpenAddRequestDialogType(timeOffRequestTypes.checkPossibility),
    [setOpenAddRequestDialogType],
  );

  const startCheckOPtimizer = (ptoData) => {
    setCheckData(ptoData);
    setOpenCheckOptomizer(true);
  };

  const cancelPendingRequest = (ptoData) => {
    deleteTimeOffRequest(ptoData);
  };

  const confirmRequestStatusChanges = async (ptoData) => {
    if (ptoData?.id) {
      updateTimeOffRequest(ptoData);
    } else {
      createTimeOffRequest(ptoData);
    }
  };

  const memoizedProviderValue = useMemo(
    () => ({
      cancelPendingRequest,
      closeAddRequestDialog,
      creatingRequestInitialData,
      openAddRequestDialogType,
      showCheckPossibilityView,
      timeOffRequests,
      timeOffRequestsTypes,
      startCheckOPtimizer
    }),
        // eslint-disable-next-line
    [
      closeAddRequestDialog,
      creatingRequestInitialData,
      openAddRequestDialogType,
      showCheckPossibilityView,
      timeOffRequests,
      timeOffRequestsTypes
    ],
  );

  return (
    <TimeOffRequestsContext.Provider value={memoizedProviderValue}>
      <Stack sx={{ maxWidth: '100%' }}>
        {!!openAddRequestDialogType && (
          <CreatePtoAndCallouts caregiverData={{ ...userData, id }} setCheckData={setCheckData} setOpenCheckOptomizer={setOpenCheckOptomizer}/>
        )}
        <OptimizerCheck
          data={[checkData]}
          dataObjName="ptoObj"
          submitFunction={confirmRequestStatusChanges}
          open={openCheckOptomizer}
          openToggle={setOpenCheckOptomizer}
        />
        <ViewActions
          addRequest={() =>
            setOpenAddRequestDialogType(timeOffRequestTypes.settingParams)
          }
        />
        <Box>
          {tabSubView === 'current' ? (
            <CustomTable
              apiRef={apiRef}
              columns={columns}
              customStyles={getTimeOffRequestsTableStyles(
                !timeOffRequests?.length,
              )}
              headerHeight={34}
              isLoading={isFetching}
              noRowsOverlay={() =>
                getNoData({ title: 'No PTO or Callouts found' })
              }
              onRowClick={() => {}}
              rows={timeOffRequests || []}
              withoutPagination
            />
          ) : (
            <PtoHistory />
          )}
        </Box>
      </Stack>
    </TimeOffRequestsContext.Provider>
  );
}

export default PtoAndCallouts;
