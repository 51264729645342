import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { getAvailabilityColumns } from '../../../../../../pages/CaregiverDetails/view/Availability/constants';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import CustomTable from '../../../../../../components/Table';

function AvailabilityTable({
  availabilityData = {},
  isLoading = true,
  noRowsOverlay = () => {},
  selectedDate = {},
}) {
  const apiRef = useGridApiRef();
  const [checkedRows, setCheckedRows] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    if (availabilityData) {
      setCheckedRows(
        availabilityData
          ?.filter((row) => row.startTime !== null)
          ?.map((row) => row.id),
      );
    }
  }, [availabilityData]);

  useEffect(() => {
    if (availabilityData.length && selectedDate) {
      const rows = availabilityData?.filter((row) => row.effectiveStartDate === selectedDate.rawDate);
      setTableRows(rows);
    }
    }, [availabilityData, selectedDate]);


  const caregiverWithoutCurrentAvailability =
    availabilityData.length === 1 &&
    availabilityData?.[0].days?.every((t) => t.startTime === null);

  const columns = useMemo(
    () =>
      getAvailabilityColumns({
        caregiverWithoutCurrentAvailability,
        checkedRows,
      }),
    [
      caregiverWithoutCurrentAvailability,
      checkedRows,
    ],
  );
 
  return (
    <Box>
      <CustomTable
        apiRef={apiRef}
        columns={columns}
        headerHeight={44}
        noRowsOverlay={noRowsOverlay}
        isLoading={isLoading}
        rowHeight={80}
        rows={tableRows}
        withoutPagination
      />
    </Box>
  );
}

AvailabilityTable.propTypes = {
  availabilityData: instanceOf(Object),
  isLoading: bool,
  noRowsOverlay: func,
  availabilityScheduleView: string,
  selectedDate: instanceOf(Object),
};
export default AvailabilityTable;
