import { Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import arrivalWhiteIcon from '../../../../../img/icons/arrival-window-visit-white.svg';
import arrivalIcon from '../../../../../img/icons/arrival-window-visit.svg';
import preferredCaregiverIcon from '../../../../../img/icons/preferred-icon.svg';
import primaryCaregiverIcon from '../../../../../img/icons/primary-visit-icon.svg';
import moment from 'moment/moment';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { visitStatusesMap } from '../../../../../shared/constants';
import { COLORS } from '../../../../../shared/styles/Theme';
import { getStatusComponent } from '../../../../../shared/utils/common';
import CustomTooltip from '../../../../../components/CustomTooltip';
import { infoTitleWrapper } from './style';

export default function EventCardInfo({
  checkIfShowArrival = false,
  eventData = {},
  visitWithArrivalWindow = false,
}) {
  const isOnSiteStatus = eventData.status === visitStatusesMap.onSite;
  const isVerified = eventData.status === visitStatusesMap.verified;
  return eventData.caregiverId ? (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={infoTitleWrapper}>
          <Link
            component={RouterLink}
            to={`/caregivers/${eventData.caregiverId}`}
            underline="none"
          >
            <Typography
              variant="body1"
              sx={{
                color:
                  isOnSiteStatus || isVerified ? '#ffffff' : COLORS.blue[700],
                fontWeight: '600',
                fontSize: '14px',
              }}
            >
              {eventData.caregiverName}
            </Typography>
          </Link>
          {eventData?.preferredCaregiver &&
            eventData?.caregiver?.id === eventData?.preferredCaregiver?.id && (
              <CustomTooltip title="Preferred Caregiver">
                <img src={preferredCaregiverIcon} alt="arrivalIcon" />
              </CustomTooltip>
            )}
          {eventData?.shadowCaregiver && (
            <CustomTooltip title="Primary Caregiver">
              <img src={primaryCaregiverIcon} alt="arrivalIcon" />
            </CustomTooltip>
          )}
        </Box>
      </Box>
      {!visitWithArrivalWindow && checkIfShowArrival && (
        <Box sx={{ display: 'flex' }}>
          <img
            src={isOnSiteStatus ? arrivalWhiteIcon : arrivalIcon}
            alt="arrivalIcon"
          />
          <Typography
            sx={{
              ml: '6px',
              color: isOnSiteStatus ? '#ffffff' : COLORS.blue[700],
            }}
            variant="body2"
          >
            {moment(eventData.arrivalTimeStart).format('h:mm A')} -
            {moment(eventData.arrivalTimeEnds).format('h:mm A')}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          marginTop: '4px',
        }}
      >
        {getStatusComponent(eventData.status)}
      </Box>
    </Box>
  ) : (
    <Typography
      variant="body1"
      sx={{ color: COLORS.blue[900], fontWeight: 600 }}
    >
      Not allocated
    </Typography>
  );
}

EventCardInfo.propTypes = {
  checkIfShowArrival: bool,
  eventData: instanceOf(Object),
  visitWithArrivalWindow: bool,
};
