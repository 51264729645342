import { Box, Button, Typography } from '@mui/material';
import moment from 'moment/moment';
import AvailabilityBreadcrumbs from '../../../../../pages/CaregiverDetails/view/Availability/components/Breadcrum';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { SHORT_DATE_FORMAT, H12_TIME_FORMAT, H24_TIME_FORMAT } from '../../../../../shared/constants';
import CustomDialog from '../../../../../components/Dialog';
import { editAvailabilityHeaderWrapper } from '../styles';
import MultiplyModeContent from './MultiplyModeContent';

export default function EditAvailabilityHeader({
  closeDialog = () => {},
  disabledDays = {},
  disabledMultipleMode = false,
  isLoading = false,
  resetToInitialView = () => {},
  setShowMultiplyMode = () => {},
  showMultiplyMode = false,
  startDate = '',
  submitAvailability = () => {},
  weekdays = [],
}) {
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [error, setError] = useState('');
  const [selectedDays, setSelectedDays] = useState([]);

  const submitSchedule = () => {
    const parsedWeekdays = [];
    let id = 0;
    for (let day in selectedDays) {
      parsedWeekdays.push({
        day: selectedDays[day],
        startTime: moment(startTime, H12_TIME_FORMAT).format(H24_TIME_FORMAT),
        endTime: moment(endTime, H12_TIME_FORMAT).format(H24_TIME_FORMAT),
        effectiveStartDate: startDate,
        id: String(id),
      });
      id++;
    }
    submitAvailability(parsedWeekdays);
    setShowMultiplyMode(false);
    setStartTime('');
    setEndTime('');
    setSelectedDays([]);
  };

  const disabledSubmit = useMemo(
    () =>
      !startTime.length ||
      !endTime.length ||
      !!error.length ||
      !selectedDays.length,
    [endTime, error, selectedDays, startTime],
  );
  return (
    <Box sx={editAvailabilityHeaderWrapper}>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setShowMultiplyMode(false)}
        customStyles={{
          '& .MuiDialogTitle-root h4.MuiTypography-root': {
            fontSize: '15px',
            fontWeight: 400,
          },
        }}
        disabledSubmit={disabledSubmit}
        submitButtonName="apply"
        submitCallback={submitSchedule}
        title="Please select the Availability Start / End Time and Weekdays."
        openDialog={showMultiplyMode}
      >
        <MultiplyModeContent
          endTime={endTime}
          error={error}
          selectedDays={selectedDays}
          setEndTime={setEndTime}
          setError={setError}
          setSelectedDays={setSelectedDays}
          setStartTime={setStartTime}
          startTime={startTime}
          weekdays={weekdays}
          disabledDays={disabledDays}
        />
      </CustomDialog>
      <AvailabilityBreadcrumbs
        closeDialog={closeDialog}
        resetToInitialView={resetToInitialView}
        isLoading={isLoading}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <Typography>
          Effective Date: {moment(startDate).format(SHORT_DATE_FORMAT)}
        </Typography>
        <Button
          color="secondary"
          disabled={disabledMultipleMode}
          variant="outlined"
          onClick={() => setShowMultiplyMode(true)}
          disableElevation
        >
          Multiple mode
        </Button>
      </Box>
    </Box>
  );
}

EditAvailabilityHeader.propTypes = {
  closeDialog: func,
  disabledDays: instanceOf(Object),
  disabledMultipleMode: bool,
  isLoading: bool,
  resetToInitialView: func,
  setShowMultiplyMode: func,
  showMultiplyMode: bool,
  startDate: string,
  submitAvailability: func,
  weekdays: instanceOf(Array),
};
