import { Box, Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetUsersQuery } from '../../../../api/Administration/api';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  SEARCH_INPUT_DEBOUNCE_DELAY,
  viewTypeButtons,
} from '../../../../shared/constants';
import { useDebouncedCallback } from '../../../../shared/hooks/useDebounce';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import CustomTable from '../../../../components/Table';
import AddUserHeader from './components/AddUserHeader';
import UsersHistory from './components/UsersHistory';
import {
  DEFAULT_SORTING_MODEL,
  userListColumns,
} from './constants';

export default function AdministrationUsers() {
  const { tabSubView } = useParams();
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const apiRef = useGridApiRef();
  const { data, isLoading, error } = useGetUsersQuery();
  const [sortOption, setSortOption] = useState(DEFAULT_SORTING_MODEL);
  const [userEmail, setUserEmail] = useState();
  const [radioValue, setRadioValue] = useState();
  const [clientsFilters, setClientsFilters] = useState({ user: '' });

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [setAlert, error]);
  const debouncedSearch = useDebouncedCallback((e) => {
    setClientsFilters({ [e.target.name]: e.target.value });
  }, SEARCH_INPUT_DEBOUNCE_DELAY);
  const filteredList = data?.filter((user) => {
    const userName = user?.email?.toLowerCase();
    const filterValue = clientsFilters?.user?.toLowerCase();
    return userName.includes(filterValue);
  });
  const updateSorting = useCallback((item) => setSortOption(item[0]), []);

  const changeSubView = (value) => navigate(`/administration/users/${value}`);
  return (
    <Stack>
      {tabSubView === 'current' ? (
        <Box>
          <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
            <ButtonsGroup
              buttons={viewTypeButtons}
              changeSelected={(value) => {
                changeSubView(value);
                setClientsFilters({ user: '' });
              }}
              selected={tabSubView}
            />

            <AddUserHeader
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              radioValue={radioValue}
              setRadioValue={setRadioValue}
              changeValue={debouncedSearch}
            />
          </Box>
          <CustomTable
            columns={userListColumns}
            rows={filteredList}
            apiRef={apiRef}
            headerHeight={40}
            isLoading={isLoading}
            rowHeight={50}
            pageSize={PAGE_SIZE}
            setSortModelState={updateSorting}
            sortModel={[sortOption]}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
          />
        </Box>
      ) : (
        <Box>
          <ButtonsGroup
            buttons={viewTypeButtons}
            changeSelected={changeSubView}
            selected={tabSubView}
          />
          <UsersHistory />
        </Box>
      )}
    </Stack>
  );
}
