import errorIcon from '../../../../../../img/icons/error-outlined.svg';
import successIcon from '../../../../../../img/icons/success-outlined.svg';
import React from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';

export const timeOffRequestInitialState = {
  caregiverId: "",
  from: "",
  to: "",
  isCallout: false,
  allDay: false,
  status: "Approved"
};

export const addTimeOffRequestRequiredFields = {
  period: [
    'requestDateEnd',
    'requestDateStart',
    'requestTimeEnd',
    'requestTimeStart',
    'ptoType',
  ],
  allDay: ['requestDateEnd', 'requestDateStart', 'ptoType'],
};

export const timeOffRequestsTypes = [
  {
    id: 'bereavement',
    name: 'Bereavement',
  },
  {
    id: 'other',
    name: 'Other',
  },
  {
    id: 'personal',
    name: 'Personal',
  },
  {
    id: 'vacation',
    name: 'Vacation',
  },
];

export const timeOffRequestPossibilityAlert = {
  success: {
    bgColor: COLORS.green[200],
    color: COLORS.green[600],
    icon: <img src={successIcon} alt="success icon" />,
    getTitle() {
      return 'Reassignment is possible for all days';
    },
    type: 'success',
  },
  error: {
    bgColor: COLORS.red[200],
    color: COLORS.red[600],
    icon: <img src={errorIcon} alt="error icon" />,
    getTitle(number) {
      return `Reassignment impossible for ${number}`;
    },
    type: 'error',
  },
};

export const possibilityLocalData = {
  requestId: '495b45f7-a6b0-4cc6-ac8e-b0613329ac8e',
  rescheduledEvents: {
    '2023-10-12': [
      {
        arrivalStart: '10:00 AM',
        arrivalEnd: '11:00 AM',
        client: {
          id: 'a4ca6adf-62af-4577-85df-eaa3b78adf0a',
          firstName: 'Lisa',
          lastName: 'Simpson',
        },
        duration: 45,
        caregiver: {
          id: 'fe99c31c-036e-4055-bef3-a3d4867ba59a',
          firstName: 'Tony',
          lastName: 'Stark',
        },
        visitType: 'Essential Care',
        status: {
          status: 'success',
          message: 'Reassignment Possible',
          reasons: [],
        },
        scheduledTime: {
          start: '10:15 AM',
          end: '11:00 AM',
        },
      },
      {
        arrivalStart: '10:15 AM',
        arrivalEnd: '10:45 AM',
        client: {
          id: 'f2cb7284-8b7a-416b-bc3d-e540cc15f6b3',
          firstName: 'Dewey',
          lastName: 'Largo',
        },
        duration: 45,
        caregiver: {
          id: '776f9abc-35d6-4727-a01b-5fdbd9ff2508',
          firstName: 'Igor',
          lastName: 'Polchshikov',
        },
        visitType: 'Essential Care',
        status: {
          status: 'success',
          message: 'Reassignment Possible',
          reasons: [],
        },
        scheduledTime: {
          start: '10:15 AM',
          end: '11:00 AM',
        },
      },
      {
        arrivalStart: '11:00 AM',
        arrivalEnd: '12:00 PM',
        client: {
          id: 'ce78ff33-a145-4db1-bf5b-74cf7a40a9da',
          firstName: 'Edna',
          lastName: 'Krabappel',
        },
        duration: 45,
        caregiver: {
          id: '511d1a4c-2edf-4f68-88b6-fffcb428546e',
          firstName: 'Darth',
          lastName: 'Nihilus',
        },
        visitType: 'Essential Care',
        status: {
          status: 'success',
          message: 'Reassignment Possible',
          reasons: [],
        },
        scheduledTime: {
          start: '11:00 AM',
          end: '11:45 AM',
        },
      },
      {
        arrivalStart: '11:30 AM',
        arrivalEnd: '11:30 AM',
        client: {
          id: 'a4ca6adf-62af-4577-85df-eaa3b78adf0a',
          firstName: 'Lisa',
          lastName: 'Simpson',
        },
        duration: 25,
        caregiver: {
          id: 'fe99c31c-036e-4055-bef3-a3d4867ba59a',
          firstName: 'Tony',
          lastName: 'Stark',
        },
        visitType: 'Quick Assist',
        status: {
          status: 'success',
          message: 'Reassignment Possible',
          reasons: [],
        },
        scheduledTime: {
          start: '11:30 AM',
          end: '11:55 AM',
        },
      },
    ],
    '2023-10-13': [
      {
        arrivalStart: '4:00 PM',
        arrivalEnd: '4:30 PM',
        client: {
          id: 'fd349957-b8b4-4af9-9673-703b06340b35',
          firstName: 'Apu',
          lastName: 'Nahasapeemapetilon',
        },
        duration: 90,
        caregiver: null,
        visitType: 'Full Service',
        status: {
          status: 'error',
          message: 'Reassignment Impossible',
          reasons: [],
        },
        scheduledTime: {
          start: '4:00 PM',
          end: '5:30 PM',
        },
      },
    ],
    '2023-10-14': [],
  },
};
