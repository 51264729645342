import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import {
  useCreateHolidayMutation,
  useGetHolidaysQuery,
} from '../../../../../../api/Administration/api';
import moment from 'moment';
import AddHolidayModalContent from '../../../../../../pages/Administration/views/AdministrationHolidays/components/AddHolidayModal';
import React, { useContext, useEffect, useState } from 'react';
import { ISO_DATE_ONLY_FORMAT } from '../../../../../../shared/constants';
import { COLORS } from '../../../../../../shared/styles/Theme';
import { isValidText } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomDialog from '../../../../../../components/Dialog';

export default function HolidayHeader() {
  const { setAlert } = useContext(AlertContext);
  const [date, setDate] = useState(moment().add(1, 'day'));
  const [holidayName, setHolidayName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [holidaysData, { error: updateHolidaysError, data }] =
    useCreateHolidayMutation();

  const { data: holidays, error: getHolidaysError } = useGetHolidaysQuery();
  const shouldDisableDate = (selectedDate) => {
    const disabledDates = holidays?.map((holiday) => holiday.holidayDate);
    return disabledDates?.includes(
      moment(selectedDate).format(ISO_DATE_ONLY_FORMAT),
    );
  };
  const [, setOpenDeleteVisitDialog] = useState();
  useEffect(() => {
    if (getHolidaysError || updateHolidaysError) {
      const errorData =
        getHolidaysError || updateHolidaysError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getHolidaysError, updateHolidaysError, setAlert]);
  useEffect(() => {
    setOpenDeleteVisitDialog(data && !data?.holidayCreated);
  }, [data]);
  const createHoliday = () => {
    holidaysData({
      name: holidayName,
      date: date.format(ISO_DATE_ONLY_FORMAT),
    });
    setOpenDialog(false);
    if (data && !data?.holidayCreated) {
      setHolidayName('');
      setDate(moment());
    }
  };
  const cancelModal = () => {
    setHolidayName('');
    setDate(moment().add(1, 'day'));
    setOpenDialog(false);
  };
  return (
    <Box>
      <Button
        color="secondary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setOpenDialog(true)}
        disableElevation
        sx={{
          '&.MuiButton-root.Mui-disabled': {
            backgroundColor: 'transparent',
            color: COLORS.blue[500],
            opacity: 0.7,
          },
        }}
      >
        Holiday
      </Button>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={cancelModal}
        submitButtonName="Save"
        disabledSubmit={
          holidayName?.trim()?.length === 0 || !isValidText(holidayName)
        }
        submitCallback={createHoliday}
        title="Add Holiday"
        openDialog={openDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 20px 13px' }}>
          <AddHolidayModalContent
            date={date}
            selectDate={setDate}
            holidayName={holidayName}
            setHolidayName={setHolidayName}
            shouldDisableDate={shouldDisableDate}
          />
        </Box>
      </CustomDialog>
    </Box>
  );
}
