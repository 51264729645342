import { Box } from '@mui/material';
import CrossedOutCell from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/CrossedOutCell';
import CareProgramTableDaysSchedule from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramTableDaysSchedule';
import { instanceOf } from 'prop-types';
import React from 'react';

export default function VisitDaySchedule({ row = {} }) {
  const scheduleHasBeenChanged = false;
  return row.parentId && scheduleHasBeenChanged ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
      }}
    >
      <CrossedOutCell>
        <CareProgramTableDaysSchedule
          selectedDays={row?.days}
          transparent
        />
      </CrossedOutCell>
      <CareProgramTableDaysSchedule selectedDays={row?.days} />
    </Box>
  ) : (
    <CareProgramTableDaysSchedule selectedDays={row?.days}/>
  );
}

VisitDaySchedule.propTypes = {
  row: instanceOf(Object),
};
