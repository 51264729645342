import { Box, Link, Typography } from '@mui/material';
import preferredCaregiverIcon from '../../../../../img/icons/preferred-icon.svg';
import primaryCaregiverIcon from '../../../../../img/icons/primary-visit-icon.svg';
import shadowCaregiverIcon from '../../../../../img/icons/shadow-visit-black-icon.svg';
import { instanceOf } from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CustomTooltip from '../../../../../components/CustomTooltip';
import {
  caregiverNameStyles,
  visitCaregiverInfo,
  visitCaregiversWrapper,
} from './styles';

export default function VisitCaregiverInfo({ visitData = {} }) {
  const showPrimaryVisitIcon =
    visitData?.shadowCaregiver &&
    visitData?.caregiver &&
    !visitData?.isShadowVisit;

  return (
    <Box sx={visitCaregiversWrapper}>
      <Box sx={visitCaregiverInfo}>
        {showPrimaryVisitIcon && (
          <CustomTooltip title="Primary Caregiver">
            <img src={primaryCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
        )}
        {visitData?.caregiverId ? (
          <Link
            component={RouterLink}
            to={`/caregivers/${visitData?.caregiverId}`}
            underline="none"
          >
            <Typography variant="body1" sx={caregiverNameStyles}>
              {`${visitData?.caregiverName}`}
            </Typography>
          </Link>
        ) : (
          <Typography>-</Typography>
        )}
        {visitData?.caregiverId &&
          visitData?.preferredCaregiverId &&
          visitData?.caregiverIdid === visitData?.preferredCaregiverId && (
            <CustomTooltip title="Primary Caregiver">
              <img src={preferredCaregiverIcon} alt="arrivalIcon" />
            </CustomTooltip>
          )}
      </Box>
      {visitData?.shadowCaregiverId && (
        <Box sx={visitCaregiverInfo}>
          <CustomTooltip title="Shadow visit">
            <img src={shadowCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
          {visitData?.caregiverId ? (
            <Link
              component={RouterLink}
              to={`/caregivers/${visitData?.shadowCaregiverId}`}
              underline="none"
            >
              <Typography variant="body1" sx={caregiverNameStyles}>
                {`${visitData?.shadowCaregiverName}}`}
              </Typography>
            </Link>
          ) : (
            <Typography>-</Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

VisitCaregiverInfo.propTypes = {
  visitData: instanceOf(Object),
};
