import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import {
  useGetClientTasksListQuery,
  useCreateCarePlanTasksMutation,
  useUpdateCarePlanTasksMutation,
  useDeleteCarePlanTaskMutation,
} from '../../../../api/CarePlan/api';
import { useGetConfigQuery } from '../../../../api/Config/api';
import AddTaskProvider from '../../../../pages/ClientDetails/components/CreateTask';
import { CarePlanContext } from '../../../../pages/ClientDetails/context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { DEFAULT_SORTING_MODEL } from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import Loader from '../../../../components/Loader';
import CustomTable from '../../../../components/Table';
import CarePlanHistory from './components/CarePlanHistory';
import FiltersWrapper from './components/Filters';
import { Header } from './components/Header';
import { INITIAL_CARE_PLAN_FILTERS, columns } from './constants';
import { CarePlanTableStyles } from './styles';

function CarePlan() {
  const { id, tabSubView } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [sortOption, setSortOption] = useState(DEFAULT_SORTING_MODEL);
  const [carePlanFilters, setCarePlanFilters] = useState(INITIAL_CARE_PLAN_FILTERS);

  const {
    data: carePlanTasks,
    error,
    isLoading,
  } = useGetClientTasksListQuery(
    { clientId: id },
    { refetchOnMountOrArgChange: true },
  );
  
  const [createCarePlanTasks, { isLoading: isCreateTasksLoading }] =
    useCreateCarePlanTasksMutation();
  const [updateCarePlanTasks, { error: updateCarePlanTasksError }] =
    useUpdateCarePlanTasksMutation();
  const [deleteCarePlanTask, { error: deleteCarePlanTaskError }] =
    useDeleteCarePlanTaskMutation();
  const apiRef = useGridApiRef();

  const {
    data: configData,
    isLoading: isLoadingConfigs,
    error: onMountOrArgChangeError,
  } = useGetConfigQuery(["categoryTypes", "levelsOfAssistance", "taskTypes", "timeOfDays", "visitTypes"], {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (onMountOrArgChangeError || error) {
      const errorData = onMountOrArgChangeError || error;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [onMountOrArgChangeError, error, setAlert]);

  useEffect(() => {
    if (updateCarePlanTasksError) {
      const errorData = updateCarePlanTasksError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [setAlert, updateCarePlanTasksError]);

  useEffect(() => {
    if (deleteCarePlanTaskError) {
      const errorData = deleteCarePlanTaskError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [setAlert, deleteCarePlanTaskError]);

  const filteredTasks = useMemo(() => {
    if (!carePlanTasks || !Array.isArray(carePlanTasks)) {
      return [];
    }
    return carePlanTasks.filter((task) => {
      const matchesTask = task.name.toLowerCase().includes(carePlanFilters.task.toLowerCase());
      const matchesLevelOfAssistance = carePlanFilters.levelsOfAssistance.length
        ? carePlanFilters.levelsOfAssistance.map((level) => level.id).includes(task.levelOfAssistance)
        : true;
      const matchesCategory = carePlanFilters.categoryTypes.length
        ? carePlanFilters.categoryTypes.map((cat) => cat.id).includes(task.categoryTypes)
        : true;
      const matchesDays = carePlanFilters.days.length
        ? carePlanFilters.days.map((thisDay) => thisDay.id).some(day => task.days.includes(day))
        : true;
      const matchesTimeOfDay = carePlanFilters.timeOfDays.length
        ? carePlanFilters.timeOfDays.map((timeOfDay) => timeOfDay.id).some(time => task.timeOfDays.includes(time))
        : true;
      const matchesVisitType = carePlanFilters.visitTypes.length
        ? carePlanFilters.visitTypes.map((type) => type.id).some(visitType => task.visitTypes.includes(visitType))
        : true;
  
      return (
        matchesTask &&
        matchesLevelOfAssistance &&
        matchesCategory &&
        matchesDays &&
        matchesTimeOfDay &&
        matchesVisitType
      );
    });
  }, [carePlanTasks, carePlanFilters]);

  const getRowId = useCallback(
    (row) => `${row.name} ${row.levelOfAssistance}`,
    [],
  );

  const memoizedProviderValue = useMemo(
    () => ({
      apiRef,
      carePlanTasks,
      isLoading,
      setSortOption,
      sortOption,
      createCarePlanTasks,
      deleteCarePlanTask,
      configData,
    }),
    [apiRef, carePlanTasks, isLoading, sortOption, createCarePlanTasks, configData, deleteCarePlanTask],
  );

  const updateSorting = useCallback((data) => setSortOption(data[0]), []);

  if (isLoadingConfigs) {
    return <Loader />;
  }

  return (
    <CarePlanContext.Provider value={memoizedProviderValue}>
      <AddTaskProvider
        sortOption={sortOption}
        carePlanFilters={carePlanFilters}
        tasksListData={filteredTasks}
        createTasksList={createCarePlanTasks}
        updateCarePlanTasks={updateCarePlanTasks}
        configData={configData}
        isLoadingConfigs={isLoadingConfigs}
      >
        {isLoading || (isCreateTasksLoading && <Loader />)}
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Header />
          {tabSubView === 'current' ? (
            <FiltersWrapper
              carePlanFilters={carePlanFilters}
              isLoading={isLoading}
              setCarePlanFilters={setCarePlanFilters}
              configData={configData}
            />
          ) : null}
          {tabSubView === 'current' ? (
            <CustomTable
              apiRef={apiRef}
              columns={columns}
              customStyles={CarePlanTableStyles}
              headerHeight={44}
              getRowId={getRowId}
              isLoading={isLoading}
              onRowClick={() => {}}
              rows={filteredTasks}
              setSortModelState={updateSorting}
              sortModel={[sortOption]}
              withoutPagination
            />
          ) : (
            <CarePlanHistory />
          )}
        </Box>
      </AddTaskProvider>
    </CarePlanContext.Provider>
  );
}

export default React.memo(CarePlan);
