import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import {
  useCreateTeamByIdMutation,
  useGetTerritoryQuery,
} from '../../../../../../api/Administration/api';
import { func, number, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { isValidText } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomDialog from '../../../../../../components/Dialog';
import SearchFilter from '../../../../../../components/FiltersComponents/Search';
import CustomSelect from '../../../../../../components/Select';
import { TEAM_LOCAL_STORAGE_KEYS } from '../../constants';
import AddTeamModal from '../AddTeamModal';
import { territorySelectStyles } from '../AddTeamModal/styles';
import { addModalBoxStyles, addTeamButtonStyles } from './styles';

export default function AddTeamModalHeader({
  // data = [],
  debouncedSearch = () => {},
  order = 0,
  setOrder = () => {},
  setTeamName = () => {},
  teamName = '',
  chosenTerritoryID = '',
  setChosenTerritoryID = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [color, setColor] = useState('#FFFFFF');
  const [fontColor, setFontColor] = useState('Black');
  const [createTeamById, { error }] = useCreateTeamByIdMutation();
  const { data: territoryList, error: getTerritoryListError } =
    useGetTerritoryQuery();

  useEffect(() => {
    if (territoryList && Array.isArray(territoryList)) {
      const sortedTerritory = [...territoryList].sort(
        (a, b) => a.order - b.order,
      );
      const territoryIDInitialValue = localStorage.getItem(
        TEAM_LOCAL_STORAGE_KEYS.territoryID,
      )
        ? JSON.parse(
            localStorage.getItem(TEAM_LOCAL_STORAGE_KEYS.territoryID),
          )
        : sortedTerritory?.[0]?.id;

      setChosenTerritoryID(territoryIDInitialValue);
    }
  }, [setChosenTerritoryID, territoryList]);

  const onSelectTerritory = (params) => {
    const { id } = params

    setChosenTerritoryID(id)

    localStorage.setItem(
      TEAM_LOCAL_STORAGE_KEYS.territoryID,
      JSON.stringify(id),
    );
  };
  
  useEffect(() => {
    const errorData = error || getTerritoryListError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [error, getTerritoryListError, setAlert]);
  const createTeam = () => {
    createTeamById({
      name: teamName,
      order,
      territory: chosenTerritoryID,
      color: {
        color,
        fontColor,
      },
      type: "team"
    });
    setOpenDialog(false);
    setTeamName('');
  };
  const cancelModal = () => {
    setTeamName('');
    setOpenDialog(false);
    setOrder(1);
    setChosenTerritoryID(chosenTerritoryID);
    setColor('#ffffff');
  };
  return (
    <Box
      sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
    >
      <Box sx={addModalBoxStyles}>
        <CustomSelect
          addStyles={territorySelectStyles}
          id="territory1"
          isLoading={false}
          isMultiselect={false}
          options={territoryList?.map((ter) => ({ id: ter.id, name: ter.name }))} 
          placeHolder="Territory"
          selectedValue={chosenTerritoryID}
          setValue={onSelectTerritory}
        />
        <SearchFilter
          changeValue={debouncedSearch}
          name="teamName"
          placeholder="Team Name"
        />
      </Box>
      <Button
        color="secondary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setOpenDialog(true)}
        disableElevation
        sx={addTeamButtonStyles}
      >
        TEAM
      </Button>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={cancelModal}
        submitButtonName="Save"
        disabledSubmit={
          teamName?.trim()?.length === 0 || !isValidText(teamName)
        }
        submitCallback={createTeam}
        title="Add Team"
        openDialog={openDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 20px 13px' }}>
          <AddTeamModal
            teamName={teamName}
            setTeamName={setTeamName}
            data={territoryList}
            order={order}
            setOrder={setOrder}
            color={color}
            fontColor={fontColor}
            setColor={setColor}
            setFontColor={setFontColor}
            territoryOptions={territoryList}
            chosenTerritoryID={chosenTerritoryID}
            setChosenTerritoryID={(setChosenTerritoryID)}
          />
        </Box>
      </CustomDialog>
    </Box>
  );
}

AddTeamModalHeader.propTypes = {
  // data: instanceOf(Array),
  debouncedSearch: func,
  order: number,
  setOrder: func,
  setTeamName: func,
  teamName: string,
  chosenTerritory: string,
  setChosenTerritoryID: func,
};
