import Administration from '../pages/Administration';
import CaregiverDetails from '../pages/CaregiverDetails';
import CaregiversView from '../pages/Caregivers';
import ClientDetails from '../pages/ClientDetails';
import ClientsView from '../pages/Clients';
import Reporting from '../pages/Reporting';
import SchedulingPage from '../pages/Scheduling';
import React from 'react';
import { Navigate } from 'react-router';

const DASHBOARD_NAVIGATION = [
  {
    path: 'clients',
    element: <ClientsView />,
    name: 'Clients',
    roles: ['Admin', 'User'],
  },
  {
    path: 'caregivers',
    element: <CaregiversView />,
    name: 'Caregivers',
    roles: ['Admin', 'User'],
  },
  {
    exact: true,
    path: 'scheduling',
    element: <Navigate replace to="calendar" />,
    name: 'Scheduling',
    roles: ['Admin', 'User'],
  },
  {
    path: 'scheduling/:tab',
    element: <SchedulingPage />,
    roles: ['Admin', 'User'],
  },
  {
    exact: true,
    path: 'administration',
    element: <Navigate replace to="scheduling-engine/current" />,
    name: 'Administration',
    roles: ['Admin'],
  },
  {
    path: 'administration/:tab/:tabSubView',
    element: <Administration />,
    roles: ['Admin'],
  },
  {
    exact: true,
    path: 'reporting',
    name: 'Reporting',
    element: <Navigate replace to="weekly-scheduling-report/not-allocated" />,
    roles: ['Admin', 'User'],
  },
  {
    path: 'reporting/:tab/:tabSubView',
    element: <Reporting />,
    roles: ['Admin', 'User'],
  },
  {
    exact: true,
    path: 'clients/:id',
    element: <Navigate replace to="profile/current" />,
    roles: ['Admin', 'User'],
  },
  {
    path: 'clients/:id/:tab',
    element: <ClientDetails />,
    roles: ['Admin', 'User'],
  },
  {
    path: 'clients/:id/:tab/:tabSubView',
    element: <ClientDetails />,
    roles: ['Admin', 'User'],
  },
  {
    exact: true,
    path: 'caregivers/:id',
    element: <Navigate replace to="profile/current" />,
    roles: ['ADMIN', 'User'],
  },
  {
    path: 'caregivers/:id/:tab',
    element: <CaregiverDetails />,
    roles: ['ADMIN', 'User'],
  },
  {
    path: 'caregivers/:id/:tab/:tabSubView',
    element: <CaregiverDetails />,
    roles: ['ADMIN', 'User'],
  },
  {
    path: '',
    element: <Navigate to="clients" replace />,
    roles: ['ADMIN'],
  },
];

export default DASHBOARD_NAVIGATION;
