import moment from 'moment';
import { DATE_ONLY_STANDARD_FORMAT } from '../../../../../../shared/constants';
import { filterVisitsByEffectiveDate } from '../../../../../../shared/utils/common';

export const parseVisits = (visits) =>
  filterVisitsByEffectiveDate(visits)
    ?.map((event) => {
      const { days, duration, visitTypeDetails } = event;
      const currentDate = moment();
      const weekStart = currentDate
        .clone()
        .startOf('week')
        .format(DATE_ONLY_STANDARD_FORMAT);
      const schedule = [];
      for (let day in days ){
        const arrivalDateStart = moment(`${weekStart} ${days[day].arrivalStart}`)
        .add(day, 'days')
        .toDate();
        const arrivalDateEnd = moment(`${weekStart} ${days[day].arrivalEnd}`)
          .add(day, 'days')
          .toDate();

        const eventEnd = moment(`${weekStart} ${days[day].arrivalEnd}`)
          .add(duration, 'minutes')
          .add(day, 'days')
          .toDate();

        schedule.push({
          ...event,
          title: `${visitTypeDetails.name} Visit`,
          type: visitTypeDetails.name?.toLowerCase(),
          arrivalTimeStart: arrivalDateStart,
          start: arrivalDateEnd,
          end: eventEnd,
        });
      }
      return schedule;
    })
    .flat();

export const getMinHour = (parsedEventsData) => {
  const minHours = parsedEventsData
    ?.map((item) => item.arrivalTimeStart.getHours())
    ?.filter((hour) => hour);
  return Math.min(...minHours);
};
export const getMaxHour = (parsedEventsData) => {
  const maxHours = parsedEventsData
    ?.map((item) => item.end.getHours())
    ?.filter((hour) => hour);
  return Math.max(...maxHours);
};
