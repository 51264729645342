import { Box, Typography } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import { checkEqualStringArrays } from '../../../../../../../shared/utils/common';
import styles from './style.module.css';

const groupProperties = (data) => {
  const result = {};

  data?.forEach((item) => {
    const { type, operation, propertyName, from, to, id } = item;

    if (!result[propertyName]) {
      result[propertyName] = {
        type,
        operation,
        propertyName,
        from: [],
        to: [],
        id: [],
      };
    }
    if (
      propertyName === 'days' ||
      propertyName === 'visitTypes' ||
      propertyName === 'timeOfDays'
    ) {
      if (from !== null) result[propertyName]?.from.push(from);
      if (to !== null) result[propertyName]?.to.push(to);
      result[propertyName]?.id?.push(id);
    } else {
      result[propertyName] = { type, operation, propertyName, from, to, id };
    }
  });

  return Object.values(result);
};

const initializeTaskChanges = () => ({
  name: '',
  nameBefore: '',
  levelOfAssistance: '',
  levelOfAssistanceBefore: '',
  description: '',
  descriptionBefore: '',
  recurrencePattern: {
    days: [],
    daysBefore: [],
    timeOfDays: [],
    timesOfDayBefore: [],
    visitTypes: [],
    visitTypesBefore: [],
  },
});

const getHistoryList = (arr) => {
  const taskChanges = initializeTaskChanges();
  const groupPropertiesArray = groupProperties(arr);

  let taskRemoved = false;
  let taskUpdate = false;
  groupPropertiesArray.forEach((item) => {
    const { propertyName, to, from, operation } = item;
    const ifIsUpdate =
      operation === 'remove' && to?.length > 0 && from?.length > 0;
    if (operation === 'insert') {
      switch (propertyName) {
        case 'name':
          taskChanges.name = to;
          break;
        case 'levelOfAssistance':
          taskChanges.levelOfAssistance = to;
          break;
        case 'details':
          taskChanges.description = to;
          break;
        case 'days':
          taskChanges.recurrencePattern.days = to;
          break;
        case 'timeOfDays':
          taskChanges.recurrencePattern.timeOfDays = to;
          break;
        case 'visitTypes':
          taskChanges.recurrencePattern.visitTypes = to;
          taskChanges.recurrencePattern.visitTypesBefore = from;
          break;
        default:
          break;
      }
    } else if (operation === 'remove' && propertyName === 'name') {
      taskChanges.name = from;
      taskRemoved = true;
    } else if (
      operation === 'noChange' ||
      ifIsUpdate ||
      operation === 'update'
    ) {
      taskUpdate = true;
      switch (propertyName) {
        case 'name':
          taskChanges.name = to;
          taskChanges.nameBefore = from;
          break;
        case 'levelOfAssistance':
          taskChanges.levelOfAssistance = to;
          taskChanges.levelOfAssistanceBefore = from;
          break;
        case 'details':
          taskChanges.description = to;
          taskChanges.descriptionBefore = from;
          break;
        case 'days':
          taskChanges.recurrencePattern.days = to;
          taskChanges.recurrencePattern.daysBefore = from;
          break;
        case 'timeOfDays':
          taskChanges.recurrencePattern.timeOfDays = to;
          taskChanges.recurrencePattern.timesOfDayBefore = from;
          break;
        case 'visitTypes':
          taskChanges.recurrencePattern.visitTypes = to;
          taskChanges.recurrencePattern.visitTypesBefore = from;
          break;
        default:
          break;
      }
    }
  });

  const renderCarePlanHistory = () => {
    if (taskRemoved) {
      return (
        <Typography className={styles.removedType}>
          <span className={styles.boldWeight}> Task Removed: </span>
          {taskChanges.name || 'N/A'}
        </Typography>
      );
    }
    if (taskUpdate) {
      return (
        <Box sx={{ p: '10px 0' }}>
          <Box sx={{ float: 'left', mb: '12px' }}>
            <span className={styles.boldWeight}> Task Updated: </span>
            {taskChanges.name === taskChanges.nameBefore ? (
              taskChanges.name
            ) : (
              <span>
                Name is changed from
                <span className={styles.oldTime}>{taskChanges.nameBefore}</span>
                to
                <span className={styles.newTime}> {taskChanges.name}</span>
              </span>
            )}
          </Box>
          <ul className={styles.bulletList}>
            <li className={`${styles.bulletListItem}`}>
              <Box>
                <span className={styles.boldWeight}>Level of Assistance:</span>
                {taskChanges.levelOfAssistance ===
                taskChanges.levelOfAssistanceBefore ? (
                  'N/A'
                ) : (
                  <span>
                    changed from
                    <span className={styles.oldTime}>
                      {taskChanges.levelOfAssistanceBefore}
                    </span>
                    to
                    <span className={styles.newTime}>
                      {taskChanges.levelOfAssistance}
                    </span>
                  </span>
                )}
              </Box>
            </li>
            <li className={`${styles.bulletListItem}`}>
              <Box>
                <span className={styles.boldWeight}> Description: </span>
                {taskChanges.description === taskChanges.descriptionBefore ? (
                  'N/A'
                ) : (
                  <span>
                    changed from
                    <span className={styles.oldTime}>
                      {taskChanges.descriptionBefore}
                    </span>
                    to
                    <span className={styles.newTime}>
                      {taskChanges.description}
                    </span>
                  </span>
                )}
              </Box>
            </li>
            <li className={`${styles.bulletListItem}`}>
              <Box>
                Recurrence Pattern:
                {taskChanges.recurrencePattern?.days.length > 0 ||
                taskChanges.recurrencePattern?.timeOfDays.length > 0 ||
                taskChanges.recurrencePattern?.visitTypes.length > 0 ? (
                  <Box>
                    <Box>
                      <span className={styles.boldWeight}>Days:</span>
                      <span>
                        {checkEqualStringArrays(
                          taskChanges.recurrencePattern?.days,
                          taskChanges.recurrencePattern?.daysBefore,
                        ) ? (
                          'N/A'
                        ) : (
                          <span>
                            changed from
                            <span className={styles.oldTime}>
                              {taskChanges.recurrencePattern?.daysBefore.join(
                                ', ',
                              )}
                            </span>
                            to
                            <span className={styles.newTime}>
                              {taskChanges.recurrencePattern?.days.join(', ')}
                            </span>
                          </span>
                        )}
                      </span>
                    </Box>
                    <Box>
                      <span className={styles.boldWeight}>Times of Day:</span>
                      <span>
                        {checkEqualStringArrays(
                          taskChanges.recurrencePattern?.timeOfDays,
                          taskChanges.recurrencePattern?.timesOfDayBefore,
                        ) ? (
                          'N/A'
                        ) : (
                          <span>
                            changed from
                            <span className={styles.oldTime}>
                              {taskChanges.recurrencePattern?.timesOfDayBefore.join(
                                ', ',
                              )}
                            </span>
                            to
                            <span className={styles.newTime}>
                              {taskChanges.recurrencePattern?.timeOfDays.join(
                                ', ',
                              )}
                            </span>
                          </span>
                        )}
                      </span>
                    </Box>
                    <Box>
                      <span className={styles.boldWeight}>Visit Types:</span>
                      <span>
                        {checkEqualStringArrays(
                          taskChanges.recurrencePattern?.visitTypes,
                          taskChanges.recurrencePattern?.visitTypesBefore,
                        ) ? (
                          'N/A'
                        ) : (
                          <span>
                            changed from
                            <span className={styles.oldTime}>
                              {taskChanges.recurrencePattern?.visitTypesBefore.join(
                                ', ',
                              )}
                            </span>
                            to
                            <span className={styles.newTime}>
                              {taskChanges.recurrencePattern?.visitTypes.join(
                                ', ',
                              )}
                            </span>
                          </span>
                        )}
                      </span>
                    </Box>
                  </Box>
                ) : (
                  'N/A'
                )}
              </Box>
            </li>
          </ul>
        </Box>
      );
    }
    return (
      <Box sx={{ p: '10px 0' }}>
        <Typography
          className={styles.addedType}
          sx={{ float: 'left', mb: '12px' }}
        >
          <span className={styles.boldWeight}> Task Added: </span>
          {taskChanges.name || 'N/A'}
        </Typography>
        <ul className={styles.bulletList}>
          <li className={`${styles.bulletListItem} ${styles.addedType}`}>
            <Typography>
              <span className={styles.boldWeight}>Level of Assistance:</span>{' '}
              {taskChanges.levelOfAssistance || 'N/A'}
            </Typography>
          </li>
          <li className={`${styles.bulletListItem} ${styles.addedType}`}>
            <Typography>
              <span className={styles.boldWeight}> Description: </span>{' '}
              {taskChanges.description || 'N/A'}
            </Typography>
          </li>
          <li className={`${styles.bulletListItem} ${styles.addedType}`}>
            <Box>
              Recurrence Pattern:
              {taskChanges.recurrencePattern?.days.length > 0 ||
              taskChanges.recurrencePattern?.timeOfDays.length > 0 ||
              taskChanges.recurrencePattern?.visitTypes.length > 0 ? (
                <Box>
                  {taskChanges.recurrencePattern?.days.length > 0 && (
                    <Box>
                      <span className={styles.boldWeight}>Days:</span>
                      <span>
                        {taskChanges.recurrencePattern?.days.join(', ')}
                      </span>
                    </Box>
                  )}
                  {taskChanges.recurrencePattern?.timeOfDays.length > 0 && (
                    <Box>
                      <span className={styles.boldWeight}>Times of Day:</span>
                      <span>
                        {taskChanges.recurrencePattern?.timeOfDays.join(', ')}
                      </span>
                    </Box>
                  )}
                  {taskChanges.recurrencePattern?.visitTypes.length > 0 && (
                    <Box>
                      <span className={styles.boldWeight}>Visit Types:</span>
                      <span>
                        {taskChanges.recurrencePattern?.visitTypes.join(', ')}
                      </span>
                    </Box>
                  )}
                </Box>
              ) : (
                'N/A'
              )}
            </Box>
          </li>
        </ul>
      </Box>
    );
  };
  return <Box>{renderCarePlanHistory()}</Box>;
};

export default function CarePlanBulletList({ changes = [] }) {
  return <Box sx={{ paddingLeft: '20px' }}>{getHistoryList(changes)}</Box>;
}

CarePlanBulletList.propTypes = {
  changes: instanceOf(Object),
};
