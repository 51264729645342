import moment from 'moment';
import { H12_TIME_FORMAT, H24_TIME_FORMAT } from '../../../../../../shared/constants';
import {convertNumbersToDays } from '../../constants';

/* Don't delete because logic can be useful */

export const checkIsPeriodsAreCollapsed = ({
  currentProgram,
  updatedCareProgram,
  startTime,
  endTime,
}) => {
  const comparedPrograms = updatedCareProgram.filter(
    (item) =>
      item.id !== currentProgram.id &&
      currentProgram.days.some((day) => item.days.indexOf(day) >= 0),
  );
  return comparedPrograms.some(({ arrivalEnd, arrivalStart, duration }) => {
    const convertedArrivalEnd = moment(arrivalEnd, H12_TIME_FORMAT).add(
      duration,
      'minutes',
    );
    const convertedArrivalStart = moment(arrivalStart, H12_TIME_FORMAT);
    return (
      startTime.isBetween(
        convertedArrivalStart,
        convertedArrivalEnd,
        'minutes',
        [],
      ) ||
      endTime.isBetween(
        convertedArrivalStart,
        convertedArrivalEnd,
        'minutes',
        [],
      ) ||
      convertedArrivalStart.isBetween(startTime, endTime, 'minutes', []) ||
      convertedArrivalEnd.isBetween(startTime, endTime, 'minutes', [])
    );
  });
};

export const checkIsDataHasBeenChanged = ({ initialData, updatedData }) => {
  const initialParams = initialData?.reduce(
    (
      string,
      {
        arrivalEnd,
        arrivalStart,
        duration,
        effectiveDateEnd,
        effectiveDateStart,
      },
    ) =>
      string +
      arrivalEnd +
      arrivalStart +
      duration +
      effectiveDateEnd +
      effectiveDateStart +
    '',
  );
  const updatedParams = updatedData?.reduce(
    (
      string,
      {
        arrivalEnd,
        arrivalStart,
        duration,
        effectiveDateEnd,
        effectiveDateStart,
      },
    ) =>
      string +
      arrivalEnd +
      arrivalStart +
      duration +
      effectiveDateEnd +
      effectiveDateStart +
    '',
  );
  return initialParams !== updatedParams;
};

export const checkIsCreateVisitStepConfirmButtonDisabled = (data) => {
  const { alternativeArrivalWindows, selectedTimeSlots, step, visitData } =
    data;
  if (step === 0) {
    const {
      arrivalWindowDuration,
      days,
      duration,
      recurrence,
      timesOfDays,
      visitType,
    } = visitData || {};
    return (
      !recurrence ||
      !arrivalWindowDuration ||
      !timesOfDays?.length ||
      !days ||
      !Object.keys(days).length ||
      !duration ||
      !visitType
    );
  }
  if (step === 1) {
    return (
      selectedTimeSlots === null ||
      Object.values(selectedTimeSlots)?.length !== Object.keys(visitData?.days).length
    );
  }
  if (step === 2) {
    return (
      !alternativeArrivalWindows ||
      !!alternativeArrivalWindows?.unAvailableSchedule?.filter(
        (visit) => !visit.cancelled,
      ).length
    );
  }
  return false;
};

export const getNewVisitsList = ({
  selectedTimeSlots,
  updatedCareProgram,
  visitData,
  effectiveDate,
  clientId
}) => {
  const thisVisitData = { ...visitData };
  const days = {};
  for (const day in selectedTimeSlots) {
    const start = moment(selectedTimeSlots[day].arrivalStart, H12_TIME_FORMAT).format(H24_TIME_FORMAT);
    const end = moment(selectedTimeSlots[day].arrivalEnd, H12_TIME_FORMAT).format(H24_TIME_FORMAT);
    days[convertNumbersToDays[day]] = {"arrivalStart": start, "arrivalEnd": end};
  }
  const newUpdatedCareProgram = [...updatedCareProgram];
  thisVisitData.effectiveDateStart = effectiveDate;
  thisVisitData.days = days;
  if (!thisVisitData.id) {
    thisVisitData.clientId = clientId;
    thisVisitData.changesType = "add";
    newUpdatedCareProgram.push(thisVisitData);
  } else {
    thisVisitData.changesType = "new";
    thisVisitData.dateCreated = moment().toISOString();
    const latestVisits = newUpdatedCareProgram.filter(
      (visit) => visit.id === thisVisitData.id).filter(
        (visit) => !visit.effectiveDateEnd
    );
    let latestIndex = null;
    if (latestVisits.length) {
      latestIndex = newUpdatedCareProgram.indexOf(latestVisits[0]);
    }
    if (newUpdatedCareProgram[latestIndex] !== thisVisitData){
      newUpdatedCareProgram[latestIndex].changesType = "existing";
      newUpdatedCareProgram.push(thisVisitData);
    }
  }
  return newUpdatedCareProgram;
};
