import ClearIcon from '@mui/icons-material/Clear';
import { Box, Chip } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import { chipClearIconStyle, chipComponentStyle } from './styles';

function CustomBadges({ disabled = false, items = [], onDelete = null, type }) {
  return (
    <Box
      sx={{
        display: 'flex',
        mt: '8px',
        gap: '8px',
        flexWrap: 'wrap',
        '& .MuiSvgIcon-root': {
          cursor: disabled ? 'default' : 'pointer',
        },
      }}
    >
      {items?.map((item) => (
        <Chip
          key={item.name}
          label={item.name}
          style={chipComponentStyle}
          onDelete={() => (disabled ? {} : onDelete(item.value, type))}
          deleteIcon={<ClearIcon sx={chipClearIconStyle} />}
        />
      ))}
    </Box>
  );
}

CustomBadges.propTypes = {
  disabled: bool,
  items: instanceOf(Array),
  onDelete: func,
};

export default React.memo(CustomBadges);
