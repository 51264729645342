import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';

export const CARE_PLAN_API_KEY = 'carePlanApi';

const carePlanApi = createApi({
  reducerPath: CARE_PLAN_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: (builder) => ({
    getClientTasksList: builder.query({
      query: ({ clientId }) => ({
        url: `crud/carePlan/`,
        method: 'GET',
        params: {
          index: "clientId-index",
          pk: "clientId",
          pkValue: clientId,
          sk: "active",
          skValue: "True",
          filters: [],
          },
      }),
      providesTags: () => [{ type: 'clientsTasks', id: 'ALL' }],
    }),
    createCarePlanTasks: builder.mutation({
      query: (payload) => ({
        url: `crud/carePlan/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'clientsTasks', id: 'ALL' }],
    }),
    updateCarePlanTasks: builder.mutation({
      query: (payload) => ({
        url: `crud/carePlan/`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'clientsTasks', id: 'ALL' }],
    }),
    deleteCarePlanTask: builder.mutation({
      query: (payload) => ({
        url: `crud/carePlan/`,
        method: 'DELETE',
        body: payload
      }),
      invalidatesTags: () => [{ type: 'clientsTasks', id: 'ALL' }],
    }),
    getCarePlanHistory: builder.query({
      query: (clientId) => ({
        url: `history/careplan`,
        method: 'GET',
        params: {
          index: "clientId-index",
          pk: "clientId",
          pkValue: clientId
        }
      }),
      providesTags: () => [{ type: 'clientsTasks', id: 'ALL' }],
    }),
  }),
});
export const {
  useGetClientTasksListQuery,
  useCreateCarePlanTasksMutation,
  useUpdateCarePlanTasksMutation,
  useDeleteCarePlanTaskMutation,
  useGetCarePlanHistoryQuery,
} = carePlanApi;

export default carePlanApi;
