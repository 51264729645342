import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import moment from 'moment';
import ErrorIcon from '../../img/icons/error-icon.svg';
import WarningIcon from '../../img/icons/warning-icon.svg';
import React, { useEffect, useState } from 'react';
import {
  customAccordionStyles,
  customAccordionSummaryStyles,
  visitAccordionSummaryTitle,
} from './styles';

const DateAccordion = ({data}) => {
  const [expanded, setExpanded] = useState(false);
  const [hasWarning, setHasWarning] = useState(false);
  const [hasError, setHasError] = useState(false);

  const checkForConstraints = () => {
    const newVisits = data?.newVisits;
    const differences = data?.differences;
    for (let visit in newVisits) {
      if (visit?.violatedHardConstraints?.length > 0) {
        setHasError(true);
      }
      if (visit?.violatedSoftConstraints?.length > 0) {
        setHasWarning(true);
      }
    }
    for (let difference in differences) {
      for (let change in differences[difference].changes) {
        if (change?.violatedHardConstraints) {
          setHasError(true);
        }
        if (change?.violatedSoftConstraints) {
          setHasWarning(true);
        }
      }
    }
  }

  useEffect(() => {
    if (data){
      checkForConstraints();

    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Accordion elevation={0} expanded={expanded} sx={customAccordionStyles}>
      <AccordionSummary
        // expandIcon={<Chevron onClick={() => setExpanded(!expanded)} />}
        aria-controls="panel1bh-content"
        sx={customAccordionSummaryStyles(data?.type, data?.softConstraints, data?.hardConstraints)}
      >
        <Box
          sx={visitAccordionSummaryTitle}
          onClick={() => setExpanded(!expanded)}
        >
          {hasWarning && <img src={WarningIcon} alt="Warning" />}
          {hasError && <img src={ErrorIcon} alt="Error" />}
          <Typography sx={{ lineHeight: '32px' }} variant="h5">
            {moment(data?.date).format("dddd, MMM D, YYYY")} - {data?.type}
          </Typography>
          {/* <CounterBadge label={tasksCount.toString()} hideIfNothing /> */}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>hello</AccordionDetails>
    </Accordion>
  );
}

export default DateAccordion;
