import { Box } from '@mui/material';
import moment from 'moment';
import { ClientProfilesContext } from '../../../../../pages/ClientDetails/context';
import React, { useContext, useState } from 'react';
import CustomSelect from '../../../../../components/Select';
import { additionalSelectStyles } from '../styles';
import EffectiveDateModal from './EffectiveDateModal';

export default function TeamSelector() {
  const {
    clientData,
    setClientData,
    teams
  } = useContext(ClientProfilesContext);

  const [date, setDate] = useState(moment().add(1, 'day'));
  const [openEffectiveDialog, setOpenEffectiveDialog] = useState(false);
  const [newTeam, setNewTeam] = useState(null);

  const submitEffectiveDateDialog = () => {
    const teamChanges = {...clientData?.pendingChanges?.team} || {};
    const teamChangeDetails = {...clientData?.pendingChanges?.teamDetails} || {};
    teamChanges[moment(date).format('YYYY-MM-DD')] = newTeam.id;
    teamChangeDetails[moment(date).format('YYYY-MM-DD')] = newTeam;
    const pendingChanges = {
      ...clientData.pendingChanges,
      team: teamChanges,
      teamDetails: teamChangeDetails,
    };
    const newProfileData = { ...clientData, pendingChanges: pendingChanges };
    setClientData(newProfileData);
    setOpenEffectiveDialog(false);
  };

  const onSelectChange = (value) => {
    const teamDetails = teams.find((team) => team.id === value.id);
    setNewTeam(teamDetails);
    setOpenEffectiveDialog(true);
  };

  return (
    <Box>
      <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
        <CustomSelect
          addStyles={additionalSelectStyles}
          id="team"
          isLoading={false}
          options={teams}
          placeHolder="Team"
          selectedValue={clientData?.teamDetails.id}
          setValue={onSelectChange}
        />
      </Box>
      <EffectiveDateModal
        openEffectiveDateDialog={openEffectiveDialog}
        closeEffectiveDateDialog={() => setOpenEffectiveDialog(false)}
        submitEffectiveDateDialog={submitEffectiveDateDialog}
        date={date}
        selectDate={(newValue) => setDate(newValue)}
        newTeam={newTeam}
      />
    </Box>
  );
}
