import moment from 'moment';
import { ISO_DATE_ONLY_FORMAT, SHORT_DATE_FORMAT } from '../../../../../../../../../../shared/constants';

export const getSummaryData = (data) => {
  const {
    effectiveDate,
    clientData,
    visitData,
  } = data;
  const arrivalWindows = [];

  return {
    client: `${clientData?.firstName} ${clientData?.lastName}`,
    address:`${clientData?.street} ${clientData?.city}, ${clientData?.state}, ${clientData?.zipCode}, ${clientData?.country}`,
    visitType: visitData?.visitTypeDetails.name,
    duration: visitData?.duration,
    recurrence: visitData?.recurrence,
    effectiveDate: moment(effectiveDate, ISO_DATE_ONLY_FORMAT).format(
      SHORT_DATE_FORMAT,
    ),
    arrivalWindows,
  };
};
