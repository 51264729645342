import { Stack, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import noDataIcon from '../../../../../img/icons/no-data.svg';
import { bool, instanceOf } from 'prop-types';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import CustomTable from '../../../../../components/Table';
import { noDataTableHintStyles } from '../../../../../components/Table/styles';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, getTableColumns } from '../constants';
import { customUpdateProgramTableStyles } from './styles';

function CareProgramTable({ careProgramVisits = [], setUpdatedCareProgram, isEditView = false }) {
  const [thisCareProgramVisits, setThisCareProgramVisits] = useState([]);
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (careProgramVisits.length > 0 && thisCareProgramVisits.length === 0 && isEditView) {
      const tempCareProgramVisits = [];
      careProgramVisits.forEach((visit) => {
        tempCareProgramVisits.push({ ...visit });
      });
      const visityIds = {};
      let numberOfVists = 0;
      for (let visit in tempCareProgramVisits) {
        let index = ``;
        const changesType = tempCareProgramVisits[visit].changesType;
        console.log(changesType);
        if (!(tempCareProgramVisits[visit].id in visityIds)) {
          numberOfVists++;
          if (changesType=== "existing" || !changesType|| changesType=== "old") {
            index = `${parseInt(visit)+1}.1`;
          } else if (changesType=== "new") {
            index = `${parseInt(visit)+1}.2`;
          } else {
            index = `${numberOfVists}.1`;
          }
          visityIds[tempCareProgramVisits[visit].id] = parseInt(visit)+1;
        } else {
          if (changesType === "existing" || !changesType|| changesType === "old") {
            if (changesType){
              if (changesType !== "old"){
                index = `${visityIds[tempCareProgramVisits[visit].id]}.1`;
                tempCareProgramVisits[visit].effectiveDateEnd = tempCareProgramVisits[visit].effectiveDateStart;
              } else {
                if (tempCareProgramVisits[visit].effectiveDateEnd){
                  index = `${visityIds[tempCareProgramVisits[visit].id]}.1`;
                } else {
                  index = `${visityIds[tempCareProgramVisits[visit].id]}.2`;
                }
              }
            } else {
              if (tempCareProgramVisits[visit].effectiveDateEnd){
                index = `${visityIds[tempCareProgramVisits[visit].id]}.1`;
              } else {
                index = `${visityIds[tempCareProgramVisits[visit].id]}.2`;
              }
            }
          } else if (changesType=== "new") {
            index = `${visityIds[tempCareProgramVisits[visit].id]}.2`;
            tempCareProgramVisits[visityIds[tempCareProgramVisits[visit].id] - 1].effectiveDateEnd = tempCareProgramVisits[visit].effectiveDateStart;
          } else {
            index = `${numberOfVists}.1`;
          }
        }
        tempCareProgramVisits[visit].updateId = index;
        if (!tempCareProgramVisits[visit].changesType){
          tempCareProgramVisits[visit].changesType = "old";
        }
      }
      if (setUpdatedCareProgram){
        setUpdatedCareProgram(tempCareProgramVisits);
      }
      setThisCareProgramVisits(tempCareProgramVisits);
    } else if (!isEditView){
      const tempCareProgramVisits = [];
      careProgramVisits.forEach((visit) => {
        tempCareProgramVisits.push({ ...visit });
      });
      let numberOfVists = 1;
      for (let visit in tempCareProgramVisits) {
        tempCareProgramVisits[visit].updateId = numberOfVists;
        numberOfVists++;
      }
      setThisCareProgramVisits(tempCareProgramVisits);
    }
    // eslint-disable-next-line
  }, [careProgramVisits]);

  const getNoRowsComponent = useCallback(
    () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <img src={noDataIcon} alt="" />
        <Typography sx={noDataTableHintStyles}>No visits yet. </Typography>
      </Stack>
    ),
    [],
  );
  const columns = useMemo(() => getTableColumns(isEditView), [isEditView]);
  return (
    <CustomTable
      apiRef={apiRef}
      columns={columns}
      customRowClassName="super-app-theme"
      customStyles={customUpdateProgramTableStyles}
      getRowClassName={(params) =>
        params.row.changesType === "existing" ? 'visit-children' : ''
      }
      headerHeight={44}
      noRowsOverlay={() => getNoRowsComponent()}
      pageSize={PAGE_SIZE}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      rowHeight={107}
      rows={thisCareProgramVisits}
      rowIdField="updateId"
    />
  );
}

CareProgramTable.propTypes = {
  careProgramVisits: instanceOf(Array),
  isEditView: bool,
};

export default React.memo(CareProgramTable);
