import axios from 'axios';

/**
 * Todo: This description needs to be filled
 * Todo: Remove console logging of token unless during development
 * @returns 
 */
export const getToken = async () => {
    const token = localStorage.getItem("accessToken");
    return token;
}

/**
 * Todo: This description needs to be filled
 * @param {*} api 
 * @param {*} method 
 * @param {*} type 
 * @param {*} data 
 * @returns 
 */
export const api = async (method, api, type, data) => {
    const accessToken = await getToken();
    const headers = {Authorization: accessToken};
    let baseUrl = process.env.REACT_APP_NEW_API;
    const url = `${baseUrl}api/${api}/${type}/`;
    const requestObj = { method: method, url: url, headers: headers };

    if (method === "GET") {
        requestObj.params = data;
    } else {
        requestObj.data = data;
    };
    try {
        const responseData = await axios(requestObj);
        return responseData;
    } catch (error) {
        if (error.response?.data?.message === "authorization has expired, please login again") {
            localStorage.clear();
            sessionStorage.clear();
            return { status: "success" };
        } else {
            return {
                error: true,
                status: error?.response?.status ? error?.response?.status : 500,
                statusText: error?.response?.statusText ? error?.response?.statusText : "Unkown Error",
                data: error?.response?.data ? error?.response?.data : {error: "Unkown Error"}
            };
        };
    };
};