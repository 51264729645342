import { Box, Typography } from '@mui/material';
import { bool, instanceOf, string } from 'prop-types';
import React from 'react';
import { detailsInfoTitle } from './styles';
import { getInfoComponent } from './utils';

export default function ProfileDataRow({
  isEdit = false,
  name = '',
  profileData = {},
  propKey = '',
  type = '',
}) {
  const thisValue = propKey === 'all' ? profileData : profileData[propKey];
  return (
    <Box
      sx={{
        display: 'flex',
        marginBottom: '24px',
        alignItems: 'flex-start',
      }}
    >
      <Typography
        sx={{
          ...detailsInfoTitle,
          alignItems: 'center',
        }}
      >
        {name}:
      </Typography>
      {profileData
        ? getInfoComponent({
            editMode: isEdit,
            type,
            value: thisValue,
          })
        : ''}
    </Box>
  );
}

ProfileDataRow.propTypes = {
  isEdit: bool,
  name: string,
  profileData: instanceOf(Object),
  propKey: string,
  type: string,
};
