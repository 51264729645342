import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Box, Button } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../shared/styles/Theme';

export default function EditProfileActions({
  cancelEditing = () => {},
  checkBlockedCareGivers = () => {},
  editProfile = () => {},
  hasChanges = false,
  isBlockedCaregiverListChanged = false,
  isEdit = false,
  updateClientData = () => {},
  userData = {},
}) {
  return (
    <Box>
      {!isEdit ? (
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<EditTwoToneIcon />}
          onClick={editProfile}
          sx={{
            '&.MuiButton-root': {
              backgroundColor: 'transparent',
              color: COLORS.blue[500],
              border: `1px solid ${COLORS.blue[200]}`,
            },
          }}
        >
          EDIT
        </Button>
      ) : (
        <Box sx={{ display: 'flex', gap: '28px' }}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={cancelEditing}
            disableElevation
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={
              !isBlockedCaregiverListChanged && userData?.status === 'Active'
                ? checkBlockedCareGivers
                : updateClientData
            }
            disableElevation
            disabled={!hasChanges && isBlockedCaregiverListChanged}
          >
            SAVE
          </Button>
        </Box>
      )}
    </Box>
  );
}

EditProfileActions.propTypes = {
  cancelEditing: func,
  checkBlockedCareGivers: func,
  editProfile: func,
  hasChanges: bool,
  isBlockedCaregiverListChanged: bool,
  isEdit: bool,
  updateClientData: func,
  userData: instanceOf(Object),
};
