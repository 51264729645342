export const taskInitialState = {
  categoryTypes: '',
  days: [],
  details: '',
  isCustom: false,
  levelOfAssistance: '',
  name: '',
  timeOfDays: [],
  visitTypes: [],
};

export const noEmptyFields = [
  'categoryTypes',
  'details',
  'levelOfAssistance',
  'name',
];

export const optionalEmptyFields = ['days', 'timeOfDays', 'visitTypes'];


