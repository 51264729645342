import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetHolidaysHistoryQuery } from '../../../../../../api/Administration/api';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import {
  HISTORY_UPDATE_FORMAT,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from '../../../../../../shared/constants';
import { getNoData } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomTable from '../../../../../../components/Table';
import { historyColumns } from '../../constants';

export default function NationalHolidaysHistory() {
  const apiRef = useGridApiRef();
  const { setAlert } = useContext(AlertContext);
  const {
    data,
    isLoading,
    error: getHolidaysHistoryError,
  } = useGetHolidaysHistoryQuery();

  useEffect(() => {
    if (getHolidaysHistoryError) {
      setAlert({
        errorData: getHolidaysHistoryError,
        type: 'error',
      });
    }
  }, [getHolidaysHistoryError, setAlert]);

  const updatedEvents = data
  ?.map((item, index) => {
    return {
      ...item,
      dateChanged: moment(item.dateChanged).format(HISTORY_UPDATE_FORMAT),
      id: index + 1,
    };
  })
  .filter(Boolean);

  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateChanged);
    const dateB = new Date(b.dateChanged);
    return dateB - dateA;
  });

  return (
    <Box sx={{ mt: '30px' }}>
      <CustomTable
        apiRef={apiRef}
        columns={historyColumns}
        headerHeight={44}
        rows={sortedUpdatedEvents}
        isLoading={isLoading}
        noRowsOverlay={() => getNoData({ title: 'No Holidays History yet' })}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
