import { Box } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import FullWidthDialog from '../FullWidthDialog';
import { api } from '../../api'
import AlertContext from '../Alert';
import Loader from '../Loader';
import DateAccordion from './dateAccordion';
import { WebSocketContext } from '../WebsocketProvider/WebsocketProvider';

const OptimizerCheck = ({ data, dataObjName, submitFunction, open, openToggle }) => {
  const [subscribe, unsubscribe] = useContext(WebSocketContext);
  const { setAlert } = useContext(AlertContext);
  const [optimizerData, setOptimizerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [savedNewVisits, setSavedNewVisits] = useState(false);
  const [savedExistingVisits, setSavedExistingVisits] = useState(false);
  const [deletedQueueItems, setSavedQueueItems] = useState(false);

  const getData = async () => {
    const payload = {
      [dataObjName]: data,
    }
    const apiData = await api('POST', 'check', "optimizer", payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      if (!apiData?.data?.optimized){
        submitFunction(data);
        setAlert({
          message: 'Nothing to optimize',
          type: 'success',
        });
        setDisabledSubmit(true);
        setSavedNewVisits(false);
        setSavedExistingVisits(false);
        setSavedQueueItems(false);
        openToggle(false);
      } else {
        setOptimizerData(apiData.data);
      }
      setLoading(false);
    }
  };

  const splitPayloads = (payloads) => {
    const listOfPayloads = [];
    let numberOfVisits = 0;
    let numberOfPayloads = 0;
    for (let visit in payloads) {
      if (numberOfVisits === 0) {
        listOfPayloads.push([])
      }
      if (numberOfVisits === 50) {
        numberOfVisits = 0;
        numberOfPayloads++;
        listOfPayloads.push([])
      }
      listOfPayloads[numberOfPayloads].push(payloads[visit]);
      numberOfVisits++;
    }
    return listOfPayloads;
  };

  const addVisits = async (addedVisits) => {
    const listOfPayloads = splitPayloads(addedVisits);
    for (let payload in listOfPayloads) {
      const apiData = await api('POST', 'crud', "visits", listOfPayloads[payload]);
      if (apiData.error) {
        setAlert({
          apiData,
          type: 'error',
        });
      } else {
        setAlert({
          message: 'Visits saved successfully',
          type: 'success',
        });
      }
    }
    setSavedNewVisits(true);
  };


  const updateVisits = async (editedVisits) => {
    const listOfPayloads = splitPayloads(editedVisits);
    for (let payload in listOfPayloads) {
      const apiData = await api('PATCH', 'crud', "visits", listOfPayloads[payload]);
      if (apiData.error) {
        setAlert({
          apiData,
          type: 'error',
        });
      } else {
        setAlert({
          message: 'Visits updated successfully',
          type: 'success',
        });
      }
    }
    setSavedExistingVisits(true);
  };

  const deleteQueueItems = async (queueIds) => {
    const apiData = await api('DELETE', 'crud', "optimizationQueue", queueIds);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      setSavedQueueItems(true);
      setAlert({
        message: 'Queue items deleted successfully',
        type: 'success',
      });
    };
  };

  useEffect(() => {
    if (open){
      getData();
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (savedNewVisits && savedExistingVisits && deletedQueueItems) {
      setOptimizerData(null);
      setDisabledSubmit(true);
      setSavedNewVisits(false);
      setSavedExistingVisits(false);
      setSavedQueueItems(false);
      openToggle(false);
    }
    // eslint-disable-next-line
  }, [savedNewVisits, savedExistingVisits, deletedQueueItems]);

  const cancelOptimizer = () => {
    setLoading(true);
    setSavedNewVisits(true);
    setSavedExistingVisits(true);
    const queueRecords = []
    for (let payload in optimizerData.queuePayLoads) {
      queueRecords.push({id: optimizerData.queuePayLoads[payload].queueId});
    }
    if (queueRecords.length > 0) {
      const tempPayload = {
        realdelete: true,
        items: queueRecords,
      }
      deleteQueueItems(tempPayload);
    } else {
      setSavedQueueItems(true);
    }
  }

  const optimizeSchedule = () => {
    setLoading(true);
    const editedVisits = []
    const addedVisits = []
    const queueRecords = []
    for (let payload in optimizerData.queuePayLoads) {
      queueRecords.push({id: optimizerData.queuePayLoads[payload].id});
      for (let visit in optimizerData.queuePayLoads[payload].addedVisits) {
        addedVisits.push(optimizerData.queuePayLoads[payload].addedVisits[visit]);
      }
      for (let visit in optimizerData.queuePayLoads[payload].editedVisits) {
        editedVisits.push(optimizerData.queuePayLoads[payload].editedVisits[visit]);
      }
    }
    if (addedVisits.length > 0) {
      addVisits(addedVisits);
    } else {
      setSavedNewVisits(true);
    }
    if (editedVisits.length > 0) {
      updateVisits(editedVisits);
    } else {
      setSavedExistingVisits(true);
    }
    if (queueRecords.length > 0) {
      const tempPayload = {
        realdelete: true,
        items: queueRecords,
      }
      deleteQueueItems(tempPayload);
    } else {
      setSavedQueueItems(true);
    }
    submitFunction(data);
  }

  useEffect(() => {
    subscribe("optimizer", (messageData) => {
      if (messageData.command === "updatedStatus" || messageData.command === "allFinished") {
        if (messageData.command === "allFinished") {
          setDisabledSubmit(false);
        }
        if (optimizerData){
          const tempQueuePayLoads = optimizerData.queuePayLoads;
          const newQueuePayLoads = []
          for (let payload in tempQueuePayLoads) {
            if (tempQueuePayLoads[payload].id === messageData.queueId) {
              newQueuePayLoads.push(messageData.newRecord);
            } else {
              newQueuePayLoads.push(tempQueuePayLoads[payload]);
            }
          }
          optimizerData.queuePayLoads = newQueuePayLoads;
          setOptimizerData({...optimizerData});
        }
      }
    });
    return () => {
      unsubscribe("optimizer");
    };
  }, [optimizerData, subscribe, unsubscribe]);

  return (
    <Box>
      <FullWidthDialog
        cancelButtonName="cancel"
        cancelCallback={cancelOptimizer}
        backButtonCallback={cancelOptimizer}
        submitButtonName="confirm Updates"
        submitCallback={optimizeSchedule}
        title="Optimizer Check"
        openDialog={open}
        disabledSubmit={disabledSubmit}
      >
        <Box sx={{ padding: '30px' }}>
          {loading ? <Loader /> : 
            <Box sx={{ padding: '30px' }}>
              {optimizerData?.queuePayLoads.map((item, index) => (
                <DateAccordion key={index} data={item}/>
              ))}
            </Box>
          }
        </Box>
      </FullWidthDialog>
    </Box>
  );
}

export default OptimizerCheck;
