import { Box } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { WORKING_WEEK_LENGTH } from '../../../../../../../../../../shared/constants';
import HorizontalItemsSelector from '../../../../../../../../../../components/HorizontalItemsSelector';
import CustomSelect from '../../../../../../../../../../components/Select';
import { visitRepeatItems } from '../constants';

export default function DaysSchedule({
  disabledDays = {},
  filtersData = {},
  handleChangeDays = () => {},
  onSelectChange = () => {},
  visitData = {},
}) {
  const weekdays = [
    { name: 0, value: "M" },
    { name: 1, value: "T" },
    { name: 2, value: "W" },
    { name: 3, value: "TH" },
    { name: 4, value: "F" },
    { name: 5, value: "SA" },
    { name: 6, value: "SU" },
  ]
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ width: '100%', height: '8px' }} />
      <HorizontalItemsSelector
        handleChange={handleChangeDays}
        disabledDays={disabledDays}
        options={weekdays.slice(0, WORKING_WEEK_LENGTH)}
        isRequired
        selectedDays={visitData?.days ? Object.keys(visitData?.days)?.map((day) => parseInt(day)) : []}
        title="Recurrence Pattern"
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <CustomSelect
        disabled
        id="repeat"
        isLoading={false}
        options={visitRepeatItems}
        placeHolder="Repeat"
        selectedValue={visitData?.recurrence}
        setValue={onSelectChange}
      />
    </Box>
  );
}

DaysSchedule.propTypes = {
  filtersData: instanceOf(Object),
  disabledDays: instanceOf(Object),
  handleChangeDays: func,
  onSelectChange: func,
  visitData: instanceOf(Object),
};
