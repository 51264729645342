import { Box } from '@mui/material';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext } from 'react';
import CustomSelect from '../../../../../components/Select';

export default function SkillsSelector() {
  const { setProfileData, editingProfileDataLibrary, profileData } =
    useContext(CaregiverProfileContext);

  const onSelectChange = (params) => {
    const newDetails = []
    for (let skill in editingProfileDataLibrary.skills.skills){
      if (params.value.includes(editingProfileDataLibrary.skills.skills[skill].id)){
        newDetails.push(editingProfileDataLibrary.skills.skills[skill])
      }
    }
    setProfileData({
      ...profileData,
      "skills": params.value,
      "skillsDetails": newDetails
    });
  };
  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <CustomSelect
        id="skills"
        skillLang
        options={editingProfileDataLibrary.skills.skills}
        isLoading={false}
        isMultiselect
        selectedValue={profileData.skillsDetails}
        setValue={onSelectChange}
      />
    </Box>
  );
}
