import { CacheLookupPolicy } from '@azure/msal-browser';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { pcaInstance } from '../config';

const authErrorHandler = async () => {
  try {
    const currentLocalAccountId = JSON.parse(localStorage.getItem('user'))?.id;
    if (currentLocalAccountId) {
      const account = pcaInstance.getAccountByLocalId(currentLocalAccountId)
      if (account) {
        await pcaInstance.acquireTokenSilent({
          scopes: ['user.read'],
          cacheLookupPolicy: CacheLookupPolicy.AccessTokenAndRefreshToken,
          account: account
        });
        window.location.reload()
      } else {
        throw new Error('No account data found');
      }
    } else {
      throw new Error('No user data found');
    }
  } catch (err) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace(`${process.env.REACT_APP_BASE_URL}`);
  }
};

export const baseQueryDBMD = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_NEW_API}api`,
  prepareHeaders: async (headers) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      headers.set('authorization', `${token}`);
    }
    return headers;
  },
  validateStatus: async (response) => {
    if (response.status === 401) {
      await authErrorHandler();
    } else {
      return response.status;
    }
  },
});
