import { Box, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import {
  H12_TIME_FORMAT,
  MAX_SCHEDULER_WORKING_HOUR,
  MIN_SCHEDULER_WORKING_HOUR,
  visitStatusesMap,
} from '../../shared/constants';
import { COLORS } from '../../shared/styles/Theme';
import { convertUtcToTeamTimeZoneMomentObject } from '../../shared/utils/common';
import { pluralize } from '../../shared/utils/pluralize';
import { teamsTooltipStyles } from './styles';

export const LOCAL_STORAGE_KEYS = {
  currentDate: 'CURRENT_DATE',
  scrollPosition: 'SCROLL_POSITION',
  temporaryScrollPosition: 'TEMPORARY_SCROLL_POSITION',
  teamsFilter: 'TEAMS_FILTER',
  timeValuesList: 'TIME_VALUES_LIST',
  oldStatus: 'OLD_STATUS',
};

export const TIME_CONTENT_HEIGHT_EXPANDED = 'calc(100vh - 345px)';
export const TIME_CONTENT_HEIGHT = 'calc(100vh - 250px)';
export const PREFERRED_TITLE = `The caregiver you are about to assign does not match the client's preferred caregiver choice for the visit.`;
export const HIDE_HEADER_HEIGHT_EXPANDED = '257px';
export const HIDE_HEADER_HEIGHT = '102px';
export const SHOW_DETAILS_BUTTON_HEIGHT_COLLAPSE = '250px';
export const SHOW_DETAILS_BUTTON_HEIGHT_EXPAND = '158px';
export const DEFAULT_HEADER_HEIGHT = '101px';
export const EXPANDED_HEADER_HEIGHT = '195px';

export const makeCaregiversVists = (visits) => {
  const newVisits = visits.map((visit) => {
    return {
      ...visit,
      resourceId: visit.caregiverId,
      start: new Date(visit.arrivalTime),
      end: new Date(moment(visit.arrivalTime).add(visit.duration, 'minutes').format("YYYY-MM-DDTHH:mm:ss")),
      isDraggable: checkIfDraggable({
        isShadowVisit: visit.isShadowVisit,
        status: visit.status,
      }),
      arrivalTimeDuration: moment(visit.arrivalEnd).diff(visit.arrivalStart, 'minutes'),
      travelTime: 20,
      timeDifferenceBetweenStartAndArrivalStart: 0,
      timeDifferenceBetweenStartAndArrivalEnd: 60,
    };
  });
  return newVisits;
};

export const checkIfDraggable = ({ isShadowVisit, status }) => {
  let isDraggable = false;
  if (
    !isShadowVisit &&
    [
      visitStatusesMap.allocated,
      visitStatusesMap.cannotBeAllocated,
      visitStatusesMap.scheduled,
      visitStatusesMap.enRoute,
      visitStatusesMap.onSite,
    ].includes(status)
  ) {
    isDraggable = true;
  }
  return isDraggable;
};
export const detailsButtons = [
  { id: 'Calendar', name: 'Calendar', infoType: 'calendar' },
  { id: 'Visits', name: 'Visits', infoType: 'visits' },
  { id: 'PTO & Callouts', name: 'PTO & Callouts', infoType: 'time-off-requests' },
];
export const truncateName = (str, maxLength, number) =>
  str?.length > maxLength ? `${str.substring(0, number)}...` : str;

export const formattedTime = (time) => {
  const duration = moment.duration(time, 'minutes');
  const hours = duration.hours();
  const minutes = duration.minutes();
  if (hours <= 0) {
    return `${minutes}min `;
  }
  if (hours >= 1 && minutes === 0) {
    return `${pluralize(hours, 'hour')}`;
  }
  if (hours >= 1 && minutes > 0) {
    return `${pluralize(hours, 'hour')} ${minutes}min`;
  }
  return '';
};

export const notAllocatedDescription = {
  id: "NoCaregiver",
  title: 'Not Allocated Visits',
  text: 'Please adjust them manually.',
  description:
    'The visits in this column failed to get allocated to the Caregivers by the automated Scheduling Engine due to the hard constraints violation.',
};
export const updateRealEvents = (event) =>
  event?.map((ev, ind) => {
    const travelTime = ev?.isShadowVisit
      ? ev?.shadowRoadTimeInMinutes
      : ev?.roadTimeInMinutes;
    const shadowTravelTime = ev?.shadowRoadTimeInMinutes;
    const arrivalStart = moment(ev?.arrivalStart, 'h:mm A');
    const arrivalEnd = moment(ev?.arrivalEnd, 'h:mm A');
    const duration =
      ev?.actualDuration === 0 || !ev?.actualDuration
        ? ev?.duration
        : ev?.actualDuration;
    const arrivalTimeFunc = () => {
      if (ev?.actualTimeStart && ev?.actualTimeStart !== 'NOT STARTED') {
        return moment(ev?.actualTimeStart, 'h:mm A');
      }
      if (ev?.arrivalTime) {
        return moment(ev?.arrivalTime, 'h:mm A');
      }
      return arrivalEnd;
    };
    const arrivalTime = arrivalTimeFunc();
    const timeDifferenceBetweenStartAndArrivalEnd = arrivalEnd?.diff(
      arrivalTime,
      'minutes',
    );
    const timeDifferenceBetweenStartAndArrivalStart = arrivalTime?.diff(
      arrivalStart,
      'minutes',
    );
    const arrivalTimeEnds = moment(ev?.date)
      .set({
        hour: arrivalTime?.hours(),
        minute: arrivalTime?.minutes(),
        second: 0,
        millisecond: 0,
      })
      .add(timeDifferenceBetweenStartAndArrivalEnd, 'minutes')
      .toDate();
    const start = moment(ev?.date)
      .set({
        hour: arrivalTime?.hours(),
        minute: arrivalTime?.minutes(),
        second: 0,
        millisecond: 0,
      })
      .toDate();
    const arrivalTimeStart = moment(ev?.date)
      .set({
        hour: arrivalTime?.hours(),
        minute: arrivalTime?.minutes(),
        second: 0,
        millisecond: 0,
      })
      .subtract(timeDifferenceBetweenStartAndArrivalStart, 'minutes')
      .toDate();
    const arrivalTimeDuration = moment
      .duration(moment(arrivalTimeEnds).diff(moment(arrivalTimeStart)))
      .asMinutes();

    const end = moment(start).add(duration, 'minutes').toDate();

    const type = ev?.visitType?.toLowerCase();
    const team = ev?.team?.name;
    const title = ev?.visitType;
    const violatedSoftConstraints = ev?.violatedSoftConstraints;
    const violatedHardConstraints = ev?.violatedHardConstraints;
    const withArrival =
      arrivalTimeDuration === 0 ? 'withoutArrival' : 'withArrival';
    if (ev?.title === 'Lunch break') {
      const lunchBreakStartTime = moment(ev?.startTime, 'h:mm A');
      const lunchBreakStart =
        lunchBreakStartTime &&
        moment(ev?.date)
          .set({
            hour: lunchBreakStartTime?.hours(),
            minute: lunchBreakStartTime?.minutes(),
            second: 0,
            millisecond: 0,
          })
          .toDate();
      const lunchBreakEnd =
        lunchBreakStartTime &&
        moment(lunchBreakStart).add(duration, 'minutes').toDate();
      return {
        start: lunchBreakStart,
        end: lunchBreakEnd,
        arrivalTimeStart: lunchBreakStart,
        arrivalTimeEnds: lunchBreakEnd,
        title,
        isDraggable: false,
        resourceId: ev?.resourceId,
        type,
        duration,
      };
    }
    return {
      arrivalTimeDuration,
      arrivalTimeEnds,
      arrivalTimeStart,
      availabilities: ev?.availabilities,
      caregiver: ev?.caregiver,
      client: ev?.client,
      duration,
      end,
      eventId: ind + 1,
      id: ev.id,
      isDraggable: false,
      isShadowVisit: ev?.isShadowVisit,
      preferredCaregiver: ev?.preferredCaregiver,
      region: ev?.team,
      repeat: ev?.recurrence,
      resourceId: ev?.resourceId,
      shadowCaregiver: ev?.shadowCaregiver,
      shadowStatus: ev?.shadowStatus,
      shadowTravelTime,
      start,
      status: ev?.status,
      team,
      timeDifferenceBetweenStartAndArrivalEnd,
      timeDifferenceBetweenStartAndArrivalStart,
      title,
      travelTime,
      type,
      date: ev?.date,
      violatedSoftConstraints,
      violatedHardConstraints,
      withArrival,
    };
  });

export const updateNotAllocatedVisits = (data, otherEventsLength) =>
  data?.notAllocatedVisits?.map((ev, ind) => {
    const arrivalStart = convertUtcToTeamTimeZoneMomentObject(ev?.arrivalStart);
    const arrivalEnd = convertUtcToTeamTimeZoneMomentObject(ev?.arrivalEnd);

    const timeDifferenceBetweenStartAndArrivalStart = arrivalEnd?.diff(
      arrivalStart,
      'minutes',
    );
    const arrivalTimeEnds = moment(ev.date)
      .set({
        hour: arrivalEnd?.hours(),
        minute: arrivalEnd?.minutes(),
        second: 0,
        millisecond: 0,
      })
      .toDate();
    const start = moment(ev.date)
      .set({
        hour: arrivalEnd?.hours(),
        minute: arrivalEnd?.minutes(),
        second: 0,
        millisecond: 0,
      })
      .toDate();
    const arrivalTimeStart = moment(ev.date)
      .set({
        hour: arrivalEnd?.hours(),
        minute: arrivalEnd?.minutes(),
        second: 0,
        millisecond: 0,
      })
      .subtract(timeDifferenceBetweenStartAndArrivalStart, 'minutes')
      .toDate();
    const arrivalTimeDuration = moment
      .duration(moment(arrivalTimeEnds).diff(moment(arrivalTimeStart)))
      .asMinutes();
    const end = moment(start).add(ev.duration, 'minutes').toDate();
    const type = ev?.visitType?.toLowerCase();
    const team = ev?.team?.name;
    const title = ev?.visitType;
    const violatedHardConstraints = ev?.violatedHardConstraints;
    const withArrival =
      timeDifferenceBetweenStartAndArrivalStart === 0
        ? 'withoutArrival'
        : 'withArrival';
    return {
      timeDifferenceBetweenStartAndArrivalStart,
      withArrival,
      duration: ev?.duration,
      client: ev?.client,
      resourceId: 1,
      arrivalTimeStart,
      arrivalTimeEnds,
      violatedHardConstraints,
      // status: 'Not Allocated',
      eventId: otherEventsLength + ind + 1,
      status: 'Scheduled',
      end,
      start,
      team,
      type,
      title,
      isDraggable: false,
      arrivalTimeDuration,
      region: ev?.team,
      id: ev.id,
      repeat: ev?.recurrence,
    };
  });

export const addArrivalEvents = ({
  eventsList,
  filteredCaregivers,
  currentDate,
}) => {
  const updatedEventsList = eventsList ? [...eventsList] : [];

  eventsList?.forEach((event) => {
    // const caregiversInTeam = filteredCaregivers?.filter(
    //   (t) => t?.team === event?.team && t.resourceId !== event.resourceId,
    // );
    const caregiversInTeam = filteredCaregivers?.filter(
      (t) =>
        t?.primaryAndSecondaryTeams?.includes(event?.team) &&
        t?.resourceId !== event?.resourceId,
    );
    if (event?.withArrival === 'withoutArrival') {
      return;
    }
    caregiversInTeam.forEach((caregiver) => {
      if (moment(event?.start) <= moment()) {
        return;
      }
      if (caregiver.resourceId === event.resourceId) {
        return;
      }

      const isCaregiverAvailable = caregiver.availabilities.map(
        (availability) => {
          const availabilityStartTime = moment(
            availability.startTime,
            'h:mm A',
          );
          const availabilityEndTime = moment(availability.endTime, 'h:mm A');
          const isNoEventInSlot = !updatedEventsList.some(
            (existingEvent) =>
              existingEvent.title !== 'arrival' &&
              existingEvent.resourceId === caregiver.resourceId &&
              moment(existingEvent.end).isSameOrAfter(
                moment(event.arrivalTimeStart),
              ) &&
              moment(existingEvent.start).isSameOrBefore(
                moment(event.arrivalTimeEnds),
              ),
          );
          const currDate = moment(currentDate);
          const availabilityStart = moment(currDate).set({
            hour: availabilityStartTime?.hours(),
            minute: availabilityStartTime?.minutes(),
            second: 0,
            millisecond: 0,
          });
          const availabilityEnd = moment(currDate).set({
            hour: availabilityEndTime?.hours(),
            minute: availabilityEndTime?.minutes(),
            second: 0,
            millisecond: 0,
          });
          // const isWithinAvailability = event.availabilities?.some(
          //   (availability1) => {
          //     const availabilityStart1 = moment(
          //       availability1.startTime,
          //       'h:mm A',
          //     );
          //     const availabilityEnd2 = moment(availability1.endTime, 'h:mm A');
          //     return moment(event.arrivalTimeStart).isBetween(
          //       availabilityStart1,
          //       availabilityEnd2,
          //       null,
          //       '[]',
          //     );
          //   },
          // );
          const isCaregiverAvailableDuringSlot =
            caregiver.availabilities.length <= 1
              ? moment(event.arrivalTimeStart).isBetween(
                  availabilityStart,
                  availabilityEnd,
                ) ||
                moment(event.arrivalTimeStart).isSameOrAfter(availabilityStart)
              : moment(event.arrivalTimeStart).isBetween(
                  availabilityStart,
                  availabilityEnd,
                ) &&
                moment(event.arrivalTimeStart).isSameOrAfter(availabilityStart);
          const checkPartialAvailabilityWithAvailability = () => {
            const isPartial =
              event.title !== 'arrival' &&
              event.title !== 'arrivalTime' &&
              moment(event?.arrivalTimeEnds)?.isBetween(
                availabilityStart,
                availabilityEnd,
                null,
                '[]',
              ) &&
              moment(event.arrivalTimeStart).isSameOrBefore(
                availabilityStart,
              ) &&
              moment(event.start).isBefore(availabilityStart) &&
              availabilityStart &&
              moment(event.arrivalTimeEnds).diff(
                availabilityStart,
                'minutes',
              ) !== 0;

            return {
              isPartial,
              start: availabilityStart.toDate(),
              end: event.arrivalTimeEnds,
            };
          };
          const isPartialAvailabilityTime =
            checkPartialAvailabilityWithAvailability();

          if (isPartialAvailabilityTime.isPartial) {
            const partialDuration = moment(isPartialAvailabilityTime.end).diff(
              moment(isPartialAvailabilityTime.start),
              'minutes',
            );
            const newEvent = {
              title: 'arrival',
              team: caregiver.team,
              caregiver: caregiver.caregiver,
              arrivalTimeDuration: event.arrivalTimeDuration,
              isDraggable: false,
              resourceId: caregiver.resourceId,
              start: isPartialAvailabilityTime.start,
              end: isPartialAvailabilityTime.end,
              region: event.region,
              client: event?.client,
              duration: partialDuration,
              eventId: event?.eventId,
            };
            updatedEventsList.push(newEvent);
          }

          const isPartiallyAvailable = updatedEventsList.map(
            (existingEvent) => {
              const isPartial =
                existingEvent.title !== 'arrival' &&
                existingEvent.resourceId === caregiver.resourceId &&
                moment(event.arrivalTimeStart).isBetween(
                  moment(existingEvent.start),
                  moment(existingEvent.end),
                  null,
                  '[]',
                ) &&
                moment(event.arrivalTimeEnds).isAfter(
                  moment(existingEvent.end),
                );
              return {
                isPartial,
                start: existingEvent.end,
                end: event.arrivalTimeEnds,
              };
            },
          );
          return {
            available: isNoEventInSlot && isCaregiverAvailableDuringSlot,
            isPartialObject: isPartiallyAvailable,
          };
        },
      );
      const duration = moment(event.arrivalTimeEnds).diff(
        moment(event.arrivalTimeStart),
        'minutes',
      );
      if (isCaregiverAvailable[0]?.available) {
        const newEvent = {
          title: 'arrival',
          team: caregiver.team,
          caregiver: caregiver.caregiver,
          arrivalTimeDuration: event.arrivalTimeDuration,
          isDraggable: false,
          resourceId: caregiver.resourceId,
          travelTime: 20,
          start: event.arrivalTimeStart,
          end: event.arrivalTimeEnds,
          region: event.region,
          client: event?.client,
          duration,
          eventId: event?.eventId,
        };
        updatedEventsList.push(newEvent);
      }
      isCaregiverAvailable[0]?.isPartialObject?.forEach((ev) => {
        if (ev?.isPartial) {
          const newEvent = {
            title: 'arrival',
            team: caregiver.team,
            arrivalTimeDuration: event.arrivalTimeDuration,
            isDraggable: false,
            caregiver: caregiver.caregiver,
            resourceId: caregiver.resourceId,
            travelTime: 20,
            start: ev?.start,
            end: ev?.end,
            region: event.region,
            client: event?.client,
            duration,
            eventId: event?.eventId,
          };
          updatedEventsList.push(newEvent);
        }
      });
    });
  });
  const finalEvents = updatedEventsList.filter((event) => {
    if (event?.title !== 'arrival') {
      return true;
    }
    return !updatedEventsList.some((otherEvent) => {
      if (
        otherEvent?.title === 'arrival' ||
        otherEvent?.resourceId !== event.resourceId
      ) {
        return false;
      }
      const eventStart = moment(event.start);
      const eventEnd = moment(event.end);
      const otherEventStart = moment(otherEvent.start);
      const otherEventEnd = moment(otherEvent.end);

      return (
        eventStart.isBetween(otherEventStart, otherEventEnd) ||
        eventEnd.isBetween(otherEventStart, otherEventEnd) ||
        otherEventStart.isBetween(eventStart, eventEnd) ||
        otherEventEnd.isBetween(eventStart, eventEnd)
      );
    });
  });

  return finalEvents.map((ev) => ({
    ...ev,
    isDraggable: checkIfDraggable({
      isShadowVisit: ev?.isShadowVisit,
      status: ev?.status,
    }),
  }));
};

export const addResourceIds = (data, visits, caregivers) => {
  let resourceIdCounter = 2;
  if (!data) {
    return null;
  }

  const updatedData = JSON.parse(JSON.stringify(data));

  updatedData?.caregivers?.forEach((caregiver) => {
    // eslint-disable-next-line no-plusplus
    const resourceId = resourceIdCounter++;
    // eslint-disable-next-line no-param-reassign
    caregiver.caregiver.resourceId = resourceId;
    // eslint-disable-next-line no-param-reassign
    caregiver.events = caregiver?.events?.map((event) => ({
      ...event,
      resourceId,
      availabilities: caregiver?.caregiver?.availabilities,
      isShadowVisit:
        event.shadowCaregiver &&
        caregiver.caregiver.id === event.shadowCaregiver.id,
    }));
  });
  return updatedData;
};
export const getAllEvents = (data) =>
  data?.caregivers
    ?.flatMap((caregiver) => caregiver?.events)
    .map((eventData) => ({
      ...eventData,
      arrivalTime: eventData?.arrivalTime
        ? convertUtcToTeamTimeZoneMomentObject(eventData?.arrivalTime).format(
            H12_TIME_FORMAT,
          )
        : null,
      arrivalStart: eventData?.arrivalStart
        ? convertUtcToTeamTimeZoneMomentObject(eventData?.arrivalStart).format(
            H12_TIME_FORMAT,
          )
        : null,
      arrivalEnd: eventData?.arrivalEnd
        ? convertUtcToTeamTimeZoneMomentObject(eventData?.arrivalEnd).format(
            H12_TIME_FORMAT,
          )
        : null,
      actualTimeStart:
        eventData?.actualTimeStart &&
        eventData?.actualTimeStart !== 'NOT STARTED'
          ? convertUtcToTeamTimeZoneMomentObject(
              eventData?.actualTimeStart,
            ).format(H12_TIME_FORMAT)
          : null,
      actualTimeEnd:
        eventData?.actualTimeEnd && eventData?.actualTimeEnd !== 'NOT ENDED'
          ? convertUtcToTeamTimeZoneMomentObject(
              eventData?.actualTimeEnd,
            ).format(H12_TIME_FORMAT)
          : null,
    })) || [];

export const onlyCaregivers = (data) => {
  const transformedData = [];
  const uniqueCaregivers = new Set();
  data?.primaryTeams?.forEach((team) => {
    const teamId = team?.id;
    const teamName = team?.name;
    // const teamCaregivers = data.caregivers.filter(
    //   (caregiver) => caregiver?.caregiver?.teams[0]?.id === teamId,
    // );

    const primaryTeamCaregivers = data.caregivers.filter((caregiver) =>
      caregiver?.caregiver?.teams?.some((t) => t?.id === teamId),
    );

    const secondaryTeamCaregivers = data.caregivers.filter(
      (caregiver) =>
        caregiver?.secondaryTeams?.some((t) => t?.id === teamId) &&
        !caregiver?.caregiver?.teams?.some((t) => t?.id === teamId),
    );
    const allTeamCaregivers = [
      ...primaryTeamCaregivers,
      ...secondaryTeamCaregivers,
    ];
    allTeamCaregivers.forEach((caregiver) => {
      const orderValues = caregiver?.primaryTeams.map(
        (caregiverTeam) => caregiverTeam?.order,
      );
      const territoryOrder =
        caregiver?.primaryTeams[0]?.territory?.order ||
        caregiver?.secondaryTeams[0]?.territory?.order ||
        0;

      const minOrder = Math.min(...orderValues);
      const caregiverId = caregiver?.caregiver?.id;
      if (
        !uniqueCaregivers.has(caregiverId) &&
        caregiver.caregiver.status === 'Active'
      ) {
        uniqueCaregivers.add(caregiverId);
        const caregiversOrder = caregiver?.caregiverOrder;
        const violates30hoursAWeekRule =
          caregiver?.caregiver?.violates30hoursAWeekRule;
        const violates50PercentADayRule =
          caregiver?.caregiver?.violates50PercentADayRule;
        const visitCount =
          caregiver?.events?.filter((ev) => ev.title !== 'Lunch break')
            .length || 0;
        const scheduledMinutes = caregiver?.caregiver?.scheduledMinutes || 0;
        const utilizedMinutes = caregiver?.caregiver?.utilizedMinutes || 0;
        const utilizationRate = caregiver?.caregiver?.utilizationRate || 0;
        const utilizedMinutesWithTravelTime =
          caregiver?.caregiver?.utilizedMinutesWithTravelTime || 0;
        const utilizationRateWithTravelTime =
          caregiver?.caregiver?.utilizationRateWithTravelTime || 0;
        const expectedTravelTime = caregiver?.caregiver?.travelTimeMinutes || 0;
        const resourceId = caregiver?.caregiver?.resourceId || 0;
        const caregiverName = `${caregiver?.caregiver?.firstName} ${caregiver?.caregiver?.lastName}`;
        const availabilities = caregiver.caregiver.availabilities || [];

        const mergeAvailabilities = () => {
          availabilities.sort(
            (a, b) =>
              moment(a.startTime, 'h:mm A') - moment(b.startTime, 'h:mm A'),
          );

          const mergedAvailabilities = [];

          let currentStart = null;
          let currentEnd = null;

          // eslint-disable-next-line no-restricted-syntax
          for (const availability of availabilities) {
            const startTime = moment(availability.startTime, 'h:mm A');
            const endTime = moment(availability.endTime, 'h:mm A');

            if (currentEnd && startTime.isSameOrBefore(currentEnd)) {
              currentEnd = moment.max(currentEnd, endTime);
            } else {
              if (currentStart !== null) {
                mergedAvailabilities.push({
                  startTime: currentStart.format('h:mm A'),
                  endTime: currentEnd.format('h:mm A'),
                });
              }
              currentStart = startTime;
              currentEnd = endTime;
            }
          }

          if (currentStart !== null) {
            mergedAvailabilities.push({
              startTime: currentStart.format('h:mm A'),
              endTime: currentEnd.format('h:mm A'),
            });
          }

          return mergedAvailabilities;
        };
        const mergedAvailabilities = mergeAvailabilities(availabilities);
        const otherTeams = allTeamCaregivers
          ?.filter(
            (otherCaregiver) =>
              otherCaregiver?.caregiver?.id === caregiver?.caregiver?.id,
          )
          ?.map((otherCaregiver) => otherCaregiver?.secondaryTeams)
          ?.flat()
          ?.map((t) => t);
        const primaryTeams = allTeamCaregivers
          .filter(
            (otherCaregiver) =>
              otherCaregiver?.caregiver?.id === caregiver?.caregiver?.id,
          )
          ?.map((otherCaregiver) => otherCaregiver?.primaryTeams)
          ?.flat()
          ?.map((t) => t);
        const otherTeamsNames = otherTeams?.map((loc) => loc?.name);
        const primaryTeamsArray = primaryTeams?.map((loc) => loc?.name);
        const primaryAndSecondaryTeams = [
          ...otherTeamsNames,
          ...primaryTeamsArray,
        ];
        const allTeamsValue = [...otherTeams, ...primaryTeams];

        const violatedSoftConstraints =
          caregiver?.caregiver?.violatedSoftConstraints || [];
        const violatedHardConstraints =
          caregiver?.caregiver?.violatedHardConstraints || [];
        const parsedCaregiverData = {
          location: teamName,
          team: teamName,
          teamId,
          otherTeams,
          visitCount,
          scheduledMinutes,
          caregiversOrder,
          utilizedMinutes,
          utilizationRate,
          utilizedMinutesWithTravelTime,
          utilizationRateWithTravelTime,
          expectedTravelTime,
          resourceId,
          primaryAndSecondaryTeams,
          allTeamsValue,
          availabilities: mergedAvailabilities,
          minOrder,
          territoryOrder,
          violatedSoftConstraints,
          violatedHardConstraints,
          violates30hoursAWeekRule,
          violates50PercentADayRule,
          firstName: caregiver?.caregiver?.firstName,
          lastName: caregiver?.caregiver?.lastName,
          caregiver: {
            name: caregiverName,
            id: caregiver?.caregiver?.id,
          },
          teams: caregiver.caregiver.teams,
          region: {
            id: caregiver?.caregiver?.teams[0]?.id,
            name: caregiver?.caregiver?.teams[0]?.name,
          },
        };
        transformedData.push(parsedCaregiverData);
      }
    });
  });
  transformedData.sort((a, b) => {
    if (a.territoryOrder !== b.territoryOrder) {
      return a.territoryOrder - b.territoryOrder;
    }
    return a.minOrder - b.minOrder;
  });
  return transformedData;
};

export const eventsForTableView = (data) => {
  const eventsArray = [];
  data?.caregivers?.forEach((item) => {
    const { caregiver: parentCaregiver, events, primaryTeams } = item;
    if (events && events.length > 0) {
      events
        .filter(
          (eventData) =>
            !(
              parentCaregiver?.id === eventData?.shadowCaregiver?.id &&
              !!eventData?.shadowCaregiver
            ),
        )
        .forEach((event) => {
          const {
            actualDuration,
            actualTimeEnd,
            actualTimeStart,
            arrivalEnd,
            arrivalStart,
            arrivalTime,
            cancellationReason,
            careProgramTasks,
            caregiver,
            client,
            date,
            duration,
            externalId,
            id,
            preferredCaregiver,
            recurrence,
            roadTimeInMinutes,
            shadowCaregiver,
            shadowRoadTimeInMinutes,
            shadowStatus,
            status,
            team,
            violatedHardConstraints,
            violatedSoftConstraints,
            visitDetails,
            visitNotes,
            visitTasks,
            visitType,
          } = event;
          if (event.visitType === 'Lunch break') {
            return;
          }
          eventsArray.push({
            actualDuration,
            actualTimeEnd,
            actualTimeStart,
            arrivalEnd,
            arrivalStart,
            arrivalTime,
            cancellationReason,
            careProgramTasks,
            caregiver,
            client,
            date,
            duration,
            externalId,
            id,
            preferredCaregiver,
            primaryTeams,
            recurrence,
            region: team,
            roadTimeInMinutes,
            shadowCaregiver,
            shadowRoadTimeInMinutes,
            shadowStatus,
            status,
            violatedHardConstraints,
            violatedSoftConstraints,
            visitDetails,
            visitNotes,
            visitTasks,
            visitType,
          });
        });
    }
  });
  const notAllocatedEventsArray = data?.notAllocatedVisits || [];
  const notAllocatedEvents = notAllocatedEventsArray?.map((event) => {
    const {
      arrivalEnd,
      arrivalStart,
      arrivalTime,
      caregiver,
      client,
      date,
      duration,
      id,
      preferredGender,
      team,
      violatedHardConstraints,
      visitType,
    } = event;
    return {
      arrivalEnd,
      arrivalStart,
      arrivalTime,
      caregiver: caregiver || null,
      client,
      date,
      duration,
      id,
      preferredGender,
      region: team,
      status: 'Scheduled',
      violatedHardConstraints,
      visitType,
    };
  });
  const cancelledVisitsArray = data?.cancelledVisits || [];
  const cancelledEvents = cancelledVisitsArray?.map((event) => {
    const {
      arrivalEnd,
      arrivalStart,
      arrivalTime,
      caregiver,
      client,
      date,
      duration,
      id,
      preferredGender,
      status,
      team,
      violatedHardConstraints,
      visitType,
    } = event;
    return {
      arrivalEnd,
      arrivalStart,
      arrivalTime,
      caregiver: caregiver || null,
      client,
      date,
      duration,
      id,
      preferredGender,
      region: team,
      status,
      violatedHardConstraints,
      visitType,
    };
  });

  const concatCancelledAndNotAllocatedEvents =
    notAllocatedEvents?.concat(cancelledEvents);
  return eventsArray?.concat(
    concatCancelledAndNotAllocatedEvents.filter((a) =>
      eventsArray.every((b) => b.id !== a.id),
    ),
  );
};

export const teamsTooltipComponent = (teamsArray) => {
  const tooltipContent = () => (
    <div>
      {teamsArray?.slice(1)?.map((t, index) => (
        <div
          key={index}
          style={{
            marginBottom: index === teamsArray.length - 1 ? '0' : '12px',
          }}
        >
          <Typography variant="body1" style={{ marginLeft: '10px' }}>
            {t.name}
          </Typography>
        </div>
      ))}
    </div>
  );

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <Typography variant="body2" noWrap>
        {truncateName(teamsArray[0]?.name)}
      </Typography>
      {teamsArray?.length > 1 && (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                ...teamsTooltipStyles,
              },
            },
          }}
          title={tooltipContent(teamsArray)}
          placement="right-start"
        >
          <Typography
            sx={{
              fontSize: '11px',
              fontWeight: 600,
              color: COLORS.green[500],
            }}
            variant="body2"
          >
            +{teamsArray.length - 1}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

export const findFirstAndLastAvailability = (caregivers) => {
  const firstAndLast = {
    first: MIN_SCHEDULER_WORKING_HOUR,
    last: MAX_SCHEDULER_WORKING_HOUR,
  };

  let tempFirst = 23;
  let tempLast = 0;
  if (caregivers?.length > 0) {
    for (let caregiver in caregivers) {
      for (let availabilityDay in caregivers[caregiver].availabilities) {
          const startTime = moment(caregivers[caregiver].availabilities[availabilityDay].startTime, 'h:mm:ss');
          const stopTime = moment(caregivers[caregiver].availabilities[availabilityDay].endTime, 'h:mm:ss');

          if (startTime.hour() < tempFirst) {
            let firstHour = startTime.hour() - 1;
            if (firstHour < 0) {
              firstHour = 0;
            }
            firstAndLast.first = firstHour;
            tempFirst = firstHour;
          }

          if (stopTime.hour() > tempLast) {
            firstAndLast.last = stopTime.hour() + 1;
            tempLast = stopTime.hour() + 1 ;
          }
      }
    }
  }
  return firstAndLast;
}

export const findMinimumStartAvailability = (caregivers) => {
  if (caregivers?.length === 0) {
    return MIN_SCHEDULER_WORKING_HOUR;
  }

  const minMoment = caregivers
    ?.map((av) => av?.availabilities)
    ?.flat()
    ?.map((availability) => moment(availability?.startTime, 'h:mm A'));
  if (!minMoment?.length) {
    return MIN_SCHEDULER_WORKING_HOUR;
  }
  const minHour = minMoment?.reduce((min, current) =>
    current?.hour() < min?.hour() ? current : min,
  );

  return minHour?.hour() === 0 ? 1 : minHour.hour();
};
export const findMaximumEndAvailability = (caregivers) => {
  if (caregivers?.length === 0) {
    return MAX_SCHEDULER_WORKING_HOUR;
  }

  const maxMoment = caregivers
    ?.map((av) => av?.availabilities)
    ?.flat()
    ?.map((availability) => moment(availability?.endTime, 'h:mm A'));

  if (!maxMoment?.length) {
    return MAX_SCHEDULER_WORKING_HOUR;
  }
  const maxHour = maxMoment?.reduce((max, current) =>
    current?.hour() > max?.hour() ? current : max,
  );

  return maxHour?.hour();
};
