/* eslint-disable no-param-reassign */
import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetCaregiverHistoryQuery } from '../../../../../api/Caregivers/api';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  HISTORY_UPDATE_FORMAT
} from '../../../../../shared/constants';
import AlertContext from '../../../../../components/Alert';
import CustomTable from '../../../../../components/Table';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, historyColumns } from '../constants';

export default function CaregiverHistoryTable() {
  const apiRef = useGridApiRef();
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { data, isLoading, error } = useGetCaregiverHistoryQuery(id);

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const updatedEvents = data
  ?.map((item, index) => {
    return {
      ...item,
      dateChanged: moment(item.dateChanged).format(HISTORY_UPDATE_FORMAT),
      id: index + 1,
    };
  })
  .filter(Boolean);

  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateChanged);
    const dateB = new Date(b.dateChanged);
    return dateB - dateA;
  });
  
  return (
    <Box>
      <CustomTable
        apiRef={apiRef}
        columns={historyColumns}
        headerHeight={44}
        isLoading={isLoading}
        rows={sortedUpdatedEvents}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
