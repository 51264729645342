/* eslint-disable no-param-reassign */
import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetCaregiverTimeOffRequestsHistoryQuery } from '../../../../../../api/TimeOffRequest/api';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  HISTORY_UPDATE_FORMAT,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from '../../../../../../shared/constants';
import { getNoData } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomTable from '../../../../../../components/Table';
import { ptoHistoryColumns } from './constants';

export default function PtoHistory() {
  const apiRef = useGridApiRef();
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const {
    data,
    isLoading,
    error: getPTOHistoryError,
  } = useGetCaregiverTimeOffRequestsHistoryQuery(id);

  useEffect(() => {
    if (getPTOHistoryError) {
      setAlert({
        errorData: getPTOHistoryError,
        type: 'error',
      });
    }
  }, [getPTOHistoryError, setAlert]);
  
  const updatedEvents = data
  ?.map((item, index) => {
    return {
      ...item,
      dateChanged: moment(item.dateChanged).format(HISTORY_UPDATE_FORMAT),
      id: index + 1,
    };
  })
  .filter(Boolean);

  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateChanged);
    const dateB = new Date(b.dateChanged);
    return dateB - dateA;
  });

  return (
    <Box sx={{ mt: '10px' }}>
      <CustomTable
        apiRef={apiRef}
        columns={ptoHistoryColumns}
        headerHeight={44}
        isLoading={isLoading}
        rows={sortedUpdatedEvents}
        noRowsOverlay={() =>
          getNoData({ title: 'No PTO & Call Out History yet' })
        }
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
