import { Box } from '@mui/material';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CustomDatePicker } from '../../../../../components/CustomDateAndTimePickers';
import ErrorAlert from '../../../../../components/ErrorAlert';
import CustomCheckbox from '../../../../../components/VisitInfoTypeProvider/components/CreateTask/components/Checkbox/Checkbox';
import ContentWrapper from './Wrapper';

export default function SuspendView({setDate, date, setEndDate, endDate}) {
  const [endTimeDisabled, setEndTimeDisabled] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (endDate.isBefore(date) && !endTimeDisabled) {
      setError('End date should be after start date');
      setEndDate(moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    } else {
      setError('');
    }
    // eslint-disable-next-line
  }, [endDate, date, endTimeDisabled]);

  const onCheckboxChange = (e) => {
    setEndTimeDisabled(e.target.checked);
    if (e.target.checked) {
      setEndDate(moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  };
    

  return (
    <ContentWrapper>
      {error && <ErrorAlert error={error} />}
      <Box>
        <Box sx={{ display: 'flex', gap: '20px', mb: '10px' }}>
          <CustomDatePicker
            changeDate={(newValue) => setDate(newValue)}
            date={date}
            isDisablePast
            isDisabledToday
            label="Suspension Start Date"
          />
          <CustomDatePicker
            disabled={endTimeDisabled}
            changeDate={(newValue) => setEndDate(newValue)}
            date={endDate}
            isDisablePast
            isDisabledToday
            label="Suspension End Date"
          />
        </Box>
        <CustomCheckbox
          checked={endTimeDisabled}
          label="Continue without end date"
          onValueChange={onCheckboxChange}
        />
      </Box>
    </ContentWrapper>
  );
}

SuspendView.propTypes = {
  error: string,
  checkedEndTime: bool,
  setCheckedEndTime: func,
  setError: func,
  setStatusPeriodDates: func,
  statusPeriodDates: instanceOf(Object),
};
