import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { instanceOf } from 'prop-types';
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { COLORS } from '../../../../shared/styles/Theme';
import { convertUtcToTeamTimeZoneMomentObject } from '../../../../shared/utils/common';
import CustomDialog from '../../../../components/Dialog';
import ButtonsGroup from './ButtonsGroup';
import { SchedulerTimeOffRequestsContext } from './context';

export default function RequestsActions({ row = {} }) {
  const { setStatusChangingLoading } = useContext(
    SchedulerTimeOffRequestsContext,
  );
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    setStatusChangingLoading(isDataLoading);
  }, [isDataLoading, setStatusChangingLoading]);

  const cancelRequest = () => {
    setOpenRejectDialog(false);
    if (row.status?.toLowerCase() === 'approved') {
      // checkCancelingApprovedRequest(row.id);
    }
    if (row.status?.toLowerCase() === 'pending') {
      // cancelPendingRequest(row.id);
    }
  };
  const showContinueButton = useMemo(
    () =>
      row.status?.toLowerCase() === 'pending' &&
      moment().isBefore(
        convertUtcToTeamTimeZoneMomentObject(row.requestStart),
        'day',
      ),
    [row],
  );

  const checkOPtimizerTrigger = () => {

  }

  const showRejectButton = useMemo(
    () =>
      (row.status?.toLowerCase() === 'approved' &&
        moment().isBefore(
          convertUtcToTeamTimeZoneMomentObject(row.requestStart),
          'minutes',
        )) ||
      (row.status?.toLowerCase() === 'pending' &&
        moment().isAfter(
          convertUtcToTeamTimeZoneMomentObject(row.requestStart),
          'minutes',
        )),
    [row],
  );

  return (
    <Box>
      {/* TODO change this to the checkOptimizer component */}
      {/* <ChangeRequestStatusPossibility
        cancelChanges={() => setChangeRequestStatusPossibilityData(null)}
        changingStatusData={changeRequestStatusPossibilityData}
        confirmChanges={confirmRequestStatusChanges}
        caregiverData={row.caregiver}
      /> */}
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenRejectDialog(false)}
        submitButtonName="Confirm"
        submitCallback={cancelRequest}
        title="Confirmation"
        openDialog={openRejectDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            Time-Off Request will be cancelled. Do you want to proceed?
          </Typography>
        </Box>
      </CustomDialog>
      <ButtonsGroup
        checkApprovingPendingRequest={checkOPtimizerTrigger}
        openRejectingDialog={() => setOpenRejectDialog(true)}
        showContinueButton={showContinueButton}
        showRejectButton={showRejectButton}
      />
    </Box>
  );
}

RequestsActions.propTypes = {
  row: instanceOf(Object),
};
