import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { useCreateTerritoryIdMutation } from '../../../../../../api/Administration/api';
import { func, instanceOf, number, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import { isValidText } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomDialog from '../../../../../../components/Dialog';
import SearchFilter from '../../../../../../components/FiltersComponents/Search';
import AddTerritoryModal from '../AddTerritoryModal';

export default function AddTerritoryModalHeader({
  data = [],
  debouncedSearch = () => {},
  order = 0,
  setOrder = () => {},
  setTerritoryName = () => {},
  territoryName = '',
}) {
  const { setAlert } = useContext(AlertContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [createTerritoryById, { error }] = useCreateTerritoryIdMutation();

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  const createTerritory = () => {
    createTerritoryById({
      name: territoryName,
      order,
      type: "territory"
    });
    setOpenDialog(false);
    setTerritoryName('');
  };
  const cancelModal = () => {
    setTerritoryName('');
    setOpenDialog(false);
  };
  return (
    <Box
      sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          mr: '20px',
          maxHeight: '40px',
        }}
      >
        <SearchFilter
          changeValue={debouncedSearch}
          name="territory"
          placeholder="Territory"
        />
      </Box>
      <Button
        color="secondary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setOpenDialog(true)}
        disableElevation
        sx={{
          '&.MuiButton-root.Mui-disabled': {
            backgroundColor: 'transparent',
            color: COLORS.blue[500],
            opacity: 0.7,
          },
        }}
      >
        Territory
      </Button>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={cancelModal}
        submitButtonName="Save"
        disabledSubmit={
          territoryName?.trim()?.length === 0 || !isValidText(territoryName)
        }
        submitCallback={createTerritory}
        title="Add Territory"
        openDialog={openDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 20px 13px' }}>
          <AddTerritoryModal
            territoryName={territoryName}
            setTerritoryName={setTerritoryName}
            data={data}
            order={order}
            setOrder={setOrder}
          />
        </Box>
      </CustomDialog>
    </Box>
  );
}

AddTerritoryModalHeader.propTypes = {
  data: instanceOf(Array),
  debouncedSearch: func,
  order: number,
  setOrder: func,
  setTerritoryName: func,
  territoryName: string,
};
