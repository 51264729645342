import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';
import { parseTimeOffRequestsData } from '../../pages/CaregiverDetails/view/PtoAndCallouts/utils';

export const TIME_OFF_REQUEST_API_KEY = 'timeOffRequestApi';

const timeOffRequestApi = createApi({
  reducerPath: TIME_OFF_REQUEST_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: (builder) => ({
    getCaregiverTimeOffRequests: builder.query({
      query: (caregiverId) => ({
        url: `crud/paidTimeOff`,
        method: 'GET',
        params: {
          index: "caregiverId-index",
          pk: "caregiverId",
          pkValue: caregiverId,
          sk: "active",
          skValue: "True"
         },
      }),
      transformResponse: (response) => parseTimeOffRequestsData(response),
      providesTags: () => [{ type: 'timeOffRequests', id: 'ALL' }],
    }),
    createTimeOffRequest: builder.mutation({
      query: ( payload ) => ({
        url: `crud/paidTimeOff`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: () => [{ type: 'timeOffRequests', id: 'ALL' }],
    }),
    deleteTimeOffRequest: builder.mutation({
      query: (payload) => ({
        url: 'crud/paidTimeOff',
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: () => [
        { type: 'timeOffRequests', id: 'ALL' },
        { type: 'allTimeOffRequests', id: 'ALL' },
      ],
    }),
    updateTimeOffRequest: builder.mutation({
      query: (payload) => ({
        url: `crud/paidTimeOff`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: () => [
        { type: 'timeOffRequests', id: 'ALL' },
        { type: 'allTimeOffRequests', id: 'ALL' },
      ],
    }),
    getCaregiverTimeOffRequestsHistory: builder.query({
      query: (caregiverId) => ({
        url: `history/paidTimeOff`,
        method: 'GET',
        params: {
          index: "caregiverId-index",
          pk: "caregiverId",
          pkValue: caregiverId,
          },
      }),
      invalidatesTags: () => [
        { type: 'timeOffRequests', id: 'ALL' },
        { type: 'allTimeOffRequests', id: 'ALL' },
      ],
    }),
  }),
});

export const {
  useGetCaregiverTimeOffRequestsQuery,
  useCreateTimeOffRequestMutation,
  useUpdateTimeOffRequestMutation,
  useDeleteTimeOffRequestMutation,
  useGetCaregiverTimeOffRequestsHistoryQuery,
} = timeOffRequestApi;

export default timeOffRequestApi;
