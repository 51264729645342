import { configureStore } from '@reduxjs/toolkit';
import { ADMINISTRATION_API_KEY, administrationApi } from '../api/Administration';
import {
  CAREGIVERS_AVAILABILITY_API_KEY,
  caregiversAvailabilityApi,
} from '../api/Availability';
import { CARE_PLAN_API_KEY, carePlanApi } from '../api/CarePlan';
import {
  CARE_PROGRAM_REAL_API_KEY,
  careProgramRealApi,
} from '../api/CareProgram';
import { CAREGIVERS_API_KEY, caregiversApi } from '../api/Caregivers';
import { CLIENTS_API_KEY, clientsApi } from '../api/Clients';
import { REPORTING_API_KEY, reportingApi } from '../api/Reporting';
import { SCHEDULER_API_KEY, schedulerApi } from '../api/Scheduler';
import {
  CONFIGS_REAL_API_KEY,
  configRealApi,
} from '../api/Config';
import {
  TIME_OFF_REQUEST_API_KEY,
  timeOffRequestApi,
} from '../api/TimeOffRequest';
import { userDataApi } from '../api/UserData';
import { USER_DATA_API_KEY } from '../api/UserData/api';
import { VISITS_API_KEY, visitsApi } from '../api/Visits';
import { COMMON_API_KEY, commonApi } from '../api/commonApi';

const store = configureStore({
  reducer: {
    [CAREGIVERS_AVAILABILITY_API_KEY]: caregiversAvailabilityApi.reducer,
    [CAREGIVERS_API_KEY]: caregiversApi.reducer,
    [CLIENTS_API_KEY]: clientsApi.reducer,
    [COMMON_API_KEY]: commonApi.reducer,
    [CARE_PLAN_API_KEY]: carePlanApi.reducer,
    [CARE_PROGRAM_REAL_API_KEY]: careProgramRealApi.reducer,
    [VISITS_API_KEY]: visitsApi.reducer,
    [TIME_OFF_REQUEST_API_KEY]: timeOffRequestApi.reducer,
    [USER_DATA_API_KEY]: userDataApi.reducer,
    [SCHEDULER_API_KEY]: schedulerApi.reducer,
    [ADMINISTRATION_API_KEY]: administrationApi.reducer,
    [REPORTING_API_KEY]: reportingApi.reducer,
    [CONFIGS_REAL_API_KEY]: configRealApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      administrationApi.middleware,
      carePlanApi.middleware,
      careProgramRealApi.middleware,
      caregiversApi.middleware,
      caregiversAvailabilityApi.middleware,
      clientsApi.middleware,
      commonApi.middleware,
      reportingApi.middleware,
      schedulerApi.middleware,
      timeOffRequestApi.middleware,
      userDataApi.middleware,
      visitsApi.middleware,
      configRealApi.middleware,
    ]),
});
export default store;
