import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import { bool, instanceOf } from 'prop-types';
import React, { useContext } from 'react';
import { backgroundColorSchemeMap, DATE_ONLY_STANDARD_FORMAT } from '../../../../../shared/constants';
import Badge from '../../../../../components/Badge';

export default function SecondaryRegionsInfo({
  isPrimary = false,
  value = [],
}) {
  const emptyValuePlaceholder = isPrimary ? 'No primary team' : '';
  const { profileData } = useContext(CaregiverProfileContext);
  return (
    <Box sx={{ display: 'flex', maxWidth: '800px', flexWrap: 'wrap', flexDirection: "column"}}>
      {value?.length ? (
        value?.map((regions, index) => {
          const key = `${regions?.name}${regions?.id}-${index}`;
          return (
            <Box key={key}>
              {!profileData.secondaryChanged ? (
                <Typography
                  key={key}
                  sx={{ marginRight: '2px' }}
                  variant="body1"
                >{`${regions?.name}${
                  index !== value.length - 1 ? ',' : ''
                }`}</Typography>
              ) : (
                <Box key={regions?.id} sx={{ mr: '6px' }}>
                  <Badge
                    colorScheme={backgroundColorSchemeMap.error}
                    label={regions?.name}
                  />
                </Box>
              )}
            </Box>
          );
        })
      ) : (
        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
          {emptyValuePlaceholder}
        </Typography>
      )}
      {profileData?.pendingChanges?.secondaryTeams && (
        <Box sx={{ display: 'flex', gap: 1, flexDirection: "column", marginTop: "10px"}}>
          {Object.keys(profileData?.pendingChanges?.secondaryTeams).map(
            (date) => (
              <Box sx={{ display: 'flex', gap: 1, flexDirection: "row"}} key={date}>
                <Typography variant="body1">{moment(date).format(DATE_ONLY_STANDARD_FORMAT)}:</Typography>
                {profileData?.pendingChanges?.secondaryTeams[date].map(
                  (teamId, index) => (
                    <Typography
                      key={teamId}
                      sx={{ marginRight: '2px' }}
                      variant="body1"
                    >
                      {profileData?.pendingChanges.secondaryTeamsDetails[date][index]?.name}
                    </Typography>
                  )
                )}
              </Box>
            )
          )}
        </Box>
      )}
    </Box>
  );
}

SecondaryRegionsInfo.propTypes = {
  isPrimary: bool,
  value: instanceOf(Array),
};
