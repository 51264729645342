import React, { useEffect, createContext, useRef} from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

export const WebSocketContext = createContext();

const WebSocketProvider = ({children}) => {
    const channels = useRef({})
    const subscribe = (channel, callback) => {
        channels.current[channel] = callback
    }
    const unsubscribe = (channel) => {
        delete channels.current[channel]
    }
    const accessToken = localStorage.getItem('accessToken');
    const socketUrl = process.env.REACT_APP_WEBSOCKET_URL;
    const webSocketOptions = {
        queryParams: {"Authorization": accessToken },
        onOpen: () => {
          
        },
        onClose: () => {
        },
        onMessage(event) {
        },
        onError(event) {
        
        },
        retryOnError: false,
        reconnectAttempts: 1,
        shouldReconnect: () => true,
    }
    const { lastMessage, readyState } = useWebSocket(socketUrl, webSocketOptions);
    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            console.log('WebSocket is open');
        } else if (readyState === ReadyState.CLOSING) {
            console.log('WebSocket is closing');
        } else if (readyState === ReadyState.CLOSED) {
            console.log('WebSocket is closed');
        } else if (readyState === ReadyState.CONNECTING) {
            console.log('WebSocket is connecting');
        } else if (readyState === ReadyState.UNINSTANTIATED) {
            console.log('WebSocket is uninstantiated');
        } else {
            console.log('WebSocket is not connected');
        }
    }, [readyState]);

    useEffect(() => {
        if (lastMessage) {
            const messageData = JSON.parse(lastMessage.data);
            const command = messageData.command
            let channel = "optimizer"
            if (command.includes("visit")) {
                channel = "visits"
            }
            if (channels.current[channel]){
                channels.current[channel](messageData)
            }
        }
    }
    , [lastMessage]);

    return (
        <WebSocketContext.Provider value={[subscribe, unsubscribe]}>
            {children}
        </WebSocketContext.Provider>
    )
}
export default WebSocketProvider;