import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { bool, func, instanceOf, string } from 'prop-types';
import * as React from 'react';
import { checkIsDayDisabled } from '../../shared/utils/common';
import CounterBadge from '../../components/CounterBadge';
import CustomBadges from '../../components/CustomBadges';
import {
  customSelectFormControlStyles,
  customSelectItem,
  customSelectPaperProps,
  customSelectStyles,
} from './styles';

export default function TaskItemMultiselect({
  disabled = false,
  disabledDays = {},
  id = '',
  options = [],
  placeHolder = '',
  selectedValue = [],
  setValue = () => {},
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
  
    if (value[value.length - 1] === 'all') {
      const filteredWeekendDays = options?.filter(
        (option) =>
          !(
            (option.name === 'Saturday' && !disabledDays?.saturdaySwitchedOn) ||
            (option.name === 'Sunday' && !disabledDays?.sundaySwitchedOn)
          ),
      );
      const chosenOptions = id === 'days' ? filteredWeekendDays : options;
  
      // Set selectedValue to an array of option 'id's, not full objects
      setValue({
        field: id,
        value:
          selectedValue?.length === chosenOptions?.length
            ? []
            : chosenOptions.map(option => option.id),  // Only keep 'id's
      });
      return '';
    }
  
    // Handle normal selection (ensure only 'id's are in the selectedValue array)
    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((optionId) => optionId === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
  
    setValue({ field: id, value: duplicateRemoved });
    return '';
  };

  const onDeleteItem = (value) =>
    setValue({
      field: id,
      value: selectedValue.filter((item) => item !== value),
    });

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <FormControl sx={{ ...customSelectFormControlStyles, width: '100%' }}>
        <Select
          className={
            options?.length === selectedValue?.length ? 'has-items' : ''
          }
          disabled={disabled}
          IconComponent={ExpandMoreIcon}
          displayEmpty
          multiple
          onChange={handleChange}
          renderValue={(selected) => {
            return (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {placeHolder}
                {selectedValue?.length > 0 && (
                  <CounterBadge
                    label={
                      selectedValue?.length === options?.length
                        ? 'ALL'
                        : selectedValue.length.toString()
                    }
                  />
                )}
              </Box>
            );
          }}          
          MenuProps={{
            disableAutoFocusItem: true,
            PaperProps: {
              sx: customSelectPaperProps,
            },
          }}
          sx={customSelectStyles}
          value={selectedValue}
        >
          <MenuItem
            name="all"
            value="all"
            disableRipple
            className="select-all-item"
            sx={{
              ...customSelectItem,
            }}
          >
            <Checkbox
              checked={
                options?.length > 0 && options?.length === selectedValue?.length
              }
            />
            All
          </MenuItem>
          {options?.map((option, index) => (
            <MenuItem
              key={index}
              value={option.id}
              disableRipple
              disabled={id === 'days' && checkIsDayDisabled(option.name, disabledDays)}
              sx={{
                ...customSelectItem,
              }}
            >
              <Checkbox
                checked={selectedValue?.includes(option.id)}
                sx={{ padding: '5px 9px' }}
              />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Collapse
        in={
          !!selectedValue?.length && options?.length !== selectedValue?.length
        }
      >
        <CustomBadges
          disabled={disabled}
          items={
            selectedValue?.map((skill) => {
              const foundOption = options.find(option => option.id === skill);
              return {
                name: foundOption ? foundOption.name : skill, 
                value: skill,
              };
            }) || []
          }
          onDelete={onDeleteItem}
        />
      </Collapse>
    </Box>
  );
}

TaskItemMultiselect.propTypes = {
  disabled: bool,
  disabledDays: instanceOf(Object),
  id: string,
  options: instanceOf(Array),
  placeHolder: string,
  selectedValue: instanceOf(Array),
  setValue: func,
};

