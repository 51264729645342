import { Box } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import {
  SEARCH_INPUT_DEBOUNCE_DELAY,
  WORKING_WEEK_LENGTH,
  WEEK_DAYS
} from '../../../../../../shared/constants';
import { useDebouncedCallback } from '../../../../../../shared/hooks/useDebounce';
import SearchFilter from '../../../../../../components/FiltersComponents/Search';
import SelectWithCounter from '../../../../../../components/FiltersComponents/Select';

export default function FiltersWrapper({
  carePlanFilters = {},
  isLoading = false,
  setCarePlanFilters = () => {},
  configData = {},
}) {
  const changeSelectFilters = ({field, value}) =>{
      let valueList = [];
      if (field === 'days') {
        valueList = value.map((val) => ({id: val, name: WEEK_DAYS.find((day) => day.id === val).name}));
      } else if (Array.isArray(value) && value.every(item => item.name && item.id)) {
        valueList = value.map((val) => ({id: val.id, name: configData[field].find((option) => option.id === val.id).name}));
      } else {
        valueList = value.map((val) => ({id: val, name: configData[field].find((option) => option.id === val).name}));
      }
      setCarePlanFilters({ ...carePlanFilters, [field]: valueList }); 
  }

  const debouncedSearch = useDebouncedCallback((e) => {
    if (!e.target.value.length || e.target.value.length > 2) {
      setCarePlanFilters({
        ...carePlanFilters,
        [e.target.name]: e.target.value,
      });
    }
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  if (!configData) {
    return false;
  }
  return (
    <Box sx={{ display: 'flex', mb: '25px', gap: '20px' }}>
      <SearchFilter
        changeValue={debouncedSearch}
        name="task"
        placeholder="Task"
      />
      <SelectWithCounter
        filterItems={carePlanFilters.categoryTypes}
        id="categoryTypes"
        isLoading={isLoading}
        isMultiselect
        options={configData?.categoryTypes?.map((cat) => ({ id: cat.id, name: cat.name }))} 
        placeHolder="Category"
        setFilters={changeSelectFilters}
        showFiltersCount
      />
      <SelectWithCounter
        filterItems={carePlanFilters.levelsOfAssistance}
        id="levelsOfAssistance"
        isLoading={isLoading}
        isMultiselect
        options={configData?.levelsOfAssistance?.map((lev) => ({ id: lev.id, name: lev.name }))}
        placeHolder="Level Of Assistance"
        setFilters={changeSelectFilters}
        showFiltersCount
      />
      <SelectWithCounter
        filterItems={carePlanFilters.days}
        id="days"
        isLoading={isLoading}
        isMultiselect
        options={WEEK_DAYS
          .map((day) => ({ id: day.id, name: day.name }))
          .slice(0, WORKING_WEEK_LENGTH)}
        placeHolder="Day"
        setFilters={changeSelectFilters}
        showFiltersCount
      />
      <SelectWithCounter
        filterItems={carePlanFilters.timeOfDays}
        id="timeOfDays"
        isLoading={isLoading}
        isMultiselect
        options={configData?.timeOfDays.map((time) => ({ id: time.id, name: time.name }))}
        placeHolder="Time of Day"
        setFilters={changeSelectFilters}
        showFiltersCount
      />
      <SelectWithCounter
        filterItems={carePlanFilters.visitTypes}
        id="visitTypes"
        isLoading={isLoading}
        isMultiselect
        hasSelectAllOption
        options={configData?.visitTypes?.map((vis) => ({ id: vis.id, name: vis.name }))}
        placeHolder="Visit Type"
        setFilters={changeSelectFilters}
        showFiltersCount
      />
    </Box>
  );
}

FiltersWrapper.propTypes = {
  carePlanFilters: instanceOf(Object),
  isLoading: bool,
  setCarePlanFilters: func,
};
