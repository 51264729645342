import { EventType, PublicClientApplication } from '@azure/msal-browser';

export const config = {
  msal: {
    auth: {
      clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
      redirectUri: `${process.env.REACT_APP_BASE_URL}scheduling/calendar`,
      postLogoutRedirectUri: `${process.env.REACT_APP_BASE_URL}`,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  },
};

export const pcaInstance = new PublicClientApplication(config.msal);

pcaInstance.addEventCallback((event) => {
  if ([EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS].includes(event.eventType)) {
    localStorage.setItem('accessToken', event.payload.accessToken);
    const userData = JSON.stringify({ "id": event.payload.account.localAccountId, "email": event.payload.account.username })
    localStorage.setItem('user', userData);
  }
});
