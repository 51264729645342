import { Box } from '@mui/material';
import moment from 'moment';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext, useState } from 'react';
import TeamEffectiveDateModal from '../TeamEffectiveDateModal';
import TeamsSelect from '../TeamsSelect';

export default function PrimaryRegionsSelector() {
  const {
    editingProfileDataLibrary,
    profileData,
    teamDate,
    setProfileData,
  } = useContext(CaregiverProfileContext);
  const [optionIsOpen, setOptionIsOpen] = useState(false);
  const [date, setDate] = useState(moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [pendingChanges, setPendingChanges] = useState({});
  const [teamEffectiveDateOpen, setTeamEffectiveDateOpen] = useState(false);

  const changeProfileData = () => {
    setProfileData({...pendingChanges});
  }

  
  const onSelectChange = (newDetails, newIds) => {
    const primaryTeams = {...profileData?.pendingChanges?.primaryTeams} || {};
    primaryTeams[moment(date).format('YYYY-MM-DD')] = newIds;
    const primaryTeamsDetails = {...profileData?.pendingChange?.primaryTeamsDetails} || {};
    primaryTeamsDetails[moment(date).format('YYYY-MM-DD')] = newDetails;
    const pendingChanges = {
      ...profileData?.pendingChanges,
      primaryTeams: primaryTeams,
      primaryTeamsDetails: primaryTeamsDetails,
    };
    const newProfileData = { ...profileData, pendingChanges: pendingChanges };
    setPendingChanges({...newProfileData});
    setTeamEffectiveDateOpen(true);
  };

  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <TeamsSelect
        id="primaryTeams"
        options={editingProfileDataLibrary?.teams}
        placeHolder="Primary teams"
        selectedValue={profileData?.primaryTeamsDetails}
        setValue={onSelectChange}
        isOpen={optionIsOpen}
        setIsOpen={setOptionIsOpen}
      />
      <TeamEffectiveDateModal
        closeEffectiveDateDialog={setTeamEffectiveDateOpen}
        openEffectiveDateDialog={teamEffectiveDateOpen && profileData?.status === 'Active'}
        submitEffectiveDateDialog={changeProfileData}
        date={teamDate}
        selectDate={setDate}
      />
    </Box>
  );
}
