import { Typography } from '@mui/material';
import React from 'react';
import AvatarGroups from '../../../../components/AvatarGroup';
import BlockedCaregiversSelector from './BlockedCaregiversSelector';
import StatusSelector from './StatusSelector';
import TeamSelector from './TeamSelector';

export const getInfoComponent = ({ type, value, editMode }) => {
  if (type === 'badge') {
    return <StatusSelector />;
  }
  if (type === 'text') {
    return <Typography variant="body1">{value || ''}</Typography>;
  }
  if (type === 'team') {
    return editMode ? (
      <TeamSelector />
    ) : (
      <Typography
        variant="body1"
        sx={{ fontStyle: value.name ? 'normal' : 'italic' }}
      >
        {value.name || 'No team'}
      </Typography>
    );
  }
  if (type === 'blockedCaregivers') {
    const fullNameArray = Array.isArray(value)
    ? value?.map((CaregiverName) => `${CaregiverName}`)
    : null;
    return editMode ? (
      <BlockedCaregiversSelector />
    ) : (
      <AvatarGroups showTooltip maxLength={5} users={fullNameArray} />
    );
  }
  return false;
};
