import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import CustomDialog from '../../../../../components/Dialog';
import SuspendView from './SuspendView';

function ClientSuspendDialog({
  openSuspendModal = false,
  closeSuspendModal = () => {},
  confirmSuspend = () => {},
  date = {},
  setDate = () => {},
  endDate = {},
  setEndDate = () => {},
}) {
  return (
    <CustomDialog
      cancelButtonName="Cancel"
      cancelCallback={closeSuspendModal}
      submitButtonName="Confirm"
      submitCallback={confirmSuspend}
      title="Suspend Confirmation"
      openDialog={openSuspendModal}
    >
      <SuspendView setDate={setDate} date={date} setEndDate={setEndDate} endDate={endDate}/>
    </CustomDialog>
  );
}

ClientSuspendDialog.propTypes = {
  openDeactivationModal: bool,
  closeDeactivationModal: func,
  confirmDeactivation: func,
  date: instanceOf(Object),
};

export default ClientSuspendDialog;
