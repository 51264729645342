import { Box } from '@mui/material';
import { useGetWeekendsSchedulingDaysQuery } from '../../../../../../../../../../api/Administration/api';
import { useCheckCareProgramAvailabiltyQuery } from '../../../../../../../../../../api/CareProgram/api';
import { useGetConfigQuery } from '../../../../../../../../../../api/Config/api';
import AvailabilityLoader from '../../../../../../../../../../pages/ClientDetails/components/AvailabilityLoader';
import AvailabilityWindowsBody from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/RecurrentAvailability/AvailabilityWindowsBody';
import CheckAvailabilitySchedulePattern from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/RecurrentAvailability/CheckAvailabilitySchedulePattern';
import {
  getRecurrentAvailabilityContentWrapperStyles,
  recurrentAvailabilityWrapper,
} from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/RecurrentAvailability/styles';
import { func, instanceOf, number, string } from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import AlertContext from '../../../../../../../../../../components/Alert';
import { checkAvailabilityWrapperStyles } from '../styles';
import ConstantTableItem from './ArrivalWindowsTableItmes/ConstantTableItem';
import { convertDaysToNames } from '../../../../../../constants';

function RecurrentAvailability({
  availabilityScheduling,
  clearSelectedTimeSlots = () => {},
  errorData = '',
  setAvailabilityScheduling = () => {},
  setErrorData = () => {},
  setStepDataLoading = () => {},
  setTemporaryVisitData = () => {},
  setVisitData = () => {},
  temporaryVisitData = {},
  updatedCareProgram = [],
  visitData = {},
  visitSummaryHeight = 0,
  effectiveDate = '',
}) {
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  const {
    data: availabilityData,
    isLoading,
    isFetching,
    error: checkVisitAvailabilityError,
  } = useCheckCareProgramAvailabiltyQuery(
    {
      clientId: id,
      arrivalWindowDuration: visitData?.arrivalWindowDuration,
      days: Object.keys(temporaryVisitData.days).map((day) => parseInt(day)),
      timesOfDays: visitData.timesOfDaysDetails,
      duration: visitData.duration,
      recurrence: visitData.recurrence,
      effectiveDate: effectiveDate,
      action: 'availability',
    }
  );
  const {
    data: filtersData,
    isLoading: isFiltersLoading,
    error: getFiltersDataError,
  } = useGetConfigQuery(['timeOfDays']);
  const { data: disabledWeekDays, error: getWeekdaysError } =
    useGetWeekendsSchedulingDaysQuery();

  useEffect(() => {
    if (
      checkVisitAvailabilityError ||
      getFiltersDataError ||
      getWeekdaysError
    ) {
      const errorRequestData =
        checkVisitAvailabilityError || getFiltersDataError || getWeekdaysError;
      setAlert({
        errorData: errorRequestData,
        type: 'error',
      });
    }
  }, [
    checkVisitAvailabilityError,
    getFiltersDataError,
    getWeekdaysError,
    setAlert,
  ]);

  useEffect(() => {
    if (availabilityData) {
      setAvailabilityScheduling(availabilityData);
    }
  }, [setAvailabilityScheduling, availabilityData]);

  useEffect(() => {
    if (!availabilityScheduling) {
      setAvailabilityScheduling(availabilityData);
    }
  }, [availabilityData, availabilityScheduling, setAvailabilityScheduling]);

  useEffect(() => {
    setStepDataLoading(isLoading);
  }, [isLoading, setStepDataLoading]);

  const handleChangeDays = (e) => {
    if (errorData) {
      setErrorData(null);
    }
    const name = e.target.name;
    let tempDays = {...temporaryVisitData.days};
    const keys = Object.keys(tempDays);
    if (keys.includes(String(name))) {
      delete tempDays[name];
    } else {
      tempDays[name] = {};
    }
    setTemporaryVisitData({
      ...temporaryVisitData,
      days: tempDays,
    });
    return false;
  };

  const handleChangeTimeOfDay = (e) => {
    if (errorData) {
      setErrorData(null);
    }
    const { name } = e.target;
    let timesOfDaysDetails = [...temporaryVisitData.timesOfDaysDetails]
    const dayNames = timesOfDaysDetails.map(({ name }) => name);
    if (dayNames.includes(name)) {
      timesOfDaysDetails = timesOfDaysDetails.filter((item) => item.name !== name);
    } else {
      timesOfDaysDetails.push(filtersData.timeOfDays.find((item) => item.name === name));
    }
    setTemporaryVisitData({
      ...temporaryVisitData,
      timesOfDays: timesOfDaysDetails.map(({ id }) => id),
      timesOfDaysDetails: timesOfDaysDetails,
    });
    return false;
  };

  const recheckAvailability = () => {
    setVisitData(temporaryVisitData);
    setAvailabilityScheduling(null);
    clearSelectedTimeSlots();
  };

  const dayNames = useMemo(
    () => Object.keys(visitData?.days).map((day) => convertDaysToNames[day]),
    [visitData.days],
  );

  const tableHeaderItems = useMemo(
    () => ['Arrival Window', ...dayNames],
    [dayNames],
  );

  const tableContentStyles = useMemo(
    () => getRecurrentAvailabilityContentWrapperStyles(visitSummaryHeight),
    [visitSummaryHeight],
  );
  return (
    <Box sx={checkAvailabilityWrapperStyles}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CheckAvailabilitySchedulePattern
          disabledRecheckAvailability={
            !Object.keys(temporaryVisitData?.days)?.length ||
            !temporaryVisitData?.timesOfDays?.length
          }
          filtersData={filtersData}
          disabledDays={disabledWeekDays?.schema}
          handleChangeDays={handleChangeDays}
          handleChangeTimeOfDay={handleChangeTimeOfDay}
          isFiltersLoading={isFiltersLoading}
          isLoading={isLoading || isFetching}
          recheckAvailability={recheckAvailability}
          visitData={temporaryVisitData}
        />
        {isLoading || isFetching ? (
          <AvailabilityLoader />
        ) : (
          <Box sx={recurrentAvailabilityWrapper}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              {tableHeaderItems.map((item, index) => (
                <ConstantTableItem
                  key={item}
                  isArrivalWindowItem={!index}
                  isHeader
                  name={item}
                  showDivider={!!index && tableHeaderItems.length - 1 !== index}
                />
              ))}
            </Box>
            <Box sx={tableContentStyles}>
              {availabilityScheduling?.timeOfDays && (
                <AvailabilityWindowsBody />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

RecurrentAvailability.propTypes = {
  availabilityScheduling: instanceOf(Object),
  clearSelectedTimeSlots: func,
  errorData: string,
  setAvailabilityScheduling: func,
  setErrorData: func,
  setStepDataLoading: func,
  setTemporaryVisitData: func,
  setVisitData: func,
  temporaryVisitData: instanceOf(Object),
  updatedCareProgram: instanceOf(Object),
  visitData: instanceOf(Object),
  visitSummaryHeight: number,
  effectiveDate: string,
};

export default RecurrentAvailability;
