import { Box, Typography } from '@mui/material';
import React from 'react';
import UserTableActions from './components/TableActions';
import HistoryBulletList from '../../../../components/HistoryBulletList'

export const DEFAULT_SORTING_MODEL = { field: 'email', sort: 'asc' };
export const userListColumns = [
  {
    field: 'email',
    headerName: 'User',
    flex: 1,
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
    renderCell: ({ row }) => <UserTableActions row={row} />,
  },
];

export const addIdsToUserList = (data) =>
  data?.map((users, index) => ({
    ...users,
    id: index + 1,
  }));
export const usersHistoryColumns = [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 280,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.dateChanged}</Typography>
      </Box>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 740,
    sortable: false,
    renderCell: (params) => <HistoryBulletList changes={params.row} />,
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    minWidth: 330,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.updatedBy}</Typography>
      </Box>
    ),
  },
];
