import { Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import arrivalWhiteIcon from '../../../../img/icons/arrival-window-visit-white.svg';
import arrivalIcon from '../../../../img/icons/arrival-window-visit.svg';
import { LOCAL_STORAGE_KEYS } from '../../../../pages/Scheduling/constants';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { visitStatusesMap } from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import { getStatusComponent } from '../../../../shared/utils/common';

export default function EventCardInfo({
  arrivalStart = {},
  arrivalEnd = {},
  checkIfShowArrival = false,
  eventData = {},
}) {
  const isOnSiteStatus = eventData.status === visitStatusesMap.onSite;
  const isVerified = eventData.status === visitStatusesMap.verified;
  const savedStatus = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.oldStatus),
  );
  const calculateStatus = () => {
    if (eventData.isShadowVisit && eventData.id === savedStatus?.uid) {
      return savedStatus?.values?.shadowStatus || eventData?.shadowStatus;
    }
    if (!eventData.status && eventData.id === savedStatus?.uid) {
      return savedStatus?.values?.status || eventData?.status;
    }
    return eventData.isShadowVisit ? eventData.shadowStatus : eventData.status;
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Link
        component={RouterLink}
        to={`/clients/${eventData?.clientId}`}
        underline="none"
      >
        <Typography
          variant="body1"
          sx={{
            color:
              !eventData.isShadowVisit && (isOnSiteStatus || isVerified)
                ? '#ffffff'
                : COLORS.blue[700],
            fontWeight: '600',
            fontSize: '14px',
          }}
        >
          {eventData?.clientName}
        </Typography>
      </Link>
      {eventData.arrivalTimeDuration !== 0 && checkIfShowArrival && (
        <Box sx={{ display: 'flex' }}>
          <img
            src={
              !eventData.isShadowVisit && isOnSiteStatus
                ? arrivalWhiteIcon
                : arrivalIcon
            }
            alt="arrivalIcon"
          />
          <Typography
            sx={{
              ml: '6px',
              color:
                !eventData.isShadowVisit && isOnSiteStatus
                  ? '#ffffff'
                  : COLORS.blue[700],
            }}
            variant="body2"
          >
            {arrivalStart.format('h:mm A')} -
            {arrivalEnd.format('h:mm A')}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          marginTop: '4px',
        }}
      >
        {getStatusComponent(calculateStatus())}
      </Box>
    </Box>
  );
}

EventCardInfo.propTypes = {
  arrivalStart: instanceOf(Object),
  arrivalEnd: instanceOf(Object),
  checkIfShowArrival: bool,
  eventData: instanceOf(Object),
};
