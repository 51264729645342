import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import { bool, instanceOf } from 'prop-types';
import React, { useContext } from 'react';
import { backgroundColorSchemeMap, DATE_ONLY_STANDARD_FORMAT } from '../../../../../shared/constants';
import Badge from '../../../../../components/Badge';

export default function PrimaryAndSecondaryRegionsInfo({
  isPrimary = false,
  value = [],
}) {
  const emptyValuePlaceholder = isPrimary ? 'No primary team' : '';
  const { profileData } = useContext(CaregiverProfileContext);

  return (
    <Box sx={{ display: 'flex', maxWidth: '600px', flexWrap: 'wrap', gap: 1, flexDirection: "column"}}>
      <Box>
        {value?.length ? (
          value?.map((regions, index) => {
            const key = `${regions?.name}${regions?.id}-${index}`;
            return (
              <Box key={key}>
                {!profileData.primaryChanged ? (
                  <Typography
                    key={key}
                    sx={{ marginRight: '2px' }}
                    variant="body1"
                  >
                    {regions?.name &&
                      `${regions?.name}${
                        index !== value.length - 1 ? ',' : ''
                      }`}
                  </Typography>
                ) : (
                  <Box>
                    <Badge
                      colorScheme={backgroundColorSchemeMap.error}
                      label={regions?.name}
                    />
                  </Box>
                )}
              </Box>
            );
          })
        ) : (
          <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
            {emptyValuePlaceholder}
          </Typography>
        )}
      </Box>
      {profileData?.pendingChanges?.primaryTeams && (
        <Box sx={{ display: 'flex', gap: 1, flexDirection: "column", marginTop: "10px"}}>
          {Object.keys(profileData?.pendingChanges?.primaryTeams).map(
            (date) => (
              <Box sx={{ display: 'flex', gap: 1, flexDirection: "row"}} key={date}>
                <Typography variant="body1">{moment(date).format(DATE_ONLY_STANDARD_FORMAT)}:</Typography>
                {profileData?.pendingChanges?.primaryTeams[date].map(
                  (teamId, index) => (
                    <Typography
                      key={teamId}
                      sx={{ marginRight: '2px' }}
                      variant="body1"
                    >
                      {profileData?.pendingChanges.primaryTeamsDetails[date][index]?.name}
                    </Typography>
                  )
                )}
              </Box>
            )
          )}
        </Box>
      )}
    </Box>
  );
}

PrimaryAndSecondaryRegionsInfo.propTypes = {
  isPrimary: bool,
  value: instanceOf(Array),
};
