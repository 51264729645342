import {
  useGetSchedulerQuery,
  useReloadSchedulerDataMutation,
} from '../../api/Scheduler/api';
import { useGetCaregiversListQuery } from '../../api/Caregivers/api';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DATE_ONLY_STANDARD_FORMAT,
  ISO_DATE_ONLY_FORMAT,
} from '../../shared/constants';
import AlertContext from '../../components/Alert';
import Loader from '../../components/Loader';
import VisitManagementProvider from '../../components/VisitInfoTypeProvider';
import Scheduling from './Scheduling';
import ScheduleCaregiverManagementProvider from './components/ScheduleCaregiverManagement';
import {
  LOCAL_STORAGE_KEYS,
  makeCaregiversVists,
} from './constants';

export default function SchedulingPage() {
  const { setAlert } = useContext(AlertContext);
  const today = moment();
  const todayDate = today.format(ISO_DATE_ONLY_FORMAT);
  const storedDate = localStorage.getItem(LOCAL_STORAGE_KEYS.currentDate)
    ? new Date(localStorage.getItem(LOCAL_STORAGE_KEYS.currentDate))
    : null;
  const [currentDate, setCurrentDate] = useState(storedDate);
  const date = useMemo(
    () =>
      currentDate
        ? moment(currentDate).format(ISO_DATE_ONLY_FORMAT)
        : todayDate,
    [currentDate, todayDate],
  );
  const [schedulerEvents, setschedulerEvents] = useState({
    scheduleEvents: [],
    caregiversList: [],
    notAllocatedEvents: [],
    tableViewEvents: [],
  })
  const {
    data: rawVisits,
    isLoading: visitsLoading,
    isFetching: isVisitsFetching,
    refetch: reFetchVisits,
    error: visitsError,
  } = useGetSchedulerQuery(date);
  const {
    data: caregivers,
    isLoading: caregiversLoading,
    isFetching: isCaregiversFetching,
    refetch: reFetchCaregivers,
    error: caregiversError,
  } = useGetCaregiversListQuery();

  useEffect(() => {
    if (rawVisits && caregivers) {
      const tempVisits = [...rawVisits];
      const allocatedVisits = [];
      const notAllocatedVisits = [];
      for (let visit in tempVisits) {
        if (tempVisits[visit].caregiverId && tempVisits[visit].caregiverId !== "NoCaregiver") {
          allocatedVisits.push(tempVisits[visit]);
        } else {
          const thisVisit  = {...tempVisits[visit]};
          const arrivalTime = moment(thisVisit["arrivalStart"])
          const start = moment(thisVisit?.date).set({
            hour: arrivalTime?.hours(),
            minute: arrivalTime?.minutes(),
            second: 0,
            millisecond: 0,
          }).toDate();
          const end = moment(start).add(thisVisit.duration, 'minutes').toDate();
          thisVisit.arrivalTime = arrivalTime.format("YYYY-MM-DDTHH:mm:ss");
          thisVisit.start = start;
          thisVisit.end = end;
          thisVisit.arrivalTimeDuration = 60;
          thisVisit.resourceId = thisVisit.caregiverId;
          thisVisit.isDraggable = false;

          notAllocatedVisits.push(thisVisit);
        }
      };
      const scheduleEvents = makeCaregiversVists(allocatedVisits, caregivers);
      setschedulerEvents({
        scheduleEvents: scheduleEvents,
        caregiversList: caregivers,
        notAllocatedEvents: notAllocatedVisits,
        tableViewEvents: allocatedVisits,
      });
    }
  }, [rawVisits, caregivers]);

  const reFetchCalendarEvents = () => {
    reFetchVisits();
    reFetchCaregivers();
  };

  const [
    reloadSchedulerData,
    { isLoading: refreshSchedulingPending, error: refreshSchedulingError },
  ] = useReloadSchedulerDataMutation();

  const reloadSchedulingForDate = () => {
    const data = {
      fromDate: moment(date).format(ISO_DATE_ONLY_FORMAT),
      toDate: moment(date).format(ISO_DATE_ONLY_FORMAT),
    };
    reloadSchedulerData(data)
      .unwrap()
      .then(() => window.location.reload());
  };

  useEffect(() => {
    const errorData = visitsError || caregiversError || refreshSchedulingError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [visitsError, caregiversError, refreshSchedulingError, setAlert]);

  const eventsLoading = useMemo(
    () => visitsLoading || caregiversLoading || isVisitsFetching || isCaregiversFetching || refreshSchedulingPending,
    [refreshSchedulingPending, visitsLoading, caregiversLoading, isVisitsFetching, isCaregiversFetching],
  );

  const changeCurrentDate = useCallback((newDate) => {
    setCurrentDate(newDate);
    localStorage.setItem(LOCAL_STORAGE_KEYS.currentDate, newDate);
  }, []);
  return (
    <VisitManagementProvider reFetchCalendarEvents={reFetchCalendarEvents}>
      <ScheduleCaregiverManagementProvider
        calloutDate={moment(date).format(DATE_ONLY_STANDARD_FORMAT)} caregiversList={schedulerEvents.caregiversList}
      >
        {eventsLoading && <Loader />}
        {schedulerEvents.caregiversList && schedulerEvents.scheduleEvents &&(
          <Scheduling
            currentDate={currentDate}
            eventsLoading={eventsLoading}
            reloadSchedulingForDate={reloadSchedulingForDate}
            schedulerEvents={schedulerEvents}
            setCurrentDate={changeCurrentDate}
            today={today}
            todayDate={todayDate}
            reFetchCalendarEvents={reFetchCalendarEvents}
          />
        )}
      </ScheduleCaregiverManagementProvider>
    </VisitManagementProvider>
  );
}
