import moment from 'moment/moment';
import { getStatusClassName } from '../../shared/utils/common';
import { checkIfDraggable } from './constants';

export const getDndEventBoxClass = (event) => {

  const classNameData = { className: 'dnd-event' };
  if (event?.title === 'arrival') {
    classNameData.className = classNameData.className.concat(
      ` arrival ${event?.resourceId} ${event?.clientName} ${event?.team} arrival${event?.eventId}`,
    );
  }
  if (event?.title === 'arrivalTime') {
    classNameData.className = classNameData.className.concat(
      ` arrivalTime  arrivalTime${event?.id}   ${event?.resourceId}`,
    );
  }
  
  if (event?.visitTypeDetails?.name === 'Lunch Break') {
    classNameData.className = classNameData.className.concat(` lunchBreak`);
  }

  if (event?.caregiverId && event?.caregiverId === 'NoCaregiver') {
    classNameData.className = classNameData.className.concat(' not-allocated');
  }

  if (
    !checkIfDraggable({
      isShadowVisit: event?.isShadowVisit,
      status: event?.status,
    })
  ) {
    classNameData.className = classNameData.className.concat(' non-draggable');
  }
  classNameData.className = getStatusClassName(classNameData.className, event);
  return classNameData;
};

export const calculateCalendarVerticalScrollPosition = ({
  currentDate,
  firstVisit,
  top,
}) => {
  const scrollMinutes = Math.floor(top * (60 / 160));
  const startOfDay = moment(firstVisit, 'HH:mm');
  const end = moment(startOfDay).add(scrollMinutes, 'minutes');
  return moment(currentDate)
    .set({
      hour: end?.hours(),
      minute: end?.minutes(),
      second: 0,
      millisecond: 0,
    })
    .toDate();
};
