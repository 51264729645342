import { Box, Typography } from '@mui/material';
import {
  useDeleteHolidaysMutation,
  useGetHolidaysQuery,
  useUpdateHolidayMutation,
} from '../../../../../../api/Administration/api';
import EditIcon from '../../../../../../img/icons/edit-icon.svg';
import DeleteIcon from '../../../../../../img/icons/iconButtons/delete-icon.svg';
import moment from 'moment';
import { instanceOf } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { ISO_DATE_ONLY_FORMAT } from '../../../../../../shared/constants';
import { COLORS } from '../../../../../../shared/styles/Theme';
import AlertContext from '../../../../../../components/Alert';
import CustomDialog from '../../../../../../components/Dialog';
import TableRowActionsWithIcons from '../../../../../../components/TableRowActionsWithIcons';
import AddHolidayModalContent from '../AddHolidayModal';

function TableActions({ row = {} }) {
  const { setAlert } = useContext(AlertContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [date, setDate] = useState(moment(row.date));
  const [holidayName, setHolidayName] = useState(row.name);
  const { data: holidays, error: getHolidaysError } = useGetHolidaysQuery();
  const [updateHolidayData, { error: updateHolidaysError }] =
    useUpdateHolidayMutation();
  const [deleteHoliday, { error: deleteHolidaysError }] =
    useDeleteHolidaysMutation();
  useEffect(() => {
    if (
      getHolidaysError ||
      updateHolidaysError ||
      deleteHolidaysError 
    ) {
      const errorData =
        getHolidaysError ||
        updateHolidaysError ||
        deleteHolidaysError
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    getHolidaysError,
    updateHolidaysError,
    deleteHolidaysError,
    setAlert,
    // deleteEventsError,
  ]);

  const updateHoliday = () => {
    updateHolidayData({
        ...row,
        name: holidayName,
        date: date.format(ISO_DATE_ONLY_FORMAT),
      }
    );
    setOpenEditDialog(false);
  };
  const deleteHolidayById = () => {
    deleteHoliday({id: row.id});
    setOpenDeleteDialog(false);
  };
  const menuItems = [
    {
      icon: EditIcon,
      key: 'Edit',
      name: 'Edit',
      callback: () => {
        setOpenEditDialog(true);
      },
    },
    {
      icon: DeleteIcon,
      key: 'Remove',
      name: 'Remove',
      callback: () => {
        setOpenDeleteDialog(true);
      },
    },
  ];
  const shouldDisableDate = (selectedDate) => {
    const disabledDates = holidays?.map((holiday) => holiday.date);
    return disabledDates?.includes(
      moment(selectedDate).format(ISO_DATE_ONLY_FORMAT),
    );
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingRight: '20px',
      }}
    >
      <Typography>{row.name}</Typography>

      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenEditDialog(false)}
        submitButtonName="Save"
        disabledSubmit={holidayName === ''}
        submitCallback={updateHoliday}
        title="Update Holiday"
        openDialog={openEditDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 20px 13px' }}>
          <AddHolidayModalContent
            date={date}
            selectDate={setDate}
            holidayName={holidayName}
            setHolidayName={setHolidayName}
            shouldDisableDate={shouldDisableDate}
          />
        </Box>
      </CustomDialog>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenDeleteDialog(false)}
        submitButtonName="confirm"
        submitCallback={deleteHolidayById}
        title="Confirmation"
        openDialog={openDeleteDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            {`The holiday ${row.name} will be removed. Do you want to proceed?`}
          </Typography>
        </Box>
      </CustomDialog>
      <TableRowActionsWithIcons menuItems={menuItems} />
    </Box>
  );
}

TableActions.propTypes = {
  row: instanceOf(Object),
};

export default React.memo(TableActions);
