import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';
import { filterTimeOffRequests } from '../../pages/Scheduling/components/PtoAndCalloutsTable/utils';
import { parseCheckCallOutPossibilityData } from '../../pages/Scheduling/components/ScheduleCaregiverManagement/CreateCallOut/constants';
import { parseVisitTime } from '../../shared/utils/common';
import { v4 as uuidv4 } from 'uuid';

export const SCHEDULER_API_KEY = 'schedulerApi';
const schedulerApi = createApi({
  reducerPath: SCHEDULER_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: (builder) => ({
    getScheduler: builder.query({
      query: (date) => ({
        url: `crud/visits/`,
        method: 'GET',
        params: { 
          index: "date-index",
          pk: "date",
          pkValue: `${date}T00:00:00`,
          sk: "active",
          skValue: "True"
         },
      }),
    }),
    dragAndDrop: builder.mutation({
      query: (payload) => ({
        url: `scheduler/calendar/update`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'scheduler', id: 'ALL' }],
    }),
    checkDragAndDropPossibility: builder.mutation({
      query: (payload) => ({
        url: `scheduler/calendar/update/check`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateScheduler: builder.mutation({
      query: ({ requestId }) => ({
        url: `scheduler/calendar/update/confirm/${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'scheduler', id: 'ALL' }],
    }),

    checkCallOutPossibility: builder.mutation({
      query: ({ caregiverId, payload }) => ({
        url: `callout/caregiver/${caregiverId}`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response) => {
        const parsedData = parseCheckCallOutPossibilityData(
          response?.changedFirstVisits,
        );
        const dates = Object.keys(response?.changedFirstVisits);
        return {
          dates,
          deletedShadowVisits: response?.nonShadowVisitsAnyMore,
          requestId: response?.requestId,
          response: parsedData,
          rescheduledEvents: response?.rescheduledEvents,
        };
      },
    }),
    applyCallOut: builder.mutation({
      query: ({ requestId }) => ({
        url: `callout/${requestId}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: () => [
        { type: 'caregiverAvailability', id: 'ALL' },
        { type: 'scheduler', id: 'ALL' },
        { type: 'allTimeOffRequests', id: 'ALL' },
      ],
    }),
    cancelCallOut: builder.mutation({
      query: ({ requestId }) => ({
        url: `callout/${requestId}/cancel`,
        method: 'POST',
      }),
    }),
    getAllTimeOffRequests: builder.query({
      query: () => ({
        url: 'time-off-requests',
        method: 'GET',
      }),
      transformResponse: (
        response,
        meta,
        { timeOffRequestsFilters, isAllTeams },
      ) => filterTimeOffRequests(timeOffRequestsFilters, response, isAllTeams),
      providesTags: () => [{ type: 'allTimeOffRequests', id: 'ALL' }],
    }),
    getAllCaregiverAvailabilities: builder.query({
      query: ({ caregiver, date }) => ({
        url: '/availability/get',
        method: 'POST',
        body: { id: caregiver?.id, date },
      }),
      transformResponse: (response, meta, { caregiver }) => ({
        availability: response?.availabilities.length
          ? response?.availabilities[0]
          : null,
        caregiver,
        oneTimeAvailability: response?.oneTimeAvailability
          ? [
              ...response.oneTimeAvailability,
              {
                temporaryId: uuidv4(),
              },
            ]
          : {
              temporaryId: uuidv4(),
            },
        holidays: response?.holidays,
        callouts: response?.callouts,
        timeOffs: response?.timeOffs,
      }),
      providesTags: () => [{ type: 'caregiverAvailability', id: 'ALL' }],
    }),
    addOneTimeAvailability: builder.mutation({
      query: (payload) => ({
        url: `/availability`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [
        { type: 'caregiverAvailability', id: 'ALL' },
        { type: 'scheduler', id: 'ALL' },
      ],
    }),
    verifyVisit: builder.mutation({
      query: (payload) => ({
        url: 'visit/info/scheduled/save',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'scheduler', id: 'ALL' }],
    }),
    reloadSchedulerData: builder.mutation({
      query: (payload) => ({
        url: 'scheduler/schedule',
        method: 'POST',
        body: payload,
      }),
    }),
    checkCarePlanChanges: builder.mutation({
      query: (payload) => ({
        url: 'careplan/change/check',
        method: 'POST',
        body: payload,
      }),
    }),
    getJobsById: builder.mutation({
      query: (jobId) => ({
        url: `visit/info/scheduled/pattern/${jobId}`,
        method: 'GET',
      }),
      transformResponse(response) {
        return parseVisitTime(response);
      },
    }),
  }),
});
export const {
  useApplyCallOutMutation,
  useCancelCallOutMutation,
  useCheckCallOutPossibilityMutation,
  useGetAllCaregiverAvailabilitiesQuery,
  useAddOneTimeAvailabilityMutation,
  useGetSchedulerQuery,
  useUpdateSchedulerMutation,
  useGetJobsByIdMutation,
  useCheckDragAndDropPossibilityMutation,
  useVerifyVisitMutation,
  useReloadSchedulerDataMutation,
  useCheckCarePlanChangesMutation,
  useGetAllTimeOffRequestsQuery,
} = schedulerApi;

export default schedulerApi;
