import { Box } from '@mui/material';
import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import CustomDialog from '../../../../../components/Dialog';
import SelectEffectiveDate from './SelectEffectiveDate';

function TeamEffectiveDateModal({
  openEffectiveDateDialog = false,
  closeEffectiveDateDialog = () => {},
  submitEffectiveDateDialog = () => {},
  date = {},
  selectDate = () => {},
}) {
  return (
    <Box>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={closeEffectiveDateDialog}
        submitButtonName="view changed visits"
        submitCallback={submitEffectiveDateDialog}
        title="Set Effective Date"
        openDialog={openEffectiveDateDialog}
      >
        <Box sx={{ padding: '0 28px 20px 13px' }}>
          <SelectEffectiveDate
            date={date}
            selectDate={selectDate}
          />
        </Box>
      </CustomDialog>
    </Box>
  );
}

TeamEffectiveDateModal.propTypes = {
  openEffectiveDateDialog: bool,
  closeEffectiveDateDialog: func,
  date: instanceOf(Object),
  selectDate: func,
  isPrimary: bool,
  caregiver: instanceOf(Object),
  caregiverId: string,
  updatedProfileData: instanceOf(Object),
  submitEffectiveDateDialog: func
};

export default TeamEffectiveDateModal;
