import { Box, Typography } from '@mui/material';
import { ClientProfilesContext } from '../../../../../pages/ClientDetails/context';
import React, { useContext } from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';
import CustomSelect from '../../../../../components/Select';
import { additionalSelectStyles } from '../styles';

export default function BlockedCaregiversSelector() {
  const {
    clientData,
    setClientData,
    caregivers
  } = useContext(ClientProfilesContext);

  const onSelectChange = (value) => {
    const caregiverDetails = [];
    for (let caregiver in value.value) {
      const caregiverDetail = caregivers.find((thisCaregiver) => thisCaregiver.id === value.value[caregiver]);
      caregiverDetails.push(caregiverDetail);
    }
    const blockedCaregivers = caregiverDetails.map((caregiver) => caregiver.id);
    const blockedCaregiversNames = caregiverDetails.map((caregiver) => `${caregiver.firstName} ${caregiver.lastName}`);
    const newProfileData = { ...clientData, blockedCaregivers: blockedCaregivers, blockedCaregiversNames: blockedCaregiversNames };
    setClientData(newProfileData);
  };

  return (
    <Box>
      <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
        {caregivers.length ? (
          <CustomSelect
            addStyles={additionalSelectStyles}
            id="blockedCaregivers"
            placeHolder="Blocked caregivers"
            options={caregivers.map((caregiver) => ({ id: caregiver.id, name: `${caregiver.firstName} ${caregiver.lastName}` }))}
            isLoading={false}
            isMultiselect
            selectedValue={clientData?.blockedCaregivers?.map((caregiverId) => ({ id: caregiverId }))}
            setValue={onSelectChange}
          />
        ) : (
          <Typography
            sx={{ color: COLORS.blue[500], fontStyle: 'italic' }}
          >{`No caregivers with a client team ${clientData?.team?.name}`}</Typography>
        )}
      </Box>
    </Box>
  );
}
