import { EditCareProgramContext } from '../../../../../../pages/ClientDetails/context';
import React, { useContext } from 'react';
import CareProgramTable from '../../components/CareProgramTable';

function CareProgramTableView() {
  const { initialCareProgramData } = useContext(EditCareProgramContext);
  return (
    <CareProgramTable careProgramVisits={initialCareProgramData} />
  );
}

export default React.memo(CareProgramTableView);
